import React, { useEffect } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { makeStyles } from "@mui/styles";
import {
  fileLessThanTenMb,
  imageFormats,
  videoFormats,
  formatBytes,
} from "../../utils";
// import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { FileDownloadDoneOutlined } from "@mui/icons-material";
import FolderIcon from "../../assets/folderIcon";
// import { UploadToCloudIcon, PreviewPngIcon } from '../../assets'
// import withAlelrtAndDialog from "../../HOCs/withAlelrtAndDialog";

const useStyles = makeStyles((theme) => ({
  folderIcon: {
    "&:hover": {
      background: "transparent",
    },
  },
  container: {
    // border: `1.5px dashed ${theme.palette.primary.main}`,
    borderRadius: 8,
    // padding: 12,
    // backgroundColor: "rgba(255,190,159, 0.04)", // peach shade
    // backgroundColor: "rgba(0, 148, 144, 0.04)", // Turqousie shade
    // marginTop: 8,
  },
  // uploadButtonFlex: {
  //   color: theme.palette.primary.main,
  //   display: "flex",
  //   justifyContent: "flex-start",
  //   [theme.breakpoints.down("xs")]: {
  //     justifyContent: "flex-start",
  //   },
  // },
  closeBtn: {
    padding: "0 !important",
    "&.MuiSvgIcon-root": {
      fontSize: "1rem !important",
    },
  },

  // New Styles
  uploadRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `0px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.primary.light}`,
    borderRadius: 8,
    // paddingLeft: 50,
    // paddingRight: 50,
    // paddingTop: 40,
    // paddingBottom: 40,
  },
  uploadButton: {
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
    "&:hover": {
      //   backgroundColor: theme.palette.primary.dark,
    },
  },
  listItemIconRoot: {
    // minWidth: 46
  },
  listItemTextRoot: {
    marginLeft: 6,
  },
  imageStyle: {
    [theme.breakpoints.down("md")]: {
      width: 320,
      height: 250,
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  list: {
    border: `0px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.light,
    // padding: "10px 0px",
  },
}));

const UploaderFile = ({ fileUrl, file, isEdit, ...props }) => {
  // const [filename, setFilename] = React.useState("");
  const [uploadError, setUploadError] = React.useState(false);
  // const [image, setImage] = React.useState(null);
  const [previewImage, setPreviewImage] = React.useState(null);
  const [uploadPreview, setUploadPreview] = React.useState(null);
  const [imageSrcError, setImageSrcError] = React.useState(false);
  const [fileState, setFileState] = React.useState(null);
  const [fileURL, setFileUrl] = React.useState(fileUrl);
  const theme = useTheme();
  const classes = useStyles(props);

  useEffect(() => {
    setFileUrl(fileUrl);
  }, [fileUrl]);

  useEffect(() => {
    if (file && file.constructor !== Object) {
      setFileState(file);
      try {
        let tempObjURL = URL.createObjectURL(file);
        setPreviewImage(tempObjURL);
      } catch (error) {
        console.log(error);
      }
    } else if (fileUrl) {
      setPreviewImage(fileUrl);
    }
  }, [file, fileUrl]);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    //   If the user press cancel while browsing for a file
    if (event.target.files[0]) {
      const fileUploaded = event.target.files[0];
      //   if (fileLessThanTenMb(fileUploaded.size)) {
      // setFilename(fileUploaded.name);
      setUploadError(false);
      setFileState(fileUploaded);
      try {
        let tempObjURL = URL.createObjectURL(fileUploaded);
        //   setPreviewImage(tempObjURL);
        setUploadPreview(tempObjURL);
      } catch (error) {
        console.log(error);
      }
      props.handleFile(fileUploaded);
      //   } else {
      // setUploadError(true);
      //   }
    }
  };

  const handleRemove = () => {
    // setFilename("");
    hiddenFileInput.current.value = "";
    // setImage(null)
    setFileState(null);
    setPreviewImage(null);
    setFileUrl("");
    // fileUrl = ""
    props.handleRemove();
  };

  const handleImagePreviewDialog = (image, item) => {
    // Display the content in a model
    props.dialog.setDialog({
      ...props.dialog,
      open: true,
      title: item?.label,
      body: (
        <div style={{ paddingTop: 16 }}>
          <img
            src={image}
            className={classes.imageStyle}
            alt={`Failed to show document.`}
            onLoad={() => {
              if (item?.file) {
                URL.revokeObjectURL(image);
              }
            }}
          />
        </div>
      ),
      positiveBtn: "Ok",
      negativeBtn: "CANCEL",
      dontShowButtons: true,
      onOk: () => {},
      onCancel: () => {
        props.dialog.setDialog({
          ...props.dialog,
          open: false,
          title: ``,
          body: "",
          positiveBtn: "Ok",
          negativeBtn: "CANCEL",
          dontShowButtons: false,
        });
      },
    });
  };

  // let filename = props?.value?.constructor === File ? props?.value?.name : file?.constructor === File && image ? file?.name : props?.value
  //
  const getFormattedFormats = (formatArray = []) => {
    return formatArray
      .map((format, index) => {
        return `.${format}`;
      })
      .join();
  };

  let acceptedFormats = `.xlxs, .csv`;

  return (
    <>
      <Grid container className={classes.container}>
        <input
          type="file"
          // accept="video/*,image/*"
          //   accept={
          //     props?.customExtensions ? props?.customExtensions : acceptedFormats
          //   }
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          id={`upload-input-${props?.parentID}`}
        />
        {/* {!previewImage && ( */}
        {!(fileState || fileURL) && (
          <Grid item xs={12} container justify={"center"}>
            <>{props.label}</>
            <div className={classes.uploadRoot}>
              {/* <IconButton onClick={handleClick} size="small" className={classes.folderIcon}>
                <FolderIcon fontSize= {"1.25rem"}/>
              </IconButton> */}
              {/* </Typography> */}
              <Button
                variant={"text"}
                color={"primary"}
                onClick={handleClick}
                disabled={previewImage ? true : false}
                size={"small"}
                className={classes.uploadButton}
                style={{ textTransform: "unset" }}
                id={`${previewImage ? "readyToUploadBtn" : "browseFilesBtn"}${
                  props?.parentID
                }`}
              >
                {previewImage ? "Ready to upload" : "Click here to upload"}
              </Button>
            </div>
          </Grid>
        )}

        {uploadError && (
          <Grid container justify="center" item xs={12}>
            <Typography style={{ color: theme.palette.ternary.main }}>
              File exceeds limit
            </Typography>
          </Grid>
        )}

        {(fileState || fileURL) && (
          <>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <InputLabel shrink>Uploaded File</InputLabel>
              </Grid>
              <Grid item xs={12}>
                <List dense={true}>
                  <ListItem className={classes.list}>
                    <ListItemIcon
                      style={{
                        justifyContent: imageSrcError ? "center" : "center",
                      }}
                      classes={{ root: classes.listItemIconRoot }}
                    >
                      <InsertDriveFileIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={fileState ? fileState?.name : fileURL}
                      secondary={null}
                      classes={{ root: classes.listItemTextRoot }}
                    />
                    <ListItemSecondaryAction>
                      {!isEdit && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => window.open(fileURL)}
                        >
                          {/* // <a href={fileURL ? fileURL : ""} style={{padding: 8}} download> */}
                          <FileDownloadIcon />
                          {/* </a> */}
                        </IconButton>
                      )}
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={handleRemove}
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
export default UploaderFile;
