import DateAdapter from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";



const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
    '& sup':{
          color:theme.palette.error.main,
    }
  },
  datepicker: {
    border: "none",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "8px",

      boxShadow: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 14px",
    },
    "& .MuiOutlinedInput-notchedOutline":{
      border: "1px solid #CED3DD !important"
    }
  },
}));

export const DateTime = (props) => {
  const classes = useStyles(props);
  const getTitle = (props) => {
    return (
      <Typography variant="body1" className={classes.text} gutterBottom>
        {props.title}{" "}<sup>*</sup>
      </Typography>
    );
  };

  return (
    <>
      {getTitle(props)}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Stack spacing={3}>
          <DatePicker
            className={classes.datepicker}
            disableFuture
            label={props.placeholder}
            openTo="year"
            views={["day", "month","year"]}
            value={props.value?props.value:new Date().toISOString()}
            onChange={(newValue) => {
              props.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textbox}
                placeholder={props.placeholder}
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </>
  );
};