import React from "react";
import { AddVendor } from "./addvendor";
import { withAllContexts, withNavBars } from "./../../HOCs";
import ListContextProvider from "../../contexts/listcontext";
import { StepperRegistration } from "./StepperRegistration";

class AddVendorParent extends React.Component {
  render() {
    return (
      <ListContextProvider>
        {/* <AddVendor {...this.props}/> */}
        <StepperRegistration {...this.props}/>
      </ListContextProvider>
    );
  }
}

export default withNavBars(withAllContexts(AddVendorParent));
