import React from "react";
import { withNavBars } from "../../HOCs";
import { ErrorLogsHome } from "./errorlogHome";

class ErrorLogs extends React.Component {
  render() {
    return <ErrorLogsHome />;
  }
}

export default withNavBars(ErrorLogs);
