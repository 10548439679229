import React from "react";
import { ParingLogic } from "./paringLogic";
import { withNavBars } from "../../HOCs";

class ParingLogicParent extends React.Component {
  render() {
    return <ParingLogic />;
  }
}
export default withNavBars(ParingLogicParent);
