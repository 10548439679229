import React from "react";
import {
  ListItem,
  TextBox,
  Select,
  Subheader,
  DrawerComponent,
} from "../../../components";
import { useStyles } from "./style";
import {
  Button,
  Typography,
  Grid,
  Snackbar,
  Divider,
  Stack,
  Pagination,
  Card,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import axios from "axios";
import moment from "moment-timezone";
import { config } from "../../../config";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTheme } from "@emotion/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "../../../assets/deleteIcon";
import { NetworkCall } from "../../../networkcall";
import { UseDebounce } from "../../../components";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FF4D4A" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const listdata = [{ label: "rename", value: "rename" }];
const listdatas = [
  { label: "is equal to", value: "is equal to" },
  { label: "is not equal to", value: "is not equal to" },
  { label: "greater than", value: "greater than" },
  { label: "greater than or equal to", value: "greater than or equal to" },
  { label: "less than", value: "less than" },
  { label: "less then or equal to", value: "less then or equal to" },
  { label: "in", value: "in" },
  { label: "not in", value: "not in" },
  { label: "contains", value: "contains" },
  { label: "between", value: "between" },
  // { label: 'rename', value: 'rename' },];
];

let token = localStorage?.getItem("auth_token")?.toString();
token = token?.substring(1, token.length - 1);

const pageSize = 5;

export const RenameAddRule = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();

  const [page, setPage] = React.useState(1);
  const [_index, setindex] = React.useState(null);
  const [listofoperators, setlistofoperators] = React.useState([]);
  const [datafilter, setdatafilter] = React.useState([]);
  const [isView, setIsview] = React.useState(false);
  const [dataRefFilter, setDataRefFilter] = React.useState([]);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const [search, setSearch] = React.useState(null);

  const debounce = UseDebounce();

  const [state, setstate] = React.useState({
    isopen: false,
    addlist: [],
    obj: {
      fact: "",
      operator: "rename",
      value: [
        {
          key: "",
          value: "",
        },
      ],
    },
    reference: false,
    listData: [],
    vendor_id: "",
    rule_name: "",
    chooseVerdorIds: [],
    choosevendor: {},
    error: {},
    id: "",
  });
  const [refObj, setRefObj] = React.useState({
    obj: {
      fact: "",
      operator: "",
      value: [""],
      path: "payload",
    },
    error: {},
  });
  const isClearState = async () => {
    await setstate({
      ...state,
      isopen: false,
      addlist: [],
      obj: {
        fact: "",
        operator: "rename",
        value: [
          {
            key: "",
            value: "",
          },
        ],
      },
      reference: false,
      vendor_id: "",
      rule_name: "",
      choosevendor: {},
      error: {},
    });
    setRefObj({
      obj: {
        fact: "",
        operator: "",
        value: [""],
        path: "payload",
      },
      error: {},
    });
    setindex(null);
  };

  const handleDelete = (index) => {
    state.addlist.splice(_index, 1);
    setstate({ ...state });
  };
  const handleRefOperatorDelete = (index) => {
    setRefObj({
      ...refObj,
      obj: {
        ...refObj.obj,
        operator: "",
      },
    });
  };

  const handleAddChild = (index, i) => {
    state.obj?.value?.push({
      key: "",
      value: "",
    });
    setstate({ ...state });
  };
  const handleRefAddChild = (index, i) => {
    refObj.obj?.value?.push("");
    setRefObj({ ...refObj });
  };

  const searchTableFunction = (text) => {
    let api_url;

    if (text !== "") {
      api_url = config.api_url + `rules/vendor/rename/get/all?search=${text}`;
    } else {
      api_url = config.api_url + `rules/vendor/rename/get/all`;
    }

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    NetworkCall(
      `${api_url}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((response) => {
        const data = response.data?.rows?.map((v, i) => {
          if (v?.id) {
            return {
              ...v,
              active: v?.fixed_markup > 0 ? true : false,
              margin: true,
              last_modifyed: moment(v?.updated_at).format("YYYY-MM-DD"),
              created_at: moment(v?.created_at).format("YYYY-MM-DD"),
            };
          }
        });
        const vedor_id = data?.map((v) => {
          return {
            value: v?.vendor_id ?? "",
            label: v?.vendor?.first_name ?? "",
          };
        });
        setstate({
          ...state,
          listData: data,
          chooseVerdorIds: vedor_id ?? [],
          isopen: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (e) => {
    debounce(() => searchTableFunction(e), 800);
  };

  const handleClear = () => {
    setstate({
      ...state,
      obj: {
        fact: "",
        operator: "rename",
        value: [
          {
            key: "",
            value: "",
          },
        ],
      },
      error: {},
    });
    setRefObj({
      obj: {
        fact: "",
        operator: "",
        value: [""],
        path: "payload",
      },
      error: {},
    });
    setindex(null);
  };

  const handleDeleteChild = (index, i) => {
    state.obj.value.splice(i, 1);
    setstate({ ...state });
  };
  const handleRefDeleteChild = (index, i) => {
    refObj.obj.value.splice(i, 1);
    setRefObj({ ...refObj });
  };
  const updateStateChild = (key, value, index, i) => {
    state.obj.value[i][key] = value;
    setstate({ ...state });
  };
  const updateRefStateChild = (key, value, index, i) => {
    refObj.obj[key][i] = value;
    setRefObj({ ...refObj });
  };
  const updateState = (key, value) => {
    state.error[key] = false;
    if (key === "operator") {
      state.obj["value"] =
        value === "in" || value === "not in" || value === "between" ? [""] : "";
    }
    if (key === "reference") {
      state[key] = value;
    }
    if (!isNaN(+value) && value) {
      state.obj[key] = JSON.parse(value);
    } else if (typeof value === "object") {
      state.obj[key] = value;
    } else {
      state.obj[key] = value;
    }
    setstate({ ...state });
  };

  const handleAdd = () => {
    setstate({ ...state });

    if (!state.obj.operator) {
      setAlert({
        open: true,
        message: "Please select Operator",
        backgroundColor: "#FF3232",
      });
      return;
    } else if (!state.obj.fact) {
      setAlert({
        open: true,
        message: "Please select Column Name",
        backgroundColor: "#FF3232",
      });
      return;
    } else if (state.obj.value[0]?.key === "") {
      console.log(state.obj);
      setAlert({
        open: true,
        message: "Please select Key",
        backgroundColor: "#FF3232",
      });
      return;
    } else if (state.obj.value[0]?.value === "") {
      console.log(state.obj);
      setAlert({
        open: true,
        message: "Please select Value",
        backgroundColor: "#FF3232",
      });
      return;
    }

    if (state?.reference) {
      if (!refObj.obj.operator) {
        setAlert({
          open: true,
          message: "Please select Operator",
          backgroundColor: "#FF3232",
        });
        return;
      } else if (!refObj.obj.fact) {
        setAlert({
          open: true,
          message: "Please select Column Name",
          backgroundColor: "#FF3232",
        });
        return;
      } else if (refObj.obj.value.length === 0) {
        setAlert({
          open: true,
          message: "Please select Value",
          backgroundColor: "#FF3232",
        });
        return;
      }
    }

    if (_index !== null && _index >= 0) {
      if (state.reference) {
        state.obj["reference"] = { ...refObj.obj };
      }
      state.addlist[_index] = { ...state.obj };
    } else {
      if (state.reference) {
        state.obj["reference"] = { ...refObj.obj };
      }
      state.addlist.push({ ...state.obj });
    }
    state.obj = {
      fact: "",
      operator: "rename",
      value: [
        {
          key: "",
          value: "",
        },
      ],
    };
    state.reference = false;
    setstate({ ...state });
    refObj.obj = {
      fact: "",
      operator: "",
      value: [""],
      path: "payload",
    };
    setRefObj({ ...refObj });
    setindex(null);
  };

  const handleClose = async () => {
    setstate({
      ...state,
      isopen: !state.isopen,
    });
    isGetList();
    await isClearState();
  };

  const isGetList = async () => {
    const api_url = config.api_url + "rules/vendor/rename/get/all";

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    new Promise((resolve, reject) => {
      axios
        .get(`${api_url}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          const data = response.data?.rows?.map((v, i) => {
            if (v?.id) {
              return {
                ...v,
                last_modifyed: moment(v?.updated_at).format("YYYY-MM-DD"),
                created_at: moment(v?.created_at).format("YYYY-MM-DD"),
              };
            }
          });
          const vedor_id = data?.map((v) => {
            return {
              value: v?.vendor_id ?? "",
              label: v?.vendor?.first_name ?? "",
            };
          });
          setstate({
            ...state,
            listData: data,
            chooseVerdorIds: vedor_id ?? [],
            isopen: false,
          });
          resolve(true);
        });
    });
  };

  React.useEffect(() => {
    isGetList();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    state?.obj?.fact && getValueData();
    // eslint-disable-next-line
  }, [state?.obj?.fact]);
  React.useEffect(() => {
    refObj?.obj?.fact && getValueRefData();
  }, [refObj?.obj?.fact]);
  const getOperatot = async (id) => {
    const api_url = config.api_url + "feed/unqiue/header/get";
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    await axios
      .post(
        `${api_url}`,
        {
          vendor_id: id || "",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response) {
          const data = response?.data?.headers;
          var arr = [];
          data?.map((v) => {
            return arr.push({ value: v, label: v });
          });
        }
        setlistofoperators(arr ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getValueData = async () => {
    let id = state?.vendor_id || state?.choosevendor?.value || "";
    await axios
      .post(
        `${config.api_url + "feed/unqiue/data/get/column"}`,
        {
          vendor_id: id || "",
          column: state?.obj?.fact ?? "",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response) {
          setdatafilter(response?.data?.column_data ?? []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getValueRefData = async () => {
    let id = state?.vendor_id || state?.choosevendor?.value || "";
    await axios
      .post(
        `${config.api_url + "feed/unqiue/data/get/column"}`,
        {
          vendor_id: id || "",
          column: refObj?.obj?.fact ?? "",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response) {
          setDataRefFilter(response?.data?.column_data ?? []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateRefObj = (key, value) => {
    refObj.error[key] = false;
    if (key === "operator") {
      refObj.obj["value"] =
        value === "in" || value === "not in" || value === "between" ? [""] : "";
    }
    if (key === "reference") {
      refObj[key] = value;
    }
    if (!isNaN(+value) && value) {
      refObj.obj[key] = JSON.parse(value);
    } else if (typeof value === "object") {
      refObj.obj[key] = value;
    } else {
      refObj.obj[key] = value;
    }
    setRefObj({ ...refObj });
  };
  const ishandleEdit = (datass, isClone) => {
    const data = JSON.parse(JSON.stringify(datass));

    getOperatot(data?.vendor_id);
    const paylod = data.rename_rules_data?.map((v) => {
      var results = [];
      Object.entries(v.value).map((val) =>
        results.push({
          ["key"]: val[0],
          ["value"]: val[1],
        })
      );
      v.value = results;
      return v;
    });
    state.obj = {
      fact: "",
      operator: "rename",
      value: [
        {
          key: "",
          value: "",
        },
      ],
    };

    if (isClone) {
      setIsview(!isView);
      setstate({
        ...state,
        // isopen: !state.isopen,
        vendor_id: data?.vendor_id,
        rule_name: data?.rule_name,
        addlist: paylod,
        id: data?.id ?? "",
      });
    } else {
      getOperatot(data?.vendor_id);
      setstate({
        ...state,
        isopen: !state.isopen,
        vendor_id: data?.vendor_id,
        rule_name: data?.rule_name,
        addlist: paylod,
        id: data?.id ?? "",
      });
    }
  };

  const ishandleDelete = (data, i) => {
    const api_url = config.api_url + "rules/vendor/rename/delete";

    new Promise((resolve, reject) => {
      axios
        .post(
          `${api_url}`,
          {
            rule_id: data?.id ?? "",
            vendor_id: data?.vendor_id ?? "",
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          setAlert({
            open: true,
            message: `Rename has been deleted successfully`,
            backgroundColor: "#4BB543",
          });
          isGetList();
          resolve(true);
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something Went Wrong",
            backgroundColor: "#FF3232",
          });
        });
    });
  };

  const isValidation = () => {
    if (state.vendor_id) {
      if (!state?.rule_name) {
        state.error["rule_name"] = true;
      }
    } else {
      if (!state?.choosevendor?.value) {
        state.error["choosevendor"] = true;
      }
      if (!state?.rule_name) {
        state.error["rule_name"] = true;
      }
    }
    setstate({
      ...state,
    });
  };

  const handleSave = async () => {
    await isValidation();
    if (state?.error?.rule_name || state?.error?.choosevendor) {
      return;
    }
    let isdata = JSON.parse(JSON.stringify(state.addlist));
    const paylod = isdata?.map((v) => {
      var object = v?.value?.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }),
        {}
      );
      v.value = object;
      return v;
    });

    const api_url = config.api_url + "rules/vendor/rename/upsert";

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    let payload = {
      rule_name: state?.rule_name ?? "",
      vendor_id: state?.vendor_id || state?.choosevendor?.value || "",
      rename_rules_data: paylod ?? [],
    };
    if (state?.vendor_id || state?.choosevendor?.value) {
      payload.rule_id = state?.id;
    }

    new Promise((resolve, reject) => {
      axios
        .post(`${api_url}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setAlert({
            open: true,
            message: `Rename rule has been ${
              state?.vendor_id ? "updated" : "added"
            } successfully`,
            backgroundColor: "#4BB543",
          });
          if (state?.vendor_id) {
            handleClose();
          }
          isGetList();
          resolve(true);
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something Went Wrong",
            backgroundColor: "#FF3232",
          });
        });
    });
  };

  const handleChange = (key, v) => {
    state[key] = v;

    if (key === "choosevendor") {
      getOperatot(v?.value ?? "");
    }
    state.error = { ...state.error, choosevendor: false };
    setstate({
      ...state,
    });
  };

  const handleAddNewRule = async () => {
    await isClearState();
    setstate({
      ...state,
      isopen: !state.isopen,
      rule_name: "",
      vendor_id: "",
      choosevendor: {},
      addlist: [],
      obj: {
        fact: "",
        operator: "rename",
        value: [
          {
            key: "",
            value: "",
          },
        ],
      },
      error: {},
    });
  };

  const { addlist, listData, vendor_id, rule_name, chooseVerdorIds } = state;

  const handleEdit = (d, i) => {
    setindex(i);
    state.obj = JSON.parse(JSON.stringify(d));
    state.reference = false;
    if (d?.reference) {
      state.reference = true;

      refObj.obj = JSON.parse(JSON.stringify(d?.reference));
      setRefObj({ ...refObj });
    }
    setstate({ ...state });
  };
  return (
    <div className={classes.root}>
      <div className={classes.rootBody}>
        {!state.isopen && (
          <Grid container rowGap={3}>
            <Grid item xs={12}>
              <Subheader
                title="Rename Rules"
                count={listData?.length}
                search={search}
                setSearch={(e) => handleSearch(e.target.value)}
                onAddClick={handleAddNewRule}
              />
            </Grid>

            <Grid container item rowGap={1.25}>
              {listData?.length === 0 || !listData ? (
                <Grid item xs={12}>
                  <Typography
                    style={{ margin: "25vh 0px", textAlign: "center" }}
                  >
                    No Data
                  </Typography>
                </Grid>
              ) : (
                listData
                  .slice(page * pageSize - pageSize, page * pageSize)
                  .map((rule) => (
                    <Grid item xs={12}>
                      <ListItem
                        key={rule?.id}
                        item={{
                          name: rule?.rule_name,
                          createdAt: rule?.created_at,
                          updatedAt: rule?.last_modifyed,
                          vendorName: rule?.vendor?.first_name ?? "",
                        }}
                        handleEdit={() => ishandleEdit(rule)}
                        handleClone={() => ishandleEdit(rule, true)}
                        handleDelete={() => ishandleDelete(rule)}
                      />
                    </Grid>
                  ))
              )}
            </Grid>

            {listData?.length > 0 && (
              <Grid item xs={12}>
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  style={{ marginTop: "-6px" }}
                >
                  <Pagination
                    count={Math.ceil(listData?.length / pageSize)}
                    page={page}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                    color="primary"
                  />
                </Stack>
              </Grid>
            )}
          </Grid>
        )}

        {state.isopen && (
          <>
            <div className={classes.titleSection}>
              <ArrowBackIcon className="icon" onClick={() => handleClose()} />
              <Typography variant="subtitle2">
                {vendor_id ? "Edit" : "Add New"} Rename Rule
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={8}
                style={{ borderRight: "1px solid #eee" }}
              >
                <div className={classes.addruleDrawer}>
                  {/* <div className={classes.titleSection}>
                
                </div> */}

                  <div className={classes.addruleDrawerBody}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextBox
                          label={"Rule Name"}
                          type={"text"}
                          value={rule_name}
                          isError={state?.error?.rule_name}
                          errorMessage={
                            state?.error?.rule_name &&
                            "Please enter the rule name"
                          }
                          placeholder={"Rule Name"}
                          onChange={(e) =>
                            setstate({
                              ...state,
                              rule_name: e.target.value,
                              error: { ...state.error, rule_name: false },
                            })
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {vendor_id ? (
                          <TextBox
                            label={"Vendor Name"}
                            type={"text"}
                            value={
                              chooseVerdorIds?.filter(
                                (v) => v.value === vendor_id
                              )?.[0]?.label
                            }
                            disabled
                            placeholder={"Vendor Name"}
                          />
                        ) : (
                          <Select
                            disablePortal
                            options={chooseVerdorIds}
                            label="Choose Vendor"
                            placeholder="Choose Vendor"
                            isError={state?.error?.choosevendor}
                            errorMessage={
                              state?.error?.choosevendor &&
                              "Please choose the vendor"
                            }
                            onChange={(e, value) =>
                              handleChange("choosevendor", value)
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                    <br />
                    Condition :
                    <br /> <br /> <Divider />
                    <div className={classes.addlist}>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        style={{
                          padding: "16px 0px",
                          borderBottom: "1px solid #bdbdbd96",
                        }}
                      >
                        <Grid item xs={6} sm={6}>
                          <Select
                            disablePortal
                            value={{
                              label: state?.obj?.fact ?? "",
                              value: state?.obj?.fact ?? "",
                            }}
                            onChange={(e, value) =>
                              updateState("fact", value?.value, _index)
                            }
                            isError={state?.error?.fact}
                            errorMessage={
                              state?.error?.fact && "Please choose Column"
                            }
                            disabled={
                              !state?.choosevendor?.value && !state?.vendor_id
                            }
                            options={listofoperators}
                            label="Column Name"
                            placeholder="Column Name"
                          />
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <Select
                            value={{
                              label: state?.obj?.operator ?? "",
                              value: state?.obj?.operator ?? "",
                            }}
                            onChange={(e, value) =>
                              updateState("operator", value?.value, _index)
                            }
                            disabled
                            isError={state?.error?.operator}
                            errorMessage={
                              state?.error?.operator && "Please enter Operator"
                            }
                            disablePortal
                            options={listdata}
                            label="Operator"
                            placeholder={"Operator"}
                          />
                        </Grid>

                        {state?.obj?.value?.map((child, i) => {
                          return (
                            <Grid
                              item
                              container
                              spacing={2}
                              xs={5}
                              sm={12}
                              alignItems={"center"}
                            >
                              <Grid item xs={5} sm={4}>
                                <Select
                                  value={{
                                    label: child?.key ?? "",
                                    value: child?.key ?? "",
                                  }}
                                  onChange={(e, value) =>
                                    updateStateChild(
                                      "key",
                                      value?.value,
                                      _index,
                                      i
                                    )
                                  }
                                  disabled={!state?.obj?.fact}
                                  options={
                                    state?.obj?.fact && datafilter
                                      ? datafilter?.map((v) => {
                                          return { value: v, label: v };
                                        })
                                      : []
                                  }
                                  label="Key"
                                  placeholder={"key"}
                                />
                              </Grid>
                              <Grid item xs={5} sm={5}>
                                <TextBox
                                  type={"text"}
                                  value={child?.value}
                                  onChange={(e) =>
                                    updateStateChild(
                                      "value",
                                      e.target.value,
                                      _index,
                                      i
                                    )
                                  }
                                  label={"Value"}
                                  placeholder={"Value"}
                                />
                              </Grid>

                              <Grid item xs={2} sm={3}>
                                <div style={{ display: "flex" }}>
                                  <DeleteIcon
                                    style={{
                                      color: "#ff4d4a",
                                      cursor: "pointer",
                                      marginTop: 23,
                                      fontSize: 17,
                                    }}
                                    onClick={() => handleDeleteChild(_index, i)}
                                  />

                                  {state?.obj?.value?.length - 1 === i && (
                                    <AddOutlinedIcon
                                      style={{
                                        color: "#231535",
                                        cursor: "pointer",
                                        marginTop: 20,
                                      }}
                                      onClick={() => handleAddChild(_index, i)}
                                    />
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          );
                        })}

                        <Grid item xs={12} sm={12}>
                          <span>Reference : </span>
                          <IOSSwitch
                            sx={{ m: 1 }}
                            defaultChecked
                            checked={state?.reference}
                            onChange={(e) =>
                              updateState("reference", e.target.checked, _index)
                            }
                          />
                        </Grid>
                        {state?.reference && (
                          <>
                            <Grid item xs={6} sm={4}>
                              <Select
                                disablePortal
                                value={{
                                  label: refObj?.obj?.fact ?? "",
                                  value: refObj?.obj?.fact ?? "",
                                }}
                                onChange={(e, value) =>
                                  updateRefObj("fact", value?.value, _index)
                                }
                                isError={refObj?.error?.fact}
                                errorMessage={
                                  refObj?.error?.fact && "Please choose Column"
                                }
                                disabled={
                                  !state?.choosevendor?.value &&
                                  !state?.vendor_id
                                }
                                options={listofoperators}
                                label="Column Name"
                                placeholder="Column Name"
                              />
                            </Grid>

                            <Grid item xs={6} sm={4}>
                              <Select
                                value={{
                                  label: refObj?.obj?.operator ?? "",
                                  value: refObj?.obj?.operator ?? "",
                                }}
                                onChange={(e, value) =>
                                  updateRefObj("operator", value?.value, _index)
                                }
                                isError={refObj?.error?.operator}
                                errorMessage={
                                  refObj?.error?.operator &&
                                  "Please enter Operator"
                                }
                                disablePortal
                                options={listdatas}
                                label="Operator"
                                placeholder={"Operator"}
                              />
                            </Grid>

                            {(refObj?.obj?.operator === "in" ||
                              refObj?.obj?.operator === "not in") && (
                              <Grid item xs={1} sm={1}>
                                <div
                                  style={{
                                    border: "1px solid red",
                                    padding: "6px 3px 0px",
                                    borderRadius: "4px",
                                    marginTop: "23px",
                                    width: "fit-content",
                                  }}
                                >
                                  <DeleteIcon
                                    style={{
                                      color: "#ff4d4a",
                                      cursor: "pointer",
                                      fontSize: "1rem",
                                    }}
                                    onClick={() =>
                                      handleRefOperatorDelete(_index)
                                    }
                                  />
                                </div>
                              </Grid>
                            )}

                            {(refObj?.obj?.operator === "in" ||
                              refObj?.obj?.operator === "not in" ||
                              refObj?.obj?.operator === "between") &&
                              refObj?.obj?.value &&
                              refObj?.obj?.value?.map((child, i) => {
                                return (
                                  <Grid
                                    item
                                    container
                                    spacing={2}
                                    xs={5}
                                    sm={12}
                                    alignItems={"center"}
                                  >
                                    <Grid item xs={5} sm={4}>
                                      {refObj?.obj?.operator === "between" ? (
                                        <TextBox
                                          type={"text"}
                                          value={child ?? ""}
                                          isError={refObj?.error?.value}
                                          errorMessage={
                                            refObj?.error?.value &&
                                            "Please select Value"
                                          }
                                          label="Value"
                                          placeholder={"Value"}
                                          disabled={!refObj?.obj?.fact}
                                          onChange={(e) =>
                                            updateRefStateChild(
                                              "value",
                                              e.target.value,
                                              _index,
                                              i
                                            )
                                          }
                                        />
                                      ) : (
                                        <Select
                                          value={{
                                            label: child ?? "",
                                            value: child ?? "",
                                          }}
                                          onChange={(e, value) =>
                                            updateRefStateChild(
                                              "value",
                                              value?.value,
                                              _index,
                                              i
                                            )
                                          }
                                          disabled={!refObj?.obj?.fact}
                                          options={
                                            dataRefFilter
                                              ? dataRefFilter?.map((v) => {
                                                  return { value: v, label: v };
                                                })
                                              : []
                                          }
                                          label="Value"
                                          placeholder={"Value"}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={2} sm={3}>
                                      <div style={{ display: "flex" }}>
                                        <DeleteIcon
                                          style={{
                                            color: "#ff4d4a",
                                            cursor: "pointer",
                                            marginTop: 22,
                                            fontSize: 17,
                                          }}
                                          onClick={() =>
                                            handleRefDeleteChild(_index, i)
                                          }
                                        />
                                        {refObj?.obj?.value?.length - 1 ===
                                          i && (
                                          <AddOutlinedIcon
                                            style={{
                                              color: "#231535",
                                              cursor: "pointer",
                                              marginTop: 20,
                                            }}
                                            onClick={() =>
                                              handleRefAddChild(_index, i)
                                            }
                                          />
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                );
                              })}

                            {(refObj?.obj?.operator !== "in" ||
                              refObj?.obj?.operator !== "not in" ||
                              refObj?.obj?.operator === "between") && (
                              <Grid item xs={5} sm={3}>
                                {refObj?.obj?.operator === "in" ||
                                refObj?.obj?.operator === "not in" ||
                                refObj?.obj?.operator === "between" ? (
                                  <></>
                                ) : (
                                  <Select
                                    value={{
                                      label: refObj?.obj?.value ?? "",
                                      value: refObj?.obj?.value ?? "",
                                    }}
                                    onChange={(e, value) =>
                                      updateRefObj(
                                        "value",
                                        value?.value,
                                        _index
                                      )
                                    }
                                    disabled={!refObj?.obj?.fact}
                                    options={
                                      refObj?.obj?.fact && dataRefFilter
                                        ? dataRefFilter?.map((v) => {
                                            return { value: v, label: v };
                                          })
                                        : []
                                    }
                                    label="Value"
                                    placeholder={"Value"}
                                  />
                                )}
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                      <br />
                      <Grid container xs={12} justifyContent={"center"}>
                        <Button
                          sx={{ background: theme.palette.button.primary }}
                          variant="contained"
                          onClick={() => handleAdd()}
                        >
                          {_index !== null && _index >= 0 ? "UPDATE" : "ADD"}
                        </Button>
                        &nbsp;
                        {_index !== null && _index >= 0 ? (
                          <Button
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={() => handleClear()}
                          >
                            CANCEL
                          </Button>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                style={{ borderRight: "1px solid #eee" }}
              >
                <div
                  style={{ background: "#fff" }}
                  className={classes.addruleDrawer}
                >
                  {addlist?.length > 0 && (
                    <div
                      className={classes.titleSection}
                      style={{ borderBottom: "1px solid #ced3dd" }}
                    >
                      <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                        &nbsp;{state.rule_name}
                      </Typography>
                    </div>
                  )}

                  {addlist?.length > 0 && (
                    <Typography
                      style={{
                        margin: "0px 16px 0px",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      Added Conditions
                    </Typography>
                  )}

                  {!addlist?.length > 0 && (
                    <div
                      style={{
                        height: "64vh",
                        margin: "auto",
                        display: "flex",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        <img src="../images/box@2x.png" />
                        <br />
                        No Conditions Added
                      </Typography>
                    </div>
                  )}

                  {addlist?.length > 0 && (
                    <div className={classes.addruleDrawerBody1}>
                      {addlist?.map((_item, _index) => {
                        return (
                          <div
                            className={classes.addruleDrawer}
                            style={{ marginBottom: 6 }}
                          >
                            <div style={{ height: "auto" }}>
                              <Card className={classes.card}>
                                <div className={classes.count}>
                                  {_index + 1}
                                </div>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <Typography className="title">
                                      Column Name{" "}
                                      <div className="body">
                                        {_item?.fact ?? ""}
                                      </div>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography className="title">
                                      Operator{" "}
                                      <div className="body">
                                        {_item?.operator ?? ""}
                                      </div>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <div
                                      style={{
                                        display: "flex",
                                        margin: "4px -8px",
                                      }}
                                    >
                                      <EditOutlinedIcon
                                        style={{
                                          color: "#8863FB",
                                          fontSize: 21,
                                        }}
                                        onClick={() =>
                                          handleEdit(_item, _index)
                                        }
                                      />
                                      <DeleteOutlineIcon
                                        style={{
                                          color: "#FF4D4A",
                                          fontSize: 21,
                                        }}
                                        onClick={() => handleDelete(_index)}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography
                                      className="title"
                                      style={{ marginTop: 6 }}
                                    >
                                      Key
                                      <div className="body">
                                        {_item?.value &&
                                          _item?.value?.map((child, i) => {
                                            return i ===
                                              _item?.value?.length - 1
                                              ? child?.key
                                              : child?.key + ", ";
                                          })}
                                      </div>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography
                                      className="title"
                                      style={{ marginTop: 6 }}
                                    >
                                      Value{" "}
                                      <div className="body">
                                        {_item?.value &&
                                          _item?.value?.map((child, i) => {
                                            return i ===
                                              _item?.value?.length - 1
                                              ? child?.value
                                              : child?.value + ", ";
                                          })}
                                      </div>
                                    </Typography>
                                  </Grid>

                                  {_item?.reference?.operator &&
                                    _item?.reference?.operator !== "" && (
                                      <Grid item xs={6}>
                                        <Typography
                                          className="title"
                                          style={{ marginTop: 6 }}
                                        >
                                          Reference Operator
                                          <div className="body">
                                            {_item?.reference?.operator ?? ""}
                                          </div>
                                        </Typography>
                                      </Grid>
                                    )}
                                  {_item?.reference?.operator &&
                                    _item?.reference?.operator !== "" && (
                                      <Grid item xs={6}>
                                        <Typography
                                          className="title"
                                          style={{ marginTop: 6 }}
                                        >
                                          Reference Value
                                          <div className="body">
                                            {_item?.reference?.operator ===
                                              "in" ||
                                            _item?.reference?.operator ===
                                              "not in" ||
                                            Array.isArray(
                                              _item?.reference?.value
                                            ) ? (
                                              <>
                                                {_item?.reference?.value &&
                                                  _item?.reference?.value?.map(
                                                    (child, i) => {
                                                      return i ===
                                                        _item?.reference?.value
                                                          ?.length -
                                                          1
                                                        ? child
                                                        : child + ", ";
                                                    }
                                                  )}
                                              </>
                                            ) : _item?.reference?.operator ===
                                              "between" ? (
                                              <>{`${
                                                _item?.reference?.value?.from ??
                                                ""
                                              }, ${
                                                _item?.reference?.value?.to ??
                                                ""
                                              }`}</>
                                            ) : _item?.reference?.operator ===
                                              "same value" ? (
                                              <>{"same value"}</>
                                            ) : (
                                              <>
                                                {_item?.reference?.value ?? ""}
                                              </>
                                            )}
                                          </div>
                                        </Typography>
                                      </Grid>
                                    )}
                                </Grid>
                              </Card>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            <div className={classes.footer}>
              <Button
                className={classes.cancelButton}
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                sx={{ background: theme.palette.button.primary }}
                variant="contained"
                onClick={() => handleSave()}
              >
                {vendor_id ? "Update" : "Save"}
              </Button>
            </div>
          </>
        )}
        <DrawerComponent
          open={isView}
          anchor="right"
          handleClose={() => setIsview(!isView)}
          children={
            <>
              <div className={classes.drawertitle}>
                <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                  Conditions
                </Typography>
                {/* <CloseOutlinedIcon onClick={() => setIsview(!isView)} /> */}
              </div>

              <div className={classes.drawer}>
                {!addlist?.length > 0 && (
                  <div
                    style={{
                      height: "64vh",
                      margin: "auto",
                      display: "flex",
                    }}
                  >
                    <Typography style={{ textAlign: "center", margin: "auto" }}>
                      <img src="../images/box@2x.png" />
                      <br />
                      No Conditions Added
                    </Typography>
                  </div>
                )}

                {addlist?.length > 0 && (
                  <div className={classes.addruleDrawerBody1}>
                    {addlist?.map((_item, _index) => {
                      return (
                        <div
                          className={classes.addruleDrawer}
                          style={{ marginBottom: 6 }}
                        >
                          <div style={{ height: "auto" }}>
                            <Card className={classes.card}>
                              <div className={classes.count}>{_index + 1}</div>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography className="title">
                                    Column Name{" "}
                                    <div className="body">
                                      {_item?.fact ?? ""}
                                    </div>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography className="title">
                                    Operator{" "}
                                    <div className="body">
                                      {_item?.operator ?? ""}
                                    </div>
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography
                                    className="title"
                                    style={{ marginTop: 6 }}
                                  >
                                    Key
                                    <div className="body">
                                      {_item?.value &&
                                        _item?.value?.map((child, i) => {
                                          return i === _item?.value?.length - 1
                                            ? child?.key
                                            : child?.key + ", ";
                                        })}
                                    </div>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    className="title"
                                    style={{ marginTop: 6 }}
                                  >
                                    Value
                                    <div className="body">
                                      {_item?.value &&
                                        _item?.value?.map((child, i) => {
                                          return i === _item?.value?.length - 1
                                            ? child?.value?.value ||
                                                child?.value
                                            : child?.value?.value ||
                                                child?.value + ", ";
                                        })}
                                    </div>
                                  </Typography>
                                </Grid>
                                {_item?.reference?.operator &&
                                  _item?.reference?.operator !== "" && (
                                    <Grid item xs={6}>
                                      <Typography
                                        className="title"
                                        style={{ marginTop: 6 }}
                                      >
                                        Reference Operator
                                        <div className="body">
                                          {_item?.reference?.operator ?? ""}
                                        </div>
                                      </Typography>
                                    </Grid>
                                  )}
                                {_item?.reference?.operator &&
                                  _item?.reference?.operator !== "" && (
                                    <Grid item xs={6}>
                                      <Typography
                                        className="title"
                                        style={{ marginTop: 6 }}
                                      >
                                        Reference Value
                                        <div className="body">
                                          {_item?.reference?.operator ===
                                            "in" ||
                                          _item?.reference?.operator ===
                                            "not in" ||
                                          Array.isArray(
                                            _item?.reference?.value
                                          ) ? (
                                            <>
                                              {_item?.reference?.value &&
                                                _item?.reference?.value?.map(
                                                  (child, i) => {
                                                    return i ===
                                                      _item?.reference?.value
                                                        ?.length -
                                                        1
                                                      ? child
                                                      : child + ", ";
                                                  }
                                                )}
                                            </>
                                          ) : _item?.reference?.operator ===
                                            "between" ? (
                                            <>{`${
                                              _item?.reference?.value?.from ??
                                              ""
                                            }, ${
                                              _item?.reference?.value?.to ?? ""
                                            }`}</>
                                          ) : _item?.reference?.operator ===
                                            "same value" ? (
                                            <>{"same value"}</>
                                          ) : (
                                            <>{_item?.reference?.value ?? ""}</>
                                          )}
                                        </div>
                                      </Typography>
                                    </Grid>
                                  )}
                              </Grid>
                            </Card>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          }
        />
        <Snackbar
          open={alert.open}
          message={alert.message}
          ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAlert({ ...alert, open: false })}
          autoHideDuration={4000}
        />
      </div>
    </div>
  );
};
