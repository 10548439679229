import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  Container,
  Snackbar,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { ValidateEmail } from "../../utils";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import GoogleLogin from "react-google-login";
const useStyles = makeStyles((theme) => ({
  rootContainer: {
    background: "#ebeff399",
    height: "100vh",
    display: "flex",
    alignItems: "center",
  },
  root: {
    padding: 0,
  },
  logoContainer: {
    display: "flex",
  },
  label: {
    marginTop: "12px",
    marginBottom: "6px",
    fontSize: "12px",
    color: "#848484",
    "& span": {
      color: "#ff4d4a",
    },
  },
  forgotPassword: {
    marginTop: "12px",
    marginBottom: "6px",
    textDecoration: "unset",
    "& .MuiTypography-root": {
      fontWeight: 500,
      fontSize: 12,
      color: theme.palette.primary.main,
    },
  },
  signupform: {
    padding: "24px 40px",
    backgroundColor: "white",
    borderRadius: "16px",
    border: "1px solid #e7e6e6",
    boxShadow: "0px 8px 69px #0000001A",
    maxWidth: 400,
    margin: "auto",
  },
  btn: {
    marginTop: 40,
    boxShadow: "none",
    borderRadius: "8px",
    background:
      "linear-gradient(90deg, rgba(222,87,229,1) 0%, rgba(187,99,251,1) 100%)",

    height: 44,

    textTransform: "uppercase",
    fontWeight: "normal",
  },
  title: {
    marginTop: "24px",
    marginBottom: "16px",
    fontWeight: 700,
    textAlign: "center",
    fontSize: 24,
  },
  subtitle: {
    marginBottom: "24px",
    color: "#848484",
    textAlign: "center",
    fontSize: 12,
  },
  footerText: {
    color: "#848484",
    fontSize: 12,
    textAlign: "center",
  },
  footerImageContainer: {
    display: "flex",
    marginTop: 16,
    marginBottom: 16,
    "& img": {
      margin: "auto",
    },
    cursor: "pointer",
  },
  already: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    fontFamily: "todo_semibold",
  },

  countrycode: {
    width: "95%",
  },
  error: {
    color: "red",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: 14,
      fontWeight: 700,
    },
  },
}));
const InitialState = {
  email: "",
  password: "",
  name: "",
  phone: "",
  error: {
    email: "",
    password: "",
    name: "",
    phone: "",
  },
};
export const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = React.useState();
  const [login, setLogin] = React.useState({ ...InitialState });
  const [loading, setLoading] = React.useState(false);

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const updateState = (key, value) => {
    let error = login.error;
    error[key] = "";
    setLogin({ ...login, [key]: value, error });
  };

  const isIamValideToRegister = () => {
    let isValid = true;
    let error = login.error;
    //Checking Email
    if (login.email.length === 0) {
      isValid = false;
      error.email = "Email is Required";
    }
    if (ValidateEmail(login.email) === false) {
      isValid = false;
      error.email = "Please Enter Valid Email";
    }

    //Check password
    if (login.password.length === 0) {
      isValid = false;
      error.password = "Password is Required";
    }

    setLogin({ ...login, error });
    return isValid;
  };

  const onSendLoginBtnClicked = () => {
    if (isIamValideToRegister()) {
      setLoading(true);
      const body = {
        email_id: login.email,
        password: login.password,
      };

      NetworkCall(`${config.api_url}auth/signin`, "POST", body)
        .then((res) => {
          localStorage.setItem(
            "auth_token",
            JSON.stringify(res?.data?.auth_token)
          );

          localStorage.setItem(
            "profile_id",
            JSON.stringify(res?.data?.profileId)
          );
          setLoading(false);

          let arr = res?.data?.roles?.feature_ids;

          let temp_arr = arr.map((x) => {
            if(x.has_access){
              return x.feature_id
            }
          })

          console.log(temp_arr)

          localStorage.setItem("user_role", res?.data?.roles?.role_name);
          localStorage.setItem("roles", JSON.stringify(temp_arr))

          setTimeout(() => {
            setTimeout(() => {
              history.push("/listing");
            }, 100);
          }, 100);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setAlert({
            open: true,
            message: "Something Went Wrong",
            backgroundColor: "#FF3232",
          });
        });
    } else {
      return false;
    }
  };

  const OnSuccessGoogle = (data) => {
    NetworkCall(`${config.api_url}auth/google/signin`, "POST", data?.profileObj)
      .then((res) => {
        localStorage.setItem(
          "auth_token",
          JSON.stringify(res?.data?.auth_token)
        );

        localStorage.setItem(
          "profile_id",
          JSON.stringify(res?.data?.profileId)
        );
        setLoading(false);

        setTimeout(() => {
          setTimeout(() => {
            history.push("/listing");
          }, 100);
        }, 100);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setAlert({
          open: true,
          message: "Something Went Wrong",
          backgroundColor: "#FF3232",
        });
      });
  };
  const OnFailureGoogle = () => {
    setLoading(false);
  };

  return (
    <div className={classes.rootContainer}>
      <Container maxWidth="xs" className={classes.root}>
        <div className={classes.signupform}>
          <div className={classes.logoContainer}>
            <img
              src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRL9jCDzgzw-_i6gNZbKutHVKf4HEQywfVnutdwm1z7yWjVvDML"
              alt="logo"
              width="180px"
              style={{ margin: "auto" }}
            />
          </div>
          <Typography variant="h6" className={classes.title}>
            Sign In
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            Sign In with your email and password
          </Typography>

          <form>
            <InputLabel className={classes.label}>
              Email Address <span>*</span>
            </InputLabel>

            <TextField
              className={classes.textbox}
              fullWidth={true}
              variant="outlined"
              size="small"
              type="email"
              placeholder="Email Id"
              value={login.email}
              onChange={(e) => updateState("email", e.target.value)}
              color="secondary"
            />
            {login.error.email.length > 0 && (
              <Typography variant={"caption"} color={"error"}>
                {login.error.email}
              </Typography>
            )}

            <Stack
              flexDirection="row"
              justifyContent="space-between"
              style={{ marginTop: 10 }}
            >
              <InputLabel className={classes.label}>
                Password <span>*</span>
              </InputLabel>
              {/* <Link to="#" className={classes.forgotPassword}>
                <Typography variant="subtitle2" align="right">
                  Forgot Password?
                </Typography>
              </Link> */}
            </Stack>

            <TextField
              className={classes.textbox}
              fullWidth={true}
              size="small"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              variant="outlined"
              color="secondary"
              value={login.password}
              onChange={(e) => updateState("password", e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <RemoveRedEyeOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {login.error.password.length > 0 && (
              <Typography variant={"caption"} color={"error"}>
                {login.error.password}
              </Typography>
            )}

            <br />
            <br />
            <Button
              variant="contained"
              fullWidth={true}
              className={classes.btn}
              onClick={onSendLoginBtnClicked}
              disabled={loading}
            >
              {loading ? "loading" : "Sign In"}
            </Button>
            <br />
            <br />

            <Typography variant="body2" className={classes.footerText}>
              Or Sign In Using
            </Typography>

            <div className={classes.footerImageContainer}>
              <GoogleLogin
                clientId={config.google_client_id}
                onSuccess={OnSuccessGoogle}
                onFailure={OnFailureGoogle}
                cookiePolicy={"single_host_origin"}
                render={(renderProps) => (
                  <img
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    alt="googleimage"
                    srcset="images/googleIcon.png,
                   images/googleIcon@2x.png 2x"
                    src="images/googleIcon@2x.png"
                  />
                )}
              />
            </div>
          </form>
        </div>
        <Snackbar
          open={alert.open}
          message={alert.message}
          ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAlert({ ...alert, open: false })}
          autoHideDuration={4000}
        />
      </Container>
    </div>
  );
};
