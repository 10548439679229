import React from "react";
import { MapSchema } from './schemamapping';
import { withAllContexts, withNavBars } from "./../../HOCs";

class MapSchemaParent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <MapSchema {...this.props} isEdit={true} isStepper={false}/>;
  }
}

export default withNavBars(withAllContexts(MapSchemaParent));
