import React, { useState } from "react";
import { TextBox, Select } from "../../../components";
import { useStyles } from "../style";
import { Button, Typography, Grid, Snackbar } from "@mui/material";

import { useTheme } from "@emotion/react";
import { withNavBars } from "../../../HOCs";
import DeleteIcon from "../../../assets/deleteIcon";
import { NetworkCall } from "../../../networkcall";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useHistory } from "react-router-dom";
import { config } from "../../../config";

const listdata = [
  { label: "same value", value: "same value" },
  { label: "is equal to", value: "is equal to" },
  { label: "is not equal to", value: "is not equal to" },
  { label: "greater than", value: "greater than" },
  { label: "greater than or equal to", value: "greater than or equal to" },
  { label: "less than", value: "less than" },
  { label: "less then or equal to", value: "less then or equal to" },
  { label: "in", value: "in" },
  { label: "not in", value: "not in" },
  { label: "contains", value: "contains" },
  { label: "between", value: "between" },
];

const CreateParingLogic = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const theme = useTheme();

  const [allCondition, setAllCondition] = useState([]);
  const [values, setValues] = useState([""]);
  const [state, setState] = React.useState({
    rule_name: "",
    condition: "",
    operator: "",
  });

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
    time: 4000,
  });
  React.useEffect(() => {
    GetAllData();
  }, []);
  React.useEffect(() => {
    history.push({
      state: {
        IndexMenu: 7,
      },
    });
    // eslint-disable-next-line
  }, []);
  const GetAllData = async (limit, offset) => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    await NetworkCall(
      `${config.api_url}pairing/columns/get/all`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        console.log(res.data);
        const data = res?.data?.columns?.map((v) => {
          return {
            value: v,
            label: v,
          };
        });
        setAllCondition(data ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateState = (name, value) => {
    if (name === "operator" && value === "between") {
      setValues(["", ""]);
    } else if (name === "operator") {
      setValues([""]);
    }
    setState({ ...state, [name]: value });
  };
  const handleOperatorDelete = (index) => {
    let initialValue = [...values];
    initialValue.splice(index, 1);
    setValues(initialValue);
  };
  const updateValue = (value, index) => {
    let initialValue = [...values];
    initialValue[index] = value;
    setValues(initialValue);
  };
  const handleAddChild = () => {
    setValues([...values, ""]);
  };
  const emptyState = () => {
    setState({
      rule_name: "",
      condition: "",
      operator: "",
    });

    setValues([""]);
  };

  const AlertValidCheck = (name) => {
    setAlert({
      open: true,
      message: `${name} is required`,
      backgroundColor: "#FF3232",
      time: 1000,
    });
    return false;
  };
  const isValidCheck = () => {
    if (state.rule_name.length === 0) {
      return AlertValidCheck("Name");
    }
    if (state.condition.length === 0) {
      return AlertValidCheck("Condition");
    }
    if (state.operator.length === 0) {
      return AlertValidCheck("Operator");
    }
    if (state.operator === "same value") {
      return true;
    }
    if (values.includes("")) {
      return AlertValidCheck("Value");
    }
    return true;
  };
  const handleSubmit = async () => {
    if (isValidCheck()) {
      let token = localStorage.getItem("auth_token").toString();
      token = token.substring(1, token.length - 1);

      let payload = {};
      if (state?.operator === "between") {
        payload = {
          rule_name: state.rule_name,
          rules_data: {
            all: [
              {
                fact: state?.condition ?? "",
                path: "payload",
                operator: state?.operator,
                value: {
                  from: values[0] ?? "",
                  to: values[1] ?? "",
                },
              },
            ],
          },
        };
      } else if (state?.operator === "in" || state?.operator === "not in") {
        payload = {
          rule_name: state.rule_name,
          rules_data: {
            all: [
              {
                fact: state?.condition ?? "",
                path: "payload",
                operator: state?.operator,
                value: [...values],
              },
            ],
          },
        };
      } else {
        payload = {
          rule_name: state.rule_name,
          rules_data: {
            all: [
              {
                fact: state?.condition ?? "",
                path: "payload",
                operator: state?.operator,
                value: values[0],
              },
            ],
          },
        };
      }
      await NetworkCall(
        `${config.api_url}pairing/rules/upsert`,
        "POST",
        payload,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      )
        .then((res) => {
          setAlert({
            open: true,
            message: res?.data?.message ?? "",

            backgroundColor: " #4BB543",
            time: 4000,
          });
          emptyState();
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something went wrong!",

            backgroundColor: "#FF3232",
            time: 4000,
          });
          console.log(err);
        });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootBody}>
        <>
          <div className={classes.titleSection}>
            <ArrowBackIcon
              className="icon"
              onClick={() => history.push("/paring")}
            />
            <Typography variant="subtitle2">Create Paring Logic</Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} style={{ borderRight: "1px solid #eee" }}>
              <div className={classes.addruleDrawer}>
                <div className={classes.addruleDrawerBody}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextBox
                        label={"Paring Logic name"}
                        type={"text"}
                        value={state.rule_name}
                        placeholder={"Paring Logic name"}
                        onChange={(e) =>
                          updateState("rule_name", e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <br /> <br />
                  Condition :
                  <br />
                  <div className={classes.addlist}>
                    <Grid
                      container
                      spacing={2}
                      alignItems={"center"}
                      style={{
                        padding: "16px 0px",
                      }}
                    >
                      <Grid item xs={6} sm={4}>
                        <Select
                          style={{ width: "100%" }}
                          disablePortal
                          value={{
                            label: state?.condition ?? "",
                            value: state?.condition ?? "",
                          }}
                          onChange={(e, value) =>
                            updateState("condition", value?.value)
                          }
                          options={allCondition ?? []}
                          label="Column Name"
                          placeholder="Column Name"
                        />
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Select
                          style={{ width: "100%" }}
                          disablePortal
                          value={{
                            label: state?.operator ?? "",
                            value: state?.operator ?? "",
                          }}
                          onChange={(e, value) =>
                            updateState("operator", value?.value)
                          }
                          options={listdata ?? []}
                          label="Operator"
                          placeholder="Operator"
                        />
                      </Grid>
                      {values &&
                        values?.map((val, index) => (
                          <Grid
                            item
                            xs={6}
                            sm={3}
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "center",
                            }}
                          >
                            <TextBox
                              label={`Value ${index + 1}`}
                              type={"text"}
                              value={val ?? ""}
                              placeholder={`Value ${index + 1}`}
                              onChange={(e) =>
                                updateValue(e.target.value, index)
                              }
                              disabled={
                                state?.operator === "same value" ? true : false
                              }
                            />

                            {(state?.operator === "in" ||
                              state?.operator === "not in") &&
                              index > 0 && (
                                <Grid
                                  style={{
                                    padding: "6px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <DeleteIcon
                                    style={{
                                      color: "#ff4d4a",
                                      cursor: "pointer",
                                      fontSize: "1rem",
                                    }}
                                    onClick={() => handleOperatorDelete(index)}
                                  />
                                </Grid>
                              )}
                          </Grid>
                        ))}
                      {(state?.operator === "in" ||
                        state?.operator === "not in") && (
                        <Grid
                          item
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <AddCircleOutlineIcon
                            style={{
                              color: "#231535",
                              cursor: "pointer",
                              marginTop: 20,
                            }}
                            onClick={() => handleAddChild()}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <br /> <br /> <br />
                    <Grid container xs={12} justifyContent={"center"}>
                      <Button
                        sx={{ background: theme.palette.button.primary }}
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        ADD
                      </Button>
                      &nbsp;
                      <Button
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={() => history.push("/paring")}
                      >
                        CANCEL
                      </Button>
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={4} style={{ borderRight: "1px solid #eee" }}>
              <div
                style={{ background: "#fff" }}
                className={classes.addruleDrawer}
              >
                <div
                  style={{
                    height: "64vh",
                    margin: "auto",
                    display: "flex",
                  }}
                >
                  <Typography style={{ textAlign: "center", margin: "auto" }}>
                    <img src="../images/box@2x.png" alt="logo" />
                    <br />
                    No Conditions Added
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      </div>
      <Snackbar
        open={alert.open}
        message={alert.message}
        ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={Number(alert.time)}
      />
    </div>
  );
};

export default withNavBars(CreateParingLogic);
