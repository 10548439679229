import React from "react";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import LabelValueInfo from "../../../components/listItem/labelValueInfo";
import WithActionMenu from "../../../components/listItem/withActionMenu";
import format from "date-fns/format";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  root: {
    border: "1px solid #DCDCDC",
    padding: "10px",
    boxShadow: "unset",
    borderRadius: "10px",
  },
  title: {
    fontSize: "15px",
    fontWeight: 500,
  },
  viewMoreButton: {
    alignSelf: "center",
    textTransform: "unset",
    fontSize: 13,
  },
  iconbutton: {
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
});

const dateFormat = "dd/MM/yyyy";

export const ListItem = WithActionMenu(
  ({ item, showViewMore, handleMenuOpen, onViewMoreClick, ...props }) => {
    const classes = useStyles();
    return (
      <Paper className={classes.root}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            <LabelValueInfo label="Rule Name" value={item?.name} />
          </Grid>
          <Grid item>
            <LabelValueInfo
              label="Created on"
              value={format(new Date(item?.createdAt), dateFormat)}
            />
          </Grid>
          <Divider orientation="vertical" flexItem />{" "}
          <Grid item>
            <LabelValueInfo
              label="Last Updated on"
              value={format(new Date(item?.updatedAt), dateFormat)}
            />
          </Grid>
          <Grid item>
            {showViewMore && (
              <Button
                className={classes.viewMoreButton}
                color="primary"
                onClick={onViewMoreClick}
              >
                View more
              </Button>
            )}
            <IconButton
              className={classes.iconbutton}
              size="small"
              disableRipple
              onClick={handleMenuOpen}
            >
              <MoreVertIcon htmlColor="#231535" />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
