import React from "react";
import { Listing } from './listing';
import { withNavBars } from "./../../HOCs";
import ListContextProvider from "../../contexts/listcontext";

class ListingParent extends React.Component {
  render() {
    return(
      <ListContextProvider>
        <Listing />
        </ListContextProvider>
    )
  }
}

export default withNavBars(ListingParent);
