import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "32px 24px",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    display: "flex",
    marginBottom: 26,
  },
  avatar: {
    fontSize: 14,
    marginLeft: 4,
    backgroundColor: theme.palette.primary.main,
    marginTop: 4,
  },
  addruleDrawer: {
    // width: 600,
    backgroundColor: "#fff",
    borderRadius: 6,
    zIndex: 1000,
    // [theme.breakpoints.down("sm")]: {
    //     width: 300,
    // },
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20,
    borderBottom: "1px solid #eeeeee",
    "& .MuiTypography-subtitle2": {
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 40,
    maxHeight: 40,
  },
  titlesection: {
    display: "flex",
    alignItems: "center",
    margin: "20px 20px 14px 20px",
    // align-items: center;
    justifyContent: "left",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: 16,
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
    },
  },
  addruleDrawerBody: {
    padding: 20,
    // height: "65vh",
    overflow: "auto",
  },
  footer: {
    borderTop: "1px solid #eeeeee",
    bottom:96,
    right: 0,
    display: "flex",

    justifyContent: "flex-end",

    overflow: "hidden",
    padding: 10,
    background: "#fff",
    // position: "absolute",
    width: "96%",
    left: "24px",
    borderRadius: 6,
  },
  addlist: {
    overflow: "auto"
    // paddingBottom: 50,
    // maxHeight: "240px", overflow: "auto"
  },
  card: {
    border: "1px solid #DCDCDC",
    padding: 20,
    borderRadius: 6,
    boxShadow: "#d9d9d97a 0px 20px 25px",
  },
  cancelButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    borderColor: "#84848466 !important",
    "& :hover": {
      color: theme.palette.secondary.main,
      borderColor: "#84848466 !important",
    },
  },
}));
const useDetailDrawerStyles = makeStyles((theme) => ({
  drawerRoot: {
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20,
    borderBottom: "1px solid #eeeeee",
    "& .MuiTypography-subtitle2": {
      fontWeight: 700,
      fontSize: 18,
    },
    "& svg": {
      cursor: "pointer",
    },
  },
  drawerBody: {
    padding: 20,
  },
  divider: {
    borderColor: "#84848429",
    marginTop: 24.5,
    marginBottom: 22,
  },
}));

const useDetailDrawerItemStyles = makeStyles({
  root: {
    columnGap: 10,
  },
  text: {
    color: "#231535",
    fontSize: 16,
  },
  label: {
    fontWeight: 500,
  },
  value: {
    fontWeight: 700,
  },
});

export { useStyles, useDetailDrawerStyles, useDetailDrawerItemStyles };
