import dotenv from "dotenv";
dotenv.config();

let config = {
  api_url: `${process.env.REACT_APP_API_BASE_URL}`,
  feed_url: `${process.env.REACT_APP_FEED_URL}`,
  graphql_url: `${process.env.REACT_APP_GRAPHQL_PROTOCAL}${process.env.REACT_APP_GRAPHQL_HOST}${process.env.REACT_APP_GRAPHQL_PORT}${process.env.REACT_APP_GRAPHQL_BASE_URL}`,
  graphql_web_socket_url: `${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PROTOCAL}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_HOST}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PORT}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_BASE_URL}`,
  google_client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
};

export { config };
