import React, { useState, useEffect } from "react";
import { withNavBars } from "../../../HOCs";
import Grid from "@mui/material/Grid";
import MuiPaper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles, styled } from "@mui/styles";
import { VendorItem } from "../../viewvendor/components";
import { useHistory, useParams } from "react-router-dom";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import calendarIcon from "../../../assets/icons/calendar.svg";
import personIcon from "../../../assets/icons/person.svg";
import timerIcon from "../../../assets/icons/timer.svg";
import filterIcon from "../../../assets/icons/filter.svg";
import renameIcon from "../../../assets/icons/rename.svg";
import marginIcon from "../../../assets/icons/marginrule.svg";
// Styles
const Paper = styled(MuiPaper, {
  shouldForwardProp: (prop) => prop !== "moreGutter",
})(({ moreGutter }) => ({
  height: "100%",
  border: "1px solid #DCDCDC",
  borderRadius: 10,
  padding: "32px 24px",
  ...(moreGutter && {
    padding: "32px",
  }),
}));

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "20px 16px 0px",
  },
  headerText: {
    fontSize: 20,
    fontWeight: 700,
  },
  editDetailButton: {
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: 1,
    textTransform: "unset",
  },
  main: {
    marginTop: 24,
    height: "80%",
  },
  divider: {
    borderColor: "#84848429",
    marginTop: 18,
    marginBottom: 16,
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      display: "flex",
      fontWeight: "bold",
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: "-10px",
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
      marginTop: "-2px",
    },
  },
}));

const ViewSchedule = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  // States
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  // Side Effects
  useEffect(() => {
    history.push({
      state: {
        IndexMenu: 5,
      },
    });
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    setLoading(true);
    NetworkCall(
      `${config.api_url}schedule/get?id=${params.id}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        setData(res.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  // Handle Functions
  const onEditVendorDetailsClick = () => {
    history.push({
      pathname: `/schedule/edit/${params.id}`,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <span>
          <Typography variant="subtitle2">
            <ArrowBackIcon
              className="icon"
              onClick={() => history.push("/schedule")}
            />
            {data?.schedule_name ?? ""}
          </Typography>
        </span>
        <div>
          <Button
            className={classes.editDetailButton}
            color="primary"
            disableRipple
            variant="contained"
            onClick={onEditVendorDetailsClick}
          >
            Edit Details
          </Button>
        </div>
      </div>
      <Grid
        container
        xs={12}
        columnGap={1.5}
        wrap="nowrap"
        className={classes.main}
      >
        <Grid item xs={12}>
          <Paper elevation={0} moreGutter>
            <Grid container rowGap={5}>
              <Grid item xs={12} md={3}>
                <VendorItem
                  loading={loading}
                  icon={calendarIcon}
                  label={"Schedule Name"}
                  value={data?.schedule_name ?? "-"}
                />
              </Grid>
              {data?.schedule_for === "Fetch" && (
                <Grid item xs={12} md={3}>
                  <VendorItem
                    loading={loading}
                    icon={personIcon}
                    label={"Email"}
                    value={data?.email_id ?? "-"}
                  />
                </Grid>
              )}

              <Grid item xs={12} md={3}>
                <VendorItem
                  loading={loading}
                  icon={calendarIcon}
                  label={"Schedule Type"}
                  value={data?.schedule_for ?? "-"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <VendorItem
                  loading={loading}
                  icon={timerIcon}
                  label={"Schedule Timer"}
                  value={data?.cron_time ?? "-"}
                />
              </Grid>
              {data?.schedule_for === "Extract" && (
                <>
                  <Grid item xs={12} md={3}>
                    <VendorItem
                      loading={loading}
                      icon={personIcon}
                      label={"Vendor Name"}
                      value={data?.vendor?.first_name ?? "-"}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <VendorItem
                      loading={loading}
                      icon={filterIcon}
                      label={"Filter Rule"}
                      value={data?.vendor_rule?.rule_name ?? "-"}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <VendorItem
                      loading={loading}
                      icon={renameIcon}
                      label={"Rename Rule"}
                      value={data?.vendor_rename_rule?.rule_name ?? "-"}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <VendorItem
                      loading={loading}
                      icon={marginIcon}
                      label={"Margin Rules "}
                      value={
                        data?.margin_rule
                          ?.map((val) => val?.rule_name)
                          .join(", ") ?? "-"
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withNavBars(ViewSchedule);
