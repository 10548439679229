import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete, Typography, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.palette.text.secondary,
    fontSize: "12px",
    textAlign: "left",
    "& sup": {
      color: theme.palette.error.main,
    },
  },
  selectbox: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: 40,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 14px",
    },
    "& .MuiOutlinedInput-root": {
      padding: 3,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CED3DD !important",
    },
    "& .MuiAutocomplete-input": {
      padding: "3px 14px !important",
    },
  },
}));

export const Select = (props) => {
  const classes = useStyles(props);

  const getLabel = (props) => {
    return (
      <Typography variant="body1" className={classes.Label} gutterBottom>
        {props.label}
        <sup>*</sup>
        {/* {props?.isrequired && <sup>*</sup>} */}
      </Typography>
    );
  };
  return (
    <div className={classes.root}>
      {props?.label && getLabel(props)}

      <div className={classes.text}>
        <Autocomplete
          className={classes.selectbox}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} placeholder={props.placeholder} />
          )}
          {...props}
        />
      </div>

      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};
Select.propTypes = {
  value: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
};
Select.defaultProps = {
  label: "Textbox",
  placeholder: "Type Here...",
};
