import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
    "& sup": {
      color: theme.palette.error.main,
    },
  },
  datepicker: {
    width: "100%",
    border: "none",
    borderRadius: "10px !important",
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px !important",
    },
    "& .css-u2ujoz-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "10px !important",
    },
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      borderRadius: "10px !important",
      padding: "11px 14px !important",
    },
  },
  localizationStyle: {
    borderRadius: "10px !important",
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px !important",
    },
    "& .css-u2ujoz-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "10px !important",
    },
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      borderRadius: "10px !important",
      padding: "11px 14px !important",
    },
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "8px",

      boxShadow: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CED3DD !important",
    },
  },

  root: {
    height: "100%",
    padding: "20px 16px 0px",
  },
  headerText: {
    fontSize: 20,
    fontWeight: 700,
  },
  editDetailButton: {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: 1,
    textTransform: "unset",
  },
  main: {
    marginTop: 24,
    height: "80%",
  },
  divider: {
    borderColor: "#84848429",
    marginTop: 18,
    marginBottom: 16,
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      display: "flex",
      fontWeight: "bold",
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: "-10px",
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
      marginTop: "-2px",
    },
  },
}));

export { useStyles };
