import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  OutlinedInput,
  Grid,
  Chip,
  Button,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

const ScheduleType = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Individual",
    value: "Individual",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 5,
  //   py: 2
};

const Styledselect = styled(Select)(({ theme }) => ({
  borderRadius: "10px",
  "& .MuiOutlinedInput-input": {
    padding: "11px 14px !important",
  },
}));
const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
    "& sup": {
      color: theme.palette.error.main,
    },
  },
  datepicker: {
    width: "100%",
    border: "none",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "8px",

      boxShadow: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CED3DD !important",
    },
  },
}));

const StyledLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "12px",
  // color: "#231535",
  marginBottom: "0.35em",
  "& .css-u0qe18-MuiFormLabel-root.Mui-focused": {
    color: "#00000099 !important",
  },
}));

const RunProcess = (props) => {
  const { open, handleClose } = props;
  const theme = useTheme();
  const [margin_rule, setMarginRule] = useState([]);
  const [vendor_rule, setVendorRule] = useState();
  const [rename_rule, setRenameRule] = useState();

  const [ state, setState] = useState()

  const handleChange = (name, val) => {}

  const handleUpdate = () => {
      console.clear()
      console.log(margin_rule)
      console.log(vendor_rule)
      console.log(rename_rule)
      let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
      
      NetworkCall(
        `${config.feed_url}feed/data/extract`,
        "POST",
        {
            vendor_id: props?.vendor_id,
            rename_rules_id: rename_rule,
            filter_rules_id: vendor_rule,
            margin_rules_id: margin_rule,
        },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      ).then((res) => {
        props?.updateMessage(`Process Started`, `#4BB543`)
        handleClose()
      }).catch((err) => {
        props?.updateMessage(`Something went wrong`, `#FF3232`)
        handleClose()
      })
  }

  useEffect(() => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    NetworkCall(
      `${config.api_url}rules/get/all?vendor_id=${props?.vendor_id}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    ).then((res) => {
        console.log(res?.data)
        let filter_rules = res?.data?.filter_rules?.map((x) => { return {label: x.rule_name, value:x.id }})
        let margin_rules = res?.data?.margin_rules?.map((x) => { return {label: x.rule_name, value:x.id }})
        let rename_rules = res?.data?.rename_rules?.map((x) => { return {label: x.rule_name, value:x.id }})
        setState({
            filter_rules,
            margin_rules,
            rename_rules
        })
    }).catch((err) => {});
  }, [props?.vendor_id]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography>Vendor Name : {props?.vendor_name}</Typography>
        <FormControl fullWidth>
          <StyledLabel>
            Vendor Rule
            <sup style={{ color: theme.palette.error.main }}>*</sup>
          </StyledLabel>
          <Styledselect
            sx={{ padding: "0px !important" }}
            value={vendor_rule}
            required={true}
            input={<OutlinedInput sx={{ padding: "0px !important" }} />}
            onChange={(e) => setVendorRule(e.target.value)}
          >
            {state?.filter_rules?.map((x) => {
              return <MenuItem value={x.value}>{x.label}</MenuItem>;
            })}
          </Styledselect>
        </FormControl>
        <FormControl fullWidth>
          <StyledLabel>
            Rename Rule
            <sup style={{ color: theme.palette.error.main }}>*</sup>
          </StyledLabel>
          <Styledselect
            sx={{ padding: "0px !important" }}
            value={rename_rule}
            required={true}
            input={<OutlinedInput sx={{ padding: "0px !important" }} />}
            onChange={(e) => setRenameRule(e.target.value)}
          >
            {state?.rename_rules?.map((x) => {
              return <MenuItem value={x.value}>{x.label}</MenuItem>;
            })}
          </Styledselect>
        </FormControl>
        <FormControl fullWidth>
          <StyledLabel>
            Margin Rule
            <sup style={{ color: theme.palette.error.main }}>*</sup>
          </StyledLabel>
          <Styledselect
            sx={{ padding: "0px !important" }}
            value={margin_rule}
            required={true}
            multiple
            input={<OutlinedInput sx={{ padding: "0px !important" }} />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected?.map((value) => (
                  <>
                    {/* {console.log(state?.margin_rules?.filter((x) => { return x.value === value})[0].label)} */}
                    <Chip key={value} label={state?.margin_rules?.filter((x) => { return x.value === value})[0].label} />
                  </>
                ))}
              </Box>
            )}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              console.log(value);
              // setMarginRule(typeof value === 'string' ? value.split(',') : value)
              // let arr = margin_rule
              // console.log(e.target)
              // arr.push(value)
              setMarginRule(value);
              // console.log(margin_rule)
            }}
          >
            {state?.margin_rules?.map((x) => {
              return <MenuItem value={x.value}>{x.label}</MenuItem>;
            })}
          </Styledselect>
        </FormControl>
        <Stack
          direction="row"
          spacing={1}
          style={{ paddingTop: 9, display: "flex", justifyContent: "right" }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => {handleUpdate()}}>Run</Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default RunProcess;
