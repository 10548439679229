import React, { createContext } from "react";

const InitialState = {
  ID: "",
  name: "",
  Email: "",
  mobile: "",
  Status: "",
  Created: "",
  Last: "",
  View: "",
  Edit: "",
  API: "",
  DB: "",
  Rule: "",
  Migration: "",
  file: "",
  Description: "",
  Address1: "",
  Address2: "",
  city: "",
  state: "",
  Country: "",
  pinCode: "",
  website: "",
  createdBy: "",
  upadtedBy: "",
};

const initialStepper = {
  vendor_detail: {},
  feed_source: {},
  schema_mapping: {},
  filter_rules: {},
  rename_rules: {},
  margin_rules: {},
};

export const ListContext = createContext();

const ListContextProvider = (props) => {
  const [list, setList] = React.useState({ ...InitialState });
  const [stepper, setStepper] = React.useState({ ...initialStepper });

  const updateStepper = (key, value) => {
    setStepper({ ...stepper, [key]: value });
  };

  const updateState = (key, value) => {
    setList({ ...list, [key]: value });
    console.log(list);
  };

  return (
    <ListContext.Provider
      value={{
        list,
        updateState,
        stepper,
        updateStepper,
      }}
    >
      {props.children}
    </ListContext.Provider>
  );
};

export default ListContextProvider;
