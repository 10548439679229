import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: "32px 24px",
    // background:"#fff"
  },
  rootBody: {
    margin: "20px 16px 0px",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    display: "flex",
    marginBottom: 26,
  },
  avatar: {
    fontSize: 14,
    marginLeft: 4,
    backgroundColor: theme.palette.primary.main,
    marginTop: 4,
  },
  addruleDrawer: {
    // width: 600,
    backgroundColor: "#fff",
    borderRadius: 6,
    zIndex: 1000,
    // [theme.breakpoints.down("sm")]: {
    //     width: 300,
    // },
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: 16,
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
    },
  },
  addruleDrawerBody: {
    padding: 20,
    height: "64vh",
    overflow: "auto",
  },
  addruleDrawerBody1: {
    padding: 20,
    height: "50vh",
    overflow: "auto",
  },
  footer: {
    borderTop: "1px solid #eeeeee",
    bottom: 0,
    right: 0,
    display: "flex",

    justifyContent: "flex-end",

    overflow: "hidden",
    padding: 10,
    background: "#fff",
    position: "absolute",
    width: "97%",
    left: "17px",
    borderRadius: 6,
  },
  addlist: {
    // paddingBottom: 50,
    // maxHeight: "240px", overflow: "auto"
  },
  card: {
    position: "relative",
    border: "1px solid #d1cece",
    padding: "10px 20px 10px 10px",
    borderRadius: 6,
    boxShadow: "none",
    background: "#f6f3ff",
    "& .title": {
      color: "#231535",
      opacity: 1,
      fontSize: 13,
      marginLeft: 10,
    },
    "& .body": {
      fontWeight: 600,
      fontSize: 12,
    },
  },
  cancelButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    borderColor: "#84848466 !important",
    "& :hover": {
      color: theme.palette.secondary.main,
      borderColor: "#84848466 !important",
    },
  },
  count: {
    position: "absolute",
    marginLeft: "-11px",
    background: "#dcd1fe",
    padding: 4,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    fontSize: 14,
  },
  drawer: {
    padding: 0,
    width: "400px",
  },
  drawertitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
    borderBottom: "1px solid #ced3dd",
  },
}));

export { useStyles };
