import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Grid, Typography, Switch, Popover, MenuList, MenuItem } from '@mui/material';
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme) => ({
    root: {},
    card: {
        border: '1px solid #eeeeee',
        padding: '12px 24px',
        boxShadow: '0px 0px 10px 3px #efefef4d',
        borderRadius: '8px',
        display: "flex",
        alignItems: "center",
        marginBottom: 6,
        background: "#fff"
    },
    lastModifyCreated: {
        fontSize: '10px',
        textTransform: 'uppercase',
        color: '#9e9e9e',
    },
    more: {
        marginTop: 10,
        cursor: "pointer"
    },
    borders: {
        borderLeft: '1px dashed #e0e0e0',
        paddingLeft: 20
    },
    popover: {
        "& .MuiPopover-paper": {
            border: '1px solid #eeeeee',
            boxShadow: '0px 0px 10px 3px #efefef4d',
            borderRadius: 8,
        }
    },
    item: {
        padding: '6px 10px',
        borderBottom: '1px solid #eeeeee',
        color: '#231535',
        fontSize: 14,
        "& svg": {
            fontSize: 17,
            marginRight: 6
        }
    },
    tablefooter: {
        padding: "0px 20px 0px 20px",
        // backgroundColor:"white",  
        bottom: 0,
        position: "sticky"
    },
}));

export const List = (props) => {

    const classes = useStyles(props);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectdata, setselectdata] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [counts, setcounts] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleClick = (event, data) => {
        setselectdata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeRowsPerPage = (event, value) => {
        setRowsPerPage(parseFloat(value * 5, 5));
        setPage(0);
        setcounts(value);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const { data, ishandleEdit, ishandleDelete } = props;

    return (
        <div>
            <div className={classes.root}
                style={{ height: "63vh" }}>

                {data?.slice((rowsPerPage - 5), rowsPerPage)?.map((item, index) => {
                    return <Grid container className={classes.card}>

                        <Grid item xs={12} md={item?.margin ? 4 : 6}>
                            <Typography variant='subtitle2' className={classes.cardtitle}>{item?.rule_name ?? ''} </Typography>
                        </Grid>

                        <Grid item xs={12} md={item?.margin ? 8 : 6}>

                            <Grid container xs={12} justifyContent={'space-between'} alignContent={'center'}>

                                {item?.is_active && <Grid xs={1}><Switch checked={item?.is_active} /></Grid>}

                                {(item?.percentage_markup > 0) && <Grid className={classes.borders}>
                                    <span className={classes.lastModifyCreated}>{'PERCENTAGE MARKUP'}</span>
                                    <Typography variant='subtitle2'>{item?.percentage_markup}</Typography>
                                </Grid>}

                                {(item?.shipping_fee > 0) && <Grid className={classes.borders}>
                                    <span className={classes.lastModifyCreated}>{'SHIPPING FEE'}</span>
                                    <Typography variant='subtitle2'>{item?.shipping_fee}</Typography>
                                </Grid>}

                                {(item?.fixed_markup > 0 || item?.fixed_price > 0) && <Grid className={classes.borders}>
                                    <span className={classes.lastModifyCreated}>{item?.active ? 'FIXED MARKUP' : 'FIXED PRICE'}</span>
                                    <Typography variant='subtitle2'>{item?.active ? item?.fixed_markup : item?.fixed_price}</Typography>
                                </Grid>}

                                {(item?.output) && <Grid className={classes.borders}>
                                    <span className={classes.lastModifyCreated}>{'OUTPUT'}</span>
                                    <Typography variant='subtitle2'>{item?.output}</Typography>
                                </Grid>}

                                <Grid className={classes.borders}>
                                    <span className={classes.lastModifyCreated}>LAST MODIFIED:</span>
                                    <Typography variant='subtitle2'>{item?.last_modifyed ?? ''}</Typography>
                                </Grid>

                                <Grid className={classes.borders}>
                                    <span className={classes.lastModifyCreated}>CREATED BY:</span>
                                    <Typography variant='subtitle2'>{item?.created_at ?? ''}</Typography>
                                </Grid>

                                <Grid>
                                    <MoreVertIcon className={classes.more} onClick={(e) => handleClick(e, item)} />
                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>
                })}

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >

                    <MenuList style={{ padding: 0 }}>
                        <MenuItem
                            onClick={() => {
                                ishandleEdit(selectdata)
                                handleClose()
                            }} className={classes.item}><EditOutlinedIcon />Edit</MenuItem>
                        <MenuItem
                            onClick={() => {
                                ishandleEdit(selectdata, true)
                                handleClose()
                            }}
                            className={classes.item}><FileCopyOutlinedIcon />Clone</MenuItem>
                        <MenuItem
                            onClick={() => {
                                ishandleDelete(selectdata)
                                handleClose()
                            }}
                            className={classes.item}><DeleteOutlineOutlinedIcon />Delete</MenuItem>
                    </MenuList>

                </Popover>
            </div>

            <div className={classes.tablefooter}>

                <Grid container justifyContent="space-between" alignItems="center">

                    <Grid item xs={6}>
                        <p>Showing {rowsPerPage - 5} to {rowsPerPage} of {data?.length} entries</p>
                    </Grid>

                    <Grid item>
                        <Pagination variant="outlined" shape="rounded" count={Math.round(data?.length / 5)} page={counts} onChange={handleChangeRowsPerPage} />
                    </Grid>

                </Grid>

            </div>

        </div>
    );
};