import React from "react";
import {
  DrawerComponent,
  // List,
  TextBox,
  Select,
  ListItem,
  Subheader,
} from "../../../components";
import {
  useStyles,
  useDetailDrawerStyles,
  useDetailDrawerItemStyles,
} from "./styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Button,
  Typography,
  Grid,
  Snackbar,
  Divider,
  Switch,
  Pagination,
  Stack,
  useTheme,
  makeStyles,
} from "@mui/material";
import axios from "axios";
import moment from "moment-timezone";
import { config } from "../../../config";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const listofoperators = [
  { label: "min", value: "min" },
  { label: "max", value: "max" },
];

// const useStyles = makeStyles((theme) => ({
//   cancelButton: {
//     color: theme.palette.secondary.main,
//     backgroundColor: theme.palette.common.white,
//     borderColor: "#84848466 !important",
//     "& :hover": {
//       color: theme.palette.secondary.main,
//       borderColor: "#84848466 !important",
//     },
//   },
// }))

const DetailDrawerItem = ({ label, value }) => {
  const classes = useDetailDrawerItemStyles();
  return (
    <Stack
      className={classes.root}
      flexDirection="row"
      justifyContent="space-between"
    >
      <Typography className={classNames(classes.text, classes.label)}>
        {label}
      </Typography>
      <Typography className={classNames(classes.text, classes.value)}>
        {value}
      </Typography>
    </Stack>
  );
};

const pageSize = 5;

export const MarginAddRule = (props) => {
  const classes = useStyles(props);
  const detailDrawerClasses = useDetailDrawerStyles();

  const location = useLocation();
  const theme = useTheme();
  const history = useHistory();

  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [detailDrawerOpen, setDetailDrawerOpen] = React.useState(false);
  const [openedRule, setOpenedRule] = React.useState({});

  const handleDetailDrawerClose = () => {
    setDetailDrawerOpen((prevOpen) => !prevOpen);
  };

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const [search, setSearch] = React.useState(null);
  const [chooseVerdorIds, setchooseVerdorIds] = React.useState([]);

  const [state, setstate] = React.useState({
    isopen: false,
    listData: [],
    vendor_id: props?.vendor_id || location?.state?.ID,
    rule_name: "",
    // chooseVerdorIds: [],
    choosevendor: {},
    error: {},
    fixed_markup: "",
    percentage_markup: "",
    fixed_price: "",
    shipping_fee: "",
    output: "",
    constraints: {
      Cost: {
        from: "",
        to: "",
      },
      Carat: {
        from: "",
        to: "",
      },
    },
    id: "",
    active: false,
  });

  const isClearState = async () => {
    await setstate({
      ...state,
      isopen: false,
      constraints: {
        Cost: {
          from: "",
          to: "",
        },
        Carat: {
          from: "",
          to: "",
        },
      },
      vendor_id: "",
      rule_name: "",
      choosevendor: {},
      error: {},
      fixed_markup: "",
      percentage_markup: "",
      fixed_price: "",
      shipping_fee: "",
      output: "",
      active: false,
      id: "",
    });
  };

  const handleAddChild = (p, c, l, v) => {
    if (v) {
      state[p][c][l] = +v;
    } else {
      state[p][c][l] = v;
    }
    setstate({ ...state });
  };

  const handleClose = async () => {
    // setstate({
    //   ...state,
    //   isopen: !state.isopen,
    // });
    // isGetList();
    // await isClearState();
    history.goBack(-1);
  };

  const isGetList = async () => {
    const api_url = config.api_url + "rules/margin/get/all";
    // ?limit=5&offset=0

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    new Promise((resolve, reject) => {
      axios
        .get(`${api_url}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          const data = response.data?.rows?.map((v, i) => {
            if (v?.id) {
              return {
                ...v,
                active: v?.fixed_markup > 0 ? true : false,
                margin: true,
                last_modifyed: moment(v?.updated_at).format("YYYY-MM-DD"),
                created_at: moment(v?.created_at).format("YYYY-MM-DD"),
              };
            }
          });
          const vedor_id = data?.map((v) => {
            return {
              value: v?.vendor_id ?? "",
              label: v?.vendor?.first_name ?? "",
            };
          });
          console.log(data);
          // setstate({
          //   ...state,
          //   listData: data,
          //   chooseVerdorIds: vedor_id ?? [],
          //   isopen: false,
          // });
          setchooseVerdorIds(vedor_id ?? []);
          resolve(true);
        });
    });
  };

  React.useEffect(() => {
    if (props?.isEdit || props?.vendor_id) {
      const api_url =
        config.api_url +
        `rules/margin/get?vendor_id=${props?.vendor_id || location?.state?.ID}`;

      let token = localStorage.getItem("auth_token").toString();
      token = token.substring(1, token.length - 1);

      new Promise((resolve, reject) => {
        axios
          .get(`${api_url}`, { headers: { Authorization: `Bearer ${token}` } })
          .then(async (response) => {
            state["constraints"] = {
              Cost: {
                from: response?.data?.constraints?.Cost?.from ?? "",
                to: response?.data?.constraints?.Cost?.to ?? "",
              },
              Carat: {
                from: response?.data?.constraints?.Carat?.from ?? "",
                to: response?.data?.constraints?.Carat?.to ?? "",
              },
            };
            state["fixed_markup"] = response?.data?.fixed_markup ?? "";
            state["percentage_markup"] =
              response?.data?.percentage_markup ?? "";
            state["fixed_price"] = response?.data?.fixed_price ?? "";
            state["shipping_fee"] = response?.data?.shipping_fee ?? "";
            state["output"] = response?.data?.output ?? "";
            state.vendor_id =
              response?.data?.vendor_id ||
              location?.state?.ID ||
              props?.vendor_id;
            state.rule_name = response?.data?.rule_name;
            state.id = response?.data?.id ?? "";
            state.active = response?.data?.fixed_price > 0 ? true : false;
            await setstate({
              ...state,
              error: {},
            });
            resolve(true);
          })
          .catch((err) => {
            setAlert({
              open: true,
              message: "Something Went Wrong",
              backgroundColor: "#FF3232",
            });
          });
      });
    }
  }, []);

  React.useEffect(() => {
    let id = props?.vendor_id || location?.state?.ID;
    isGetList();
  }, []);

  const ishandleEdit = (data, isClone) => {
    // const paylod = data.margin_rules_data;
    state["constraints"] = {
      Cost: {
        from: data?.constraints?.Cost?.from ?? "",
        to: data?.constraints?.Cost?.to ?? "",
      },
      Carat: {
        from: data?.constraints?.Carat?.from ?? "",
        to: data?.constraints?.Carat?.to ?? "",
      },
    };
    state["fixed_markup"] = data?.fixed_markup ?? "";
    state["percentage_markup"] = data?.percentage_markup ?? "";
    state["fixed_price"] = data?.fixed_price ?? "";
    state["shipping_fee"] = data?.shipping_fee ?? "";
    state["output"] = data?.output ?? "";

    setstate({
      ...state,
      isopen: !state.isopen,
      vendor_id: data?.vendor_id,
      rule_name: isClone ? data?.rule_name + "-Copy" : data?.rule_name,
      error: {},
      active: data?.fixed_price > 0 ? true : false,
      id: data?.id ?? "",
    });
  };

  const ishandleDelete = (data, i) => {
    const api_url = config.api_url + "rules/margin/delete";

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    new Promise((resolve, reject) => {
      axios
        .post(
          `${api_url}`,
          {
            rule_id: data?.id ?? "",
            vendor_id: data?.vendor_id ?? "",
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          setAlert({
            open: true,
            message: `Margin rule has been deleted successfully`,
            backgroundColor: "#4BB543",
          });
          isGetList();
          resolve(true);
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something Went Wrong",
            backgroundColor: "#FF3232",
          });
        });
    });
  };

  const isValidation = () => {
    if (state.vendor_id) {
      console.clear();
      console.log(state.error);
      if (!state?.rule_name) {
        state.error["rule_name"] = true;
      }
      if (!state?.output) {
        state.error["output"] = true;
      }
      if (!state?.active) {
        if (!state?.fixed_markup) {
          state.error["fixed_markup"] = true;
        }
      }
      if (state?.active) {
        if (!state?.fixed_price) {
          state.error["fixed_price"] = true;
        }
      }
      if (!state?.percentage_markup) {
        state.error["percentage_markup"] = true;
      }
      if (!state?.shipping_fee) {
        state.error["shipping_fee"] = true;
      }
      if (
        state?.constraints?.Cost?.from === "" ||
        state?.constraints?.Cost?.to === "" ||
        state?.constraints?.Carat?.from === "" ||
        state?.constraints?.Carat?.to === ""
      ) {
        state.error["constraints"] = true;
      }
    } else {
      if (!state?.choosevendor?.value) {
        state.error["choosevendor"] = true;
      }
      if (!state?.rule_name) {
        state.error["rule_name"] = true;
      }
      if (!state?.output) {
        state.error["output"] = true;
      }
      if (!state?.active) {
        if (!state?.fixed_markup) {
          state.error["fixed_markup"] = true;
        }
      }
      if (state?.active) {
        if (!state?.fixed_price) {
          state.error["fixed_price"] = true;
        }
      }
      if (!state?.percentage_markup) {
        state.error["percentage_markup"] = true;
      }
      if (!state?.shipping_fee) {
        state.error["shipping_fee"] = true;
      }
      if (
        state?.constraints?.Cost?.from === "" ||
        state?.constraints?.Cost?.to === "" ||
        state?.constraints?.Carat?.from === "" ||
        state?.constraints?.Carat?.to === ""
      ) {
        state.error["constraints"] = true;
      }
    }
    setstate({
      ...state,
    });
  };

  const getErrorMsg = (state) => {
    if (state?.error?.output) {
      return "Output cannot be empty";
    }
    if (state?.error?.rule_name) {
      return "Rule Name is mandatory";
    }
    if (state?.error?.choosevendor) {
      return "Vendor Name is Mandatory";
    }
    if (state?.error?.constraints) {
      return "From and to values cannot be empty";
    }
    if (state?.error?.fixed_markup) {
      return "Fixed markup is mandatory";
    }
    if (state?.error?.fixed_price) {
      return "Fixed price is mandatory";
    }
    if (state?.error?.percentage_markup) {
      return "Percentage Markup is mandatory";
    }
    if (state?.error?.shipping_fee) {
      return "Shipping Fees is mandatory";
    } else {
      return "";
    }
  };

  const handleSave = async () => {
    await isValidation();

    if (
      state?.error?.fixed_markup ||
      state?.error?.fixed_price ||
      state?.error?.percentage_markup ||
      state?.error?.shipping_fee ||
      state?.error?.output ||
      state?.error?.rule_name ||
      state?.error?.choosevendor ||
      state.error["constraints"]
    ) {
      // let err_message = state?.error?.rule_name ? "Rule Name is Mandatory" : state?.error?.choosevendor ? "Vendor Name is Mandatory" : state.error["constraints"] ? "From and To Values cannot be zero" : state?.error?.output ? "Output cannot be empty" : ""
      let err_message = getErrorMsg(state);
      setAlert({
        open: true,
        message: err_message,
        backgroundColor: "#FF3232",
      });
      setstate({
        ...state,
        error: {},
      });
      return;
    }

    const payload = {
      constraints: {
        Cost: {
          from: +state["constraints"]["Cost"]["from"],
          to: +state["constraints"]["Cost"]["to"],
        },
        Carat: {
          from: +state["constraints"]["Carat"]["from"],
          to: +state["constraints"]["Carat"]["to"],
        },
      },
      fixed_markup: +state["fixed_markup"],
      percentage_markup: +state["percentage_markup"],
      fixed_price: +state["fixed_price"],
      shipping_fee: +state["shipping_fee"],
      output: state["output"],
    };

    const api_url = config.api_url + "rules/margin/upsert";

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    var pay_ = {};
    if (state?.vendor_id) {
      pay_ = {
        id: state?.id ?? "",
        rule_name: state?.rule_name ?? "",
        vendor_id: state?.vendor_id || state?.choosevendor?.value || "",
        ...payload,
      };
    } else {
      pay_ = {
        rule_name: state?.rule_name ?? "",
        vendor_id: state?.vendor_id || state?.choosevendor?.value || "",
        ...payload,
      };
    }

    new Promise((resolve, reject) => {
      axios
        .post(
          `${api_url}`,
          {
            ...pay_,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          setAlert({
            open: true,
            message: `Margin rule has been ${
              !props.isStepper ? "updated" : "added"
            } successfully`,
            backgroundColor: "#4BB543",
          });
          setTimeout(() => {
            props.isStepper ? history.push("/listing") : history.goBack(-1);
          }, 2000);
          // if (state?.vendor_id) {
          //   handleClose();
          // }
          // isGetList();
          // resolve(true);
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something Went Wrong",
            backgroundColor: "#FF3232",
          });
          setstate({
            ...state,
          });
        });
    });
  };

  const handleChange = (key, v) => {
    if (key === "active" && !v) {
      state["fixed_price"] = "";
    }
    if (key === "active" && v) {
      state["fixed_markup"] = "";
    }
    if (!isNaN(+v) && v) {
      state[key] = +v;
    } else {
      state[key] = v;
    }
    state.error = { ...state.error, choosevendor: false };
    setstate({ ...state });
  };

  const handleAddNewRule = async () => {
    await isClearState();
    setstate({
      ...state,
      isopen: !state.isopen,
      rule_name: "",
      vendor_id: "",
      choosevendor: {},
      constraints: {
        Cost: {
          from: "",
          to: "",
        },
        Carat: {
          from: "",
          to: "",
        },
      },
      fixed_markup: "",
      percentage_markup: "",
      fixed_price: "",
      shipping_fee: "",
      output: "",
      id: "",
      error: {},
      active: false,
    });
  };

  const handleViewMoreClick = (rule) => {
    setDetailDrawerOpen(true);
    setOpenedRule(rule);
  };

  const { listData, vendor_id, rule_name } = state;

  const isFilterList = search
    ? listData?.filter(
        (v) => v?.rule_name?.toLowerCase()?.includes(search?.toLowerCase()) && v
      )
    : listData;

  console.log("HIII");
  console.log(location?.state?.ID);
  return (
    <>
      {!props.isStepper && (
        <div className={classes.titlesection}>
          <ArrowBackIcon className="icon" onClick={() => history.goBack(-1)} />
          <Typography variant="subtitle2">Edit Margin Rules</Typography>
        </div>
      )}
      <div className={classes.root}>
        {
          <div className={classes.addruleDrawer}>
            {/* {!props?.isStepper && (
            <div className={classes.titleSection}>
              <Typography variant="subtitle2">
                {vendor_id ? "Edit" : "Add New"} Margin Rule
              </Typography>
              <CloseOutlinedIcon onClick={() => handleClose()} />
            </div>
          )} */}

            <div
              className={classes.addruleDrawerBody}
              style={{ marginBottom: 70 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextBox
                    label={"Margin Name"}
                    type={"text"}
                    value={rule_name}
                    isRequired={true}
                    isError={state?.error?.rule_name}
                    errorMessage={
                      state?.error?.rule_name && "Please enter the rule name"
                    }
                    placeholder={"Margin Name"}
                    onChange={(e) =>
                      setstate({
                        ...state,
                        rule_name: e.target.value,
                        error: { ...state.error, rule_name: false },
                      })
                    }
                  />
                </Grid>

                {chooseVerdorIds?.filter((v) => v.value === vendor_id)?.[0]
                  ?.label && (
                  <Grid item xs={12} sm={4}>
                    {vendor_id ? (
                      <TextBox
                        label={`Vendor ${
                          chooseVerdorIds?.filter(
                            (v) => v.value === vendor_id
                          )?.[0]?.label
                            ? `Name`
                            : `Id`
                        }`}
                        type={"text"}
                        value={
                          chooseVerdorIds?.filter(
                            (v) => v.value === vendor_id
                          )?.[0]?.label || vendor_id
                        }
                        disabled
                        placeholder={"Vendor"}
                      />
                    ) : (
                      <Select
                        disablePortal
                        options={chooseVerdorIds}
                        label="Choose Vendor"
                        placeholder="Choose Vendor"
                        isError={state?.error?.choosevendor}
                        // value={choosevendor ?? {}}
                        errorMessage={
                          state?.error?.choosevendor &&
                          "Please choose the vendor"
                        }
                        isRequired={true}
                        onChange={(e, value) =>
                          handleChange("choosevendor", value)
                        }
                      />
                    )}
                  </Grid>
                )}

                <Grid item xs={12} sm={props?.isStepper ? 4 : 2}>
                  Markup Or Price
                  <Switch
                    checked={state?.active}
                    onChange={() => {
                      handleChange("active", !state?.active);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextBox
                    label={!state?.active ? "Fixed Markup" : "Fixed Price"}
                    type={"number"}
                    value={
                      !state?.active
                        ? state["fixed_markup"]
                        : state["fixed_price"]
                    }
                    placeholder={
                      !state?.active ? "Fixed Markup" : "Fixed Price"
                    }
                    isRequired={true}
                    onChange={(e) => {
                      handleChange(
                        !state?.active ? "fixed_markup" : "fixed_price",
                        e.target.value
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextBox
                    label={"Percentage Markup"}
                    type={"number"}
                    value={state["percentage_markup"]}
                    placeholder={"Percentage Markup"}
                    onChange={(e) => {
                      handleChange("percentage_markup", e.target.value);
                    }}
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextBox
                    label={"Shipping Fee"}
                    type={"number"}
                    value={state["shipping_fee"]}
                    placeholder={"Shipping Fee"}
                    onChange={(e) => {
                      handleChange("shipping_fee", e.target.value);
                    }}
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Select
                    value={{
                      label: state?.output ?? "",
                      value: state?.output ?? "",
                    }}
                    onChange={(e, value) =>
                      handleChange("output", value?.value)
                    }
                    options={listofoperators}
                    isRequired={true}
                    disablePortal
                    label="Output"
                    placeholder={"Output"}
                  />
                </Grid>
              </Grid>
              <br />
              Condition :
              <br /> <br /> <Divider />
              <div className={classes.addlist}>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  style={{ padding: "16px 0px" }}
                >
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item xs={3}>
                        <Select
                          value={{ label: "Cost", value: "Cost" }}
                          onChange={(e, value) =>
                            handleChange("Cost", value?.value)
                          }
                          disabled
                          options={[{ label: "Cost", value: "Cost" }]}
                          label="Cost"
                          placeholder={"Cost"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Select
                          value={{ label: "between", value: "between" }}
                          onChange={(e, value) =>
                            handleChange("operator", value?.value)
                          }
                          disabled
                          options={[{ label: "between", value: "between" }]}
                          label="Operator"
                          placeholder={"Operator"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox
                          label={"From"}
                          type={"number"}
                          value={state["constraints"]["Cost"]["from"]}
                          placeholder={"From"}
                          onChange={(e) => {
                            handleAddChild(
                              "constraints",
                              "Cost",
                              "from",
                              e.target.value
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox
                          label={"To"}
                          type={"number"}
                          value={state["constraints"]["Cost"]["to"]}
                          placeholder={"To"}
                          onChange={(e) => {
                            handleAddChild(
                              "constraints",
                              "Cost",
                              "to",
                              e.target.value
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} sm={12}>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item xs={3}>
                        <Select
                          value={{ label: "Size", value: "Size" }}
                          onChange={(e, value) =>
                            handleChange("Carat", value?.value)
                          }
                          disabled
                          options={[{ label: "Size", value: "Size" }]}
                          label="Size"
                          placeholder={"Size"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Select
                          value={{ label: "between", value: "between" }}
                          onChange={(e, value) =>
                            handleChange("operator", value?.value)
                          }
                          disabled
                          options={[{ label: "between", value: "between" }]}
                          label="Operator"
                          placeholder={"Operator"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox
                          label={"From"}
                          type={"number"}
                          value={state["constraints"]["Carat"]["from"]}
                          placeholder={"From"}
                          onChange={(e) => {
                            handleAddChild(
                              "constraints",
                              "Carat",
                              "from",
                              e.target.value
                            );
                          }}
                        />{" "}
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox
                          label={"To"}
                          type={"number"}
                          value={state["constraints"]["Carat"]["to"]}
                          placeholder={"To"}
                          onChange={(e) => {
                            handleAddChild(
                              "constraints",
                              "Carat",
                              "to",
                              e.target.value
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.footer}>
                &nbsp;
                <Button
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={() =>
                    props?.isStepper ? handleClose() : handleClose()
                  }
                >
                  {props?.isStepper ? `Skip` : `Cancel`}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  sx={{ background: theme.palette.button.primary }}
                  onClick={() => handleSave()}
                >
                  {!props?.isStepper ? "Update" : "Save"}
                </Button>
              </div>
            </div>
          </div>
        }

        <DrawerComponent
          open={detailDrawerOpen}
          children={
            <div className={detailDrawerClasses.drawerRoot}>
              <div className={detailDrawerClasses.titleSection}>
                <Typography variant="subtitle2">
                  {openedRule?.rule_name}&nbsp;Details
                </Typography>
                <CloseOutlinedIcon onClick={() => handleDetailDrawerClose()} />
              </div>

              <div className={detailDrawerClasses.drawerBody}>
                <Stack>
                  {openedRule?.percentage_markup > 0 && (
                    <>
                      <DetailDrawerItem
                        label="Percentage Markup"
                        value={openedRule?.percentage_markup}
                      />
                      <Divider
                        className={detailDrawerClasses.divider}
                        flexItem
                      />
                    </>
                  )}

                  {openedRule?.shipping_fee > 0 && (
                    <>
                      <DetailDrawerItem
                        label="Shipping Fee"
                        value={openedRule?.shipping_fee}
                      />
                      <Divider
                        className={detailDrawerClasses.divider}
                        flexItem
                      />
                    </>
                  )}

                  {(openedRule?.fixed_markup > 0 ||
                    openedRule?.fixed_price > 0) && (
                    <>
                      <DetailDrawerItem
                        label="Fixed Price"
                        value={
                          openedRule?.active
                            ? openedRule?.fixed_markup
                            : openedRule?.fixed_price
                        }
                      />
                      <Divider
                        className={detailDrawerClasses.divider}
                        flexItem
                      />
                    </>
                  )}

                  {openedRule?.output && (
                    <>
                      <DetailDrawerItem
                        label="Output"
                        value={openedRule?.output}
                      />
                      <Divider
                        className={detailDrawerClasses.divider}
                        flexItem
                      />
                    </>
                  )}
                </Stack>
              </div>
            </div>
          }
          anchor={"right"}
        />

        <Snackbar
          open={alert.open}
          message={alert.message}
          ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAlert({ ...alert, open: false })}
          autoHideDuration={4000}
        />
      </div>
    </>
  );
};
