import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  FormLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Stack,
  Snackbar,
} from "@mui/material";
import { TextBox } from "../../components";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import { ExchangeMethodsOptions } from "../../utils";
import { styled } from "@mui/styles";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 16px 0px",
  },
  rootBody: {
    textAlign: "left",
    backgroundColor: theme.palette.common.white,
    padding: "20px",
    borderRadius: 8,
    border: "1px solid #DCDCDC",
  },
  tittle: {
    fontWeight: 600,
    fontSize: "20px",
    marginBottom: "10px",
  },
  sumbit: {
    textTransform: "capitalize",
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: 16,
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
    },
  },
  footer: {
    borderTop: "1px solid #eeeeee",
    position: "fixed",
    zIndex: 1000,
    bottom: 0,
    width: "100%",
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: 10,
    background: "#fff",
  },
}));


const StyledLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "12px",
  color: "#231535",
  marginBottom: "0.35em",
}));

const Styledselect = styled(Select)(({ theme }) => ({
  borderRadius: "10px",
  "& .MuiOutlinedInput-input": {
    padding: "11px 14px !important",
  },
}));

let token = localStorage?.getItem("auth_token")?.toString();
token = token?.substring(1, token.length - 1);

export const ApiConfigure = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [state, setstate] = useState({
    api_url: "",
    method: "",
    username: "",
    password: "",
    to_currency_text: "",
    from_currency_text: "",
    amount_text: "",
    auth_type: "Basic",
  });

  const [error, setError] = useState({});

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const validation = () => {
    var isError = [];
    var isErrorBool = false;
    var arr = [
      "api_url",
      "method",
      //   "username",
      //   "password",
      "to_currency_text",
      "from_currency_text",
      "amount_text",
    ];

    const message = {
      api_url: "API Url",
      method: "Methods",
      username: "User Name",
      password: "Password",
      to_currency_text: "To Currency",
      from_currency_text: "From Currency",
      amount_text: "Amount",
    };

    arr.map((v) => {
      if (!state[v]) {
        setError({ ...error, [v]: true });
        isError.push(message[v]);
        isErrorBool = true;
      }
    });
    return { isError, isErrorBool };
  };

  const Continue = () => {
    const isValidate = validation();

    if (isValidate.isErrorBool) {
      setAlert({
        open: true,
        message: `Please fill in the ${isValidate.isError.join(", ")} fields`,
        backgroundColor: "#FF3232",
      });
      return;
    }

    NetworkCall(
      `${config.api_url}exchange_rate/upsert`,
      "POST",
      {
        url: state.api_url ?? "",
        method: state?.method ?? "",
        to_currency_text: state?.to_currency_text ?? "",
        from_currency_text: state?.from_currency_text ?? "",
        amount_text: state?.amount_text ?? "",
        auth_type: state?.auth_type ?? "",
        username: state?.username ?? "",
        password: state?.password ?? "",
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        setAlert({
          open: res.data?.success ?? false,
          message: res.data?.message ?? "",
          backgroundColor: "#4BB543",
        });
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Something went wrong. Please try again later.",
          backgroundColor: "#FF3232",
        });
      });
  };

  React.useEffect(() => {
    NetworkCall(
      `${config.api_url}exchange_rate/get`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        
        // history.push("/");
        const data = res?.data?.data;
        setstate({
          api_url: data?.url ?? "",
          method: data?.method ?? "",
          to_currency_text: data?.to_currency_text ?? "",
          from_currency_text: data?.from_currency_text ?? "",
          amount_text: data?.amount_text ?? "",
          auth_type: data?.auth_type ?? "",
          username: data?.username ?? "",
          password: data?.password ?? "",
          auth_type: data?.auth_type ?? "",
          isUpdate: data?.url && true,
        });
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Something went wrong. Please try again later.",
          backgroundColor: "#FF3232",
        });
      });
  }, []);
  const handleChange = (n, v) => {
    error[n] = false;
    setstate({ ...state, [n]: v });
    setError({ ...error });
  };

  return (
    <div className={classes.root}>
        <div className={classes.titleSection}>
              <Typography variant="subtitle2">
        Exchange Configure Rate
              </Typography>
            </div>

    <div className={classes.rootBody}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextBox
                label={"API URL Configure"}
                type={"text"}
                placeholder={"api url configure"}
                value={state.api_url}
                isRequired
                onChange={(e, index) => {
                  handleChange("api_url", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <StyledLabel>
                  Network Call Methods
                  <sup style={{ color: theme.palette.error.main }}>*</sup>
                </StyledLabel>
                <Styledselect
                  sx={{ padding: "0px !important" }}
                  placeholder={`network call methods`}
                  value={state.method}
                  required={true}
                  label={`Network Call Methods`}
                  input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                  onChange={(e) => handleChange("method", e.target.value)}
                >
                  {ExchangeMethodsOptions?.map((x) => {
                    return <MenuItem value={x.value}>{x.label}</MenuItem>;
                  })}
                </Styledselect>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextBox
                  label={"User Name"}
                  type={"text"}
                  placeholder={"username"}
                  value={state.username}
                  onChange={(e, index) =>
                    handleChange("username", e.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <TextBox
                  label={"Password"}
                  type={"text"}
                  placeholder={"password"}
                  value={state.password}
                  onChange={(e, index) =>
                    handleChange("password", e.target.value)
                  }
                />
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <TextBox
                  label={"From Currency Text"}
                  type={"text"}
                  isRequired
                  placeholder={"from currency text"}
                  value={state.from_currency_text}
                  onChange={(e, index) =>
                    handleChange("from_currency_text", e.target.value)
                  }
                />
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <TextBox
                  label={"To Currency Text"}
                  type={"text"}
                  isRequired
                  placeholder={"to currency text"}
                  value={state.to_currency_text}
                  onChange={(e, index) =>
                    handleChange("to_currency_text", e.target.value)
                  }
                />
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <TextBox
                  label={"Amount Text"}
                  type={"text"}
                  isRequired
                  placeholder={"amount text"}
                  value={state.amount_text}
                  onChange={(e, index) =>
                    handleChange("amount_text", e.target.value)
                  }
                />
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <StyledLabel>
                  Authorization
                  <sup style={{ color: theme.palette.error.main }}>*</sup>
                </StyledLabel>
                <Styledselect
                  sx={{ padding: "0px !important" }}
                  placeholder={`authorization`}
                  value={state.auth_type}
                  required={true}
                  disabled
                  label={`Authorization`}
                  input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                  onChange={(e) => handleChange("auth_type", e.target.value)}
                >
                  {[{ value: "Basic", label: "Basic" }]?.map((x) => {
                    return <MenuItem value={x.value}>{x.label}</MenuItem>;
                  })}
                </Styledselect>
              </FormControl>
            </Grid>
          </Grid>

          <Stack
            gap={2}
            direction={"row"}
            justifyContent={"flex-end"}
            sx={{ mt: 2 }}
          >
            {/* <Button
              variant="outlined"
              className={classes.sumbit}
              onClick={Skip}
            >
              Cancel
            </Button> */}
            <Button
                        sx={{ background: theme.palette.button.primary }}
                        variant="contained"
                        onClick={() => Continue()}
                      >
              {!state?.isUpdate ? "Save" : "Update"}
                      </Button>
         
          </Stack>
        </Grid>
      </Grid>
      {/* <div className={classes.footer}>
                      <Button
                        sx={{ background: theme.palette.button.primary }}
                        variant="contained"
                        onClick={() => Continue()}
                      >
              {!state?.isUpdate ? "Save" : "Update"}
                      </Button>
                  
                    </div> */}
      <Snackbar
        open={alert.open}
        message={alert.message}
        ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={4000}
      />
    </div>
    </div>
  );
};
