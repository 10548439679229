import React, { useEffect } from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  Skeleton,
  Pagination,
  Typography,
  Button,
  Checkbox,
  Stack,
} from "@mui/material";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment-timezone";
import { styled } from "@mui/material/styles";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { useHistory } from "react-router-dom";
import { FormControlLabel } from "@mui/material";
import { NetWorkCallMethods } from "../../../utils";
import { useTheme } from "@mui/material";

// style section for cell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#efe6ff",
    color: theme.palette.common.black,
    paddingLeft: 25,
    fontSize: 14,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "#848484",
    opacity: "100%",
    textAlign: "center",
  },
}));
// style section for row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "none",
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    fontSize: 13,
  },
}));

export const RolesTabelComponent = ({
  data = [],
  loading = false,
  page = 0,
  setPage = () => false,
  count = 5,
  search = "",
  ScheduleEditActive = () => false,
  org_data = {},
  tittles = [],
}) => {
  const history = useHistory();

  const theme = useTheme()

  const [state, setState] = React.useState({ roles: [] });

  useEffect(() => {
    setState({ ...state, roles: data });
  }, [data]);

  const changeState = (index, event) => {
    let temp_arr = state.roles;
    temp_arr[index].is_active = !temp_arr[index].is_active;
    temp_arr[index].has_access = !temp_arr[index].has_access;
    setState({ ...state, temp_arr });
    let feature_ids = state.roles.map((x) => {
      return {
        id: x.id,
        feature_id: x.id,
        feature_name: x.feature_name,
        has_access: x.is_active,
        is_active: x.is_active,
      };
    });

    let payload = {
      role_id: org_data?.id,
      role_name: org_data?.role_name,
      feature_ids,
    };

    ScheduleEditActive(payload)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const updateState = () => {
    

    let feature_ids = state.roles.map((x) => {
      return {
        id: x.id,
        feature_id: x.id,
        feature_name: x.feature_name,
        has_access: x.is_active,
        is_active: x.is_active,
      };
    });

    let payload = {
      role_id: org_data?.id,
      role_name: org_data?.role_name,
      feature_ids,
    };

    ScheduleEditActive(payload)

   
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "0px 12px 53px rgb(29 31 114 / 7%)",
        border: "1px solid #00000012",
      }}
    >
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table" size="medium">
          <TableHead>
            <TableRow>
              {tittles.map((val) => {
                return (
                  <StyledTableCell
                    sx={{
                      textAlign: `${val.align} !important`,
                      backgroundColor: "#8863FB",
                      // width: "10px !important"
                    }}
                  >
                    {val.tittle}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody >
            {loading && state?.roles?.length === 0 ? (
              <>
                <StyledTableRow>
                  {[1, 2].map((_, key) => (
                    <StyledTableCell key={key} component="th" scope="row">
                      <Skeleton variant="text" />
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </>
            ) : (
              <>
                {state?.roles?.map((val, index) => {
                  return (
                    <StyledTableRow key={val.id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{ textAlign: `left !important`, paddingLeft: 5 }}
                      >
                        {val?.feature_name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" sx={{ textAlign: `left !important`, paddingLeft: 5 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={val.is_active}
                              key={index}
                              onChange={(e) => {
                                changeState(index, e);
                              }}
                            />
                          }
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          padding: "10px 10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* <Pagination
          count={Math.round(count / 5)}
          page={page + 1}
          color="primary"
          showFirstButton
          showLastButton
          onChange={handleChangePage}
        /> */}
      </div>
      {/* <div style={{padding: "2px 40px"}}> */}
      {/* <Stack
          direction="row"
          spacing={1}
          style={{ padding: "2px 40px", display: "flex", justifyContent: "right" }}
        >
        <Button
          variant="outlined"
          onClick={() => {
            history.goBack(-1);
          }}
        >
          Cancel
        </Button>&nbsp;&nbsp;
        <Button sx={{ background: theme.palette.button.primary }} variant="contained" onClick={() => updateState()}>Update</Button>
        </Stack> */}
      {/* </div> */}
    </Paper>
  );
};
