export const Routes = {
  home: "/",
  login: "/login",
  listing: "/listing",
  addvendor: "/addvendor",
  apiconfigure: "/apiconfigure",
  viewvendor: "/viewvendor",
  schema: "/schema",
  edit: "/edit",
  addRule: "/addrule",
  renameAddRule: "/renameaddrule",
  addVendorRuleParent: "/addvendorrule",
  vendorRenameRuleParent: "/addrenamerule",
  marginRuleParent: "/marginrule",
  marginAddRuleParent: "/addmarginrule",
  exchange: "/exchange",
  schedule: "/schedule",
  usermanagement: "/usermanagement",
  scheduleView: "/schedule/view/:id",
  scheduleEdit: "/schedule/edit/:id",
  scheduleCreate: "/schedule/create",
  errorLogs: "/error-logs",
  adduser: "/adduser",
  roleaccess: "/roleaccess",
  paringHome: "/paring",
  paringCreate: "/paring/create",
  paringUpdate: "/paring/update/:id",
};
