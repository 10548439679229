import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MuiOutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { makeStyles, styled } from "@mui/styles";
import PropTypes from "prop-types";

const OutlinedInput = styled(MuiOutlinedInput)(({ theme }) => ({
  borderRadius: 12,
  boxShadow: "0px 20px 25px #D9D9D97A",
  background: "#fff",
  "& .MuiInputAdornment-root": {
    color: "#848484",
  },
  "& .MuiOutlinedInput-input": {
    paddingLeft: 14,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#231535",
    fontSize: "16px",
    fontWeight: 700,
  },
  fab: {
    boxShadow: "0px 20px 25px #0000001F",
  },
}));

export function Subheader({ title, count, search, setSearch, onAddClick }) {
  const classes = useStyles();
  return (
    <Stack
      flexDirection="row"
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {title ? (
        <Typography className={classes.title}>
          {title}
          {count ? ` (${count})` : ""}
        </Typography>
      ) : (
        <div></div>
      )}
      <Stack flexDirection="row" columnGap={2}>
        <OutlinedInput
          size="small"
          placeholder="Search here..."
          value={search}
          onChange={setSearch}
          startAdornment={
            <InputAdornment>
              <SearchOutlinedIcon />
            </InputAdornment>
          }
        />
        <Fab
          className={classes.fab}
          size="small"
          color="primary"
          aria-label="add"
          onClick={onAddClick}
        >
          <AddIcon />
        </Fab>
      </Stack>
    </Stack>
  );
}

Subheader.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  onIconButtonClick: PropTypes.func,
};

export default Subheader;
