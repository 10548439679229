import React from "react";
import { withNavBars } from "./../../HOCs";
import { ApiConfigure } from "./exchange";

class ExchangeApiConfigureParent extends React.Component {
  render() {
    return <ApiConfigure />;
  }
}

export default withNavBars(ExchangeApiConfigureParent);