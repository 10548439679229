import React from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LocalStorageKeys } from '../../utils';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    marginTop: '20%',
  },
}));

export const Home = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const onLogOut = () => {
    localStorage.removeItem(LocalStorageKeys.authToken);
    history.push(Routes.login);
  };

  return (
    <div className={classes.root}>
      <Typography variant="body1">Welcome to Caratlane</Typography>
      <Button variant={'contained'} color={'primary'} onClick={onLogOut}>
        Logout
      </Button>
    </div>
  );
};
