import {
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Modal,
  Box,
  Snackbar
} from "@mui/material";
import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import { Add, CenterFocusStrong } from "@mui/icons-material";
import { TabelComponent } from "../../components";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import AddRoles from "../addUser/addRoles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: 15,
    height: "fit-content",
    margin: "auto 0px",
  },
  create: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  search: {
    backgroundColor: theme.palette.common.white,
    // padding: 5,
    border: "0px !important",
    borderRadius: "12px !important",
    boxShadow: "0px 20px 25px #D9D9D97A",
  },
  noBorder: {
    border: "none",
  },
}));


const Tittles = [
  // { tittle: "S.No" },
  { tittle: "Role Name", align: "left" },
  // { tittle: "Status", align: "left" },
  //   { tittle: "Role" },
  // { tittle: "Status" },
  // { tittle: "Created At" },
  // { tittle: "Updated At" },
  // { tittle: "Created by" },
  // { tittle: "updated by" },
  // { tittle: "Address" },
  { tittle: "Action" },
  // { tittle: "Edit" },
  // { tittle: "Delete" },
];

const ManageRoles = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [roles, setRoles ] = React.useState({})
  const [isEdit , setIsEdit] = React.useState(false)

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const editUser = (name) => {
    setIsEdit(true)
    setRoles(name)
    setOpen(true)
  };

  const loadData = () => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    NetworkCall(
      `${config.api_url}roles/get/all`,
      "GET",
      {},
      {
        // auth_token: "auth_token",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        console.log(res?.data?.rows);
        setLoading(false);
        setData(res?.data?.rows || res?.data?.data);
        setCount(res?.data?.count);
        setIsEdit(false)
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    loadData()
  }, [limit, offset]);

  const updateMessage = (value, color) => {
    setAlert({
      open: true,
      message: `${value}`,
      backgroundColor: `${color}`,
    });
  }

  return (
    <>
      <div className={classes.root}>
        <div
          style={{
            textAlign: "right",
            marginBottom: "25px",
            //   marginTop: "25px",
          }}
        >
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography
              className={classes.title}
            >{`Total Roles (${count})`}</Typography>
            <div>
              <TextField
                // variant="standard"
                size="small"
                type="search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                placeholder="Search by Name"
                className={classes.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  classes: { notchedOutline: classes.noBorder },
                }}
              />
              &nbsp;&nbsp;
              <IconButton
                className={classes.create}
                onClick={() => {
                  setRoles({})
                  setOpen(true);
                }}
              >
                <Add />
              </IconButton>
              {/* <Button className={classes.create} variant='contained' onClick={viewPage}>+ Add Vendor</Button> */}
            </div>
          </Stack>
        </div>
      </div>
      <TabelComponent
        state={data}
        loading={loading}
        tittles={Tittles}
        setRowsPerPage={setLimit}
        setPage={setOffset}
        page={offset}
        rowsPerPage={limit}
        count={count}
        edit={editUser}
        search={search}
        isViewNeeded={false}
        showStatus={false}
        showButton={true}
        isEditNeeded={false}
        manageButton={(data) => {history.push({pathname: "/roleaccess", state: {data, IndexMenu: 4,}})}}
        // viewVendor={viewVendor}
      />
      <AddRoles
        open={open}
        roles={roles}
        isEdit={isEdit}
        // vendor_id={data?.id}
        // vendor_name={data?.first_name}
        handleClose={() => {setOpen(false); loadData()}}
        updateMessage = {(value, color) => {updateMessage(value, color)}}
      />
      <Snackbar
          open={alert.open}
          message={alert.message}
          ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAlert({ ...alert, open: false })}
          autoHideDuration={4000}
        />
    </>
  );
};

export default ManageRoles;
