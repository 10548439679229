import React from "react";
import { withNavBars } from "../../HOCs";
import ManageRoleAccess from "../userManagement/ManageRolesAccess";

const RoleAccess = () => {
  return (
    <div
      style={{
        textAlign: "right",
        // marginBottom: "25px",
        margin: "25px",
      }}
    >
      <ManageRoleAccess />
    </div>
  );
};

export default withNavBars(RoleAccess);
