import React from "react";
import {
    Paper,
    TableRow,
    TableHead,
    TableContainer,
    TableBody,
    Table,
    Stack,
    IconButton,
    Skeleton,
    Pagination,
    Typography,
    Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import moment from "moment-timezone";
import { styled } from "@mui/material/styles";

import EyeIcon from "../../assets/eyeIcon";
import PenIcon from "../../assets/penIcon";
import LensIcon from "@mui/icons-material/Lens";
import { useTheme } from "@emotion/react";

// style section for cell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "none",
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#8863FB1F",
        color: theme.palette.common.black,
        paddingLeft: 25,
        fontSize: 14,
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        color: "#848484",
        opacity: "100%",
        textAlign: "center",
    },
}));
// style section for row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "none",
    "&:nth-of-type(odd)": {
        backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
        fontSize: 13,
    },
}));

const useStyles = makeStyles((theme) => ({
    active: {
        // border: "1px solid green",
        // padding: "10px",
        color: "green",
        textAlign: "center",
        borderRadius: "5px",
        fontWeight: 600,
    },
    inactive: {
        // border: "1px solid red",
        // padding: "10px",
        color: "red",
        textAlign: "center",
        borderRadius: "5px",
        fontWeight: 600,
    },
    icon: {
        padding: "4px 0px",
        "& svg": {
            fontSize: 18,
        },
    },
}));

const tempstate = Array.from(Array(100).keys())?.map((v, i) => {
    return {
      // id: "0fed9973-d92e-47a8-b26d-9533142177e3" + i,
      first_name: "Ideal" + i,
      mobile_no_country_code: "+91",
      mobile: `9988776677`,
      email_id: `email_id_details${i}@gmail.com`,
      is_active: i % 2 === 0 ? true : false,
      created_at: "2022-01-31T13:55:25.495Z",
      updated_at: "2022-01-31T13:55:25.495Z",
      created_by: "Mr. Firstname Secondname",
      // updated_by: "94b1697d-a1ec-4ee7-9ab8-aa51b359f9f0",
      country_name: "USA",
    };
  });

export function NiceTable(props) {
    const classes = useStyles();

    const theme = useTheme();

    const {
        state,
        loading,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count,
        search,
        isViewNeeded = true,
        showStatus = false,
        showButton = false,
        isEditNeeded = true,
        hideContact = false,
    } = props;

    moment.defaultFormat = "DD MMM YY";
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        // alert(newPage)
        setPage(newPage - 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const constructAddress = (val) => {
        return `${val?.address1 ? `${val?.address1}, ` : ''} ${val?.address2 ? `${val?.address2}, ` : ``} ${val?.city ? `${val?.city}, ` : ''}${val?.state ? `${val?.state},` : ''}${val?.country_name ? `${val?.country_name}` : ''}${val?.pin ? `- ${val?.pin}` : ``}`
    }

    const listrender = () => {
        let data = state?.filter((val) => {
            if (search === "") {
                return val;
            } else if (
                val.first_name?.toLowerCase().includes(search?.toLowerCase())
            ) {
                return val;
            } else if (val.role_name?.toLowerCase().includes(search?.toLowerCase())) {
                return val;
            }
        });
        if (data?.length === 0) {
            return (
                <StyledTableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>
                        <Typography>{`No data found for '${search}'`}</Typography>
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                </StyledTableRow>
            );
        } else {
            return data?.map((val, index) => {
                return (
                    <StyledTableRow key={val.name}>
                        {/* <StyledTableCell component="th" scope="row">
                {index + 1 + page}
              </StyledTableCell> */}
                        {val?.first_name ? (
                            <StyledTableCell
                                sx={{ textAlign: `left !important`, paddingLeft: 2 }}
                                component="th"
                                scope="row"
                            >
                                {val?.first_name ?? "-"}
                            </StyledTableCell>
                        ) : <StyledTableCell
                            sx={{ textAlign: `left !important`, paddingLeft: 2 }}
                            component="th"
                            scope="row"
                        >-
                        </StyledTableCell>}

                        {val?.email_id ? (
                            <StyledTableCell
                                sx={{ textAlign: `left !important` }}
                                component="th"
                                scope="row"
                            >
                                {val?.email_id ?? "-"}
                            </StyledTableCell>
                        ) : (<StyledTableCell
                            sx={{ textAlign: `left !important` }}
                            component="th"
                            scope="row"
                        >-</StyledTableCell>)}

                        {val?.role_name && (
                            <StyledTableCell
                                sx={{ textAlign: `left !important`, paddingLeft: 5 }}
                                component="th"
                                scope="row"
                            >
                                {val?.role_name ?? "-"}
                                <IconButton
                                    onClick={() => props?.edit(val)}
                                    variant="outlined"
                                    color="primary"
                                >
                                    <PenIcon sx={{ fontSize: "16px" }} />
                                </IconButton>
                            </StyledTableCell>
                        )}

                        {val?.role_master && (
                            <StyledTableCell
                                component="th"
                                scoope="row"
                                sx={{ textAlign: `left !important` }}
                            >
                                {val?.role_master?.role_name ?? "-"}
                            </StyledTableCell>
                        )}
                        {showStatus && val?.is_active && (
                            <StyledTableCell
                                sx={{ textAlign: `left !important`, paddingLeft: 5 }}
                                component="th"
                                scope="row"
                            >
                                <LensIcon
                                    style={{
                                        fontSize: "0.75rem",
                                        marginRight: 5,
                                        marginTop: 2,
                                        color: val?.is_active ? "#74B1B5" : "red",
                                        whiteSpace: "nowrap",
                                    }}
                                />
                                {val?.is_active ? `Active` : `InActive`}
                            </StyledTableCell>
                        )}
                        {(val?.mobile_no_country_code || val?.mobile) ? (
                            <StyledTableCell component="th" scope="row">
                                {val?.mobile_no_country_code ?? "-"}&nbsp;{val?.mobile ? `- ${val?.mobile}` : "-"}
                            </StyledTableCell>
                        ) : (hideContact && <StyledTableCell component="th" scope="row">-</StyledTableCell>)}
                        {(val?.address1 ||
                            val?.address2 ||
                            val?.city ||
                            val?.state ||
                            val?.country_name ||
                            val?.pin) ? (
                                <StyledTableCell component="th" scope="row" sx={{ width: 250 }}>
                                    {/* {`${val?.address1}, ${val?.address2}, ${val?.city}, ${val?.state}, ${val?.country_name} - ${val?.pin}`} */}
                                    {constructAddress(val)}
                                </StyledTableCell>
                            ) : (<StyledTableCell component="th" scope="row" sx={{ width: 250 }}>-</StyledTableCell>) }

                        <StyledTableCell component="th" scope="row">
                            <Stack direction={`row`} justifyContent="center">
                                {isViewNeeded && (
                                    <IconButton
                                        variant="outlined"
                                        onClick={() => props?.viewVendor(val)}
                                    >
                                        {/* <RemoveRedEyeOutlinedIcon fontSize="small" sx={{ fontSize: "16px" }}/> */}
                                        <EyeIcon sx={{ fontSize: "16px" }} />
                                    </IconButton>
                                )}

                                {showButton && (
                                    <Button
                                        // sx={{ background: theme.palette.button.primary }}
                                        variant="outlined"
                                        size="small"
                                        onClick={() => props?.manageButton(val)}
                                    >
                                        Manage Roles
                                    </Button>
                                )}

                                {isEditNeeded && (
                                    <IconButton
                                        onClick={() => props?.edit(val)}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        <PenIcon sx={{ fontSize: "16px" }} />
                                    </IconButton>
                                )}
                            </Stack>
                        </StyledTableCell>
                    </StyledTableRow>
                );
            });
        }
    };

    return (
        <Paper
            sx={{
                width: "100%",
                overflow: "hidden",
                boxShadow: "0px 12px 53px rgb(29 31 114 / 7%)",
                border: "1px solid #00000012",
            }}
        >
            <TableContainer sx={{ maxHeight: 700 }}>
                <Table stickyHeader aria-label="sticky table" size="medium">
                    <TableHead>
                        <TableRow>
                            {props?.tittles?.map((val) => {
                                return (
                                    <StyledTableCell
                                        sx={{
                                            textAlign: `${val.align} !important`,
                                            backgroundColor: "#8863FB1F",
                                        }}
                                    >
                                        {val.tittle}
                                    </StyledTableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <>
                                {tempstate
                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    ?.filter((val) => {
                                        if (search === "") {
                                            return val;
                                        } else if (
                                            val.first_name
                                                .toLowerCase()
                                                .includes(search.toLowerCase())
                                        ) {
                                            return val;
                                        }
                                    })
                                    .map((val, index) => {
                                        return (
                                            <StyledTableRow key={val.name}>
                                                {props?.tittles.map((val) => {
                                                    return (
                                                        <StyledTableCell component="th" scope="row">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                    );
                                                })}
                                                {/* <StyledTableCell component="th" scope="row">
                            <Skeleton variant="text" />
                          </StyledTableCell>
  
                          <StyledTableCell component="th" scope="row">
                            <Skeleton variant="text" />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Skeleton variant="text" />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Skeleton variant="text" />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Skeleton variant="text" />
                          </StyledTableCell> */}
                                            </StyledTableRow>
                                        );
                                    })}
                            </>
                        ) : (
                            <>{listrender()}</>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
            <div
                style={{
                    padding: "10px 10px",
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Pagination
                    // justifyContent="flex-end"
                    count={Math.round(count / 5)}
                    page={page + 1}
                    color="primary"
                    showFirstButton
                    showLastButton
                    onChange={handleChangePage}
                />
            </div>
        </Paper>
    );
}