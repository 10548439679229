import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";
import PrivateRouter from "./privateRouter";

import {
  NotFound,
  Login,
  ListingParent,
  AddVendor,
  ViewVendorParent,
  MapSchemaParent,
  EditVendorParent,
  AddRuleParent,
  RenameAddRule,
  ApiConfigureParent,
  AddVendorRuleParent,
  VendorRenameRuleParent,
  MarginRuleParent,
  MarginAddRuleParent,
  ExchangeApiConfigureParent,
  Schedule,
  ScheduleView,
  CreateSchedule,
  EditSchedule,
  ErrorLogs,
  UserManagementParent,
  AddUser,
  RoleAccess,
  ParingLogic,
  CreateParingLogic,
  UpdateParingLogic,
} from "./../screens";

// import UserManagement from "../screens/userManagement";
// import { ApiConfigure } from "../screens/apiconfigure/apiconfigure";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Login Route */}
        <Route exact path={Routes.login}>
          <Login />
        </Route>
        {/* Home Route */}
        <PrivateRouter exact path={Routes.home}>
          {/* <Home /> */}
          <ListingParent />
        </PrivateRouter>
        {/* Listing Route */}
        <PrivateRouter exact path={Routes.listing}>
          <ListingParent />
        </PrivateRouter>
        {/* add vendor */}
        <PrivateRouter exact path={Routes.addvendor}>
          <AddVendor />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.apiconfigure}>
          <ApiConfigureParent />
        </PrivateRouter>
        {/* view vendor */}
        <PrivateRouter exact path={Routes.viewvendor}>
          <ViewVendorParent />
        </PrivateRouter>
        {/*map schema */}
        <PrivateRouter exact path={Routes.schema}>
          <MapSchemaParent />
        </PrivateRouter>
        {/*Edit*/}
        <PrivateRouter exact path={Routes.edit}>
          <EditVendorParent />
        </PrivateRouter>
        {/* add rule */}
        <PrivateRouter exact path={Routes.addRule}>
          <AddRuleParent />
        </PrivateRouter>
        {/* rename add rule */}
        <PrivateRouter exact path={Routes.renameAddRule}>
          <RenameAddRule />
        </PrivateRouter>
        {/* margin add rule */}
        <PrivateRouter exact path={Routes.marginRuleParent}>
          <MarginRuleParent />
        </PrivateRouter>
        {/* add vendor rule parent add rule */}
        <PrivateRouter exact path={Routes.addVendorRuleParent}>
          <AddVendorRuleParent />
        </PrivateRouter>
        {/* add rename rule parent add rule */}
        <PrivateRouter exact path={Routes.vendorRenameRuleParent}>
          <VendorRenameRuleParent />
        </PrivateRouter>
        {/* add margin rule parent add rule */}
        <PrivateRouter exact path={Routes.marginAddRuleParent}>
          <MarginAddRuleParent />
        </PrivateRouter>
        {/* exchange configuration*/}
        <PrivateRouter exact path={Routes.exchange}>
          <ExchangeApiConfigureParent />
        </PrivateRouter>
        {/* Schedule List */}
        <PrivateRouter exact path={Routes.schedule}>
          <Schedule />
        </PrivateRouter>
        {/*  Schedule Details */}
        <PrivateRouter exact path={Routes.scheduleView}>
          <ScheduleView />
        </PrivateRouter>
        {/* Create Schedule */}
        <PrivateRouter exact path={Routes.scheduleCreate}>
          <CreateSchedule />
        </PrivateRouter>
        {/* Edit Schedule */}
        <PrivateRouter exact path={Routes.scheduleEdit}>
          <EditSchedule />
        </PrivateRouter>
        {/* Statistics Home */}
        <PrivateRouter exact path={Routes.errorLogs}>
          <ErrorLogs />
        </PrivateRouter>

        {/* User Management */}
        <PrivateRouter exact path={Routes.usermanagement}>
          <UserManagementParent />
        </PrivateRouter>

        {/* Add User */}
        <PrivateRouter exact path={Routes.adduser}>
          <AddUser />
        </PrivateRouter>

        <PrivateRouter exact path={Routes.roleaccess}>
          <RoleAccess />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.paringHome}>
          <ParingLogic />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.paringCreate}>
          <CreateParingLogic />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.paringUpdate}>
          <UpdateParingLogic />
        </PrivateRouter>
        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
