import React from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  Skeleton,
  Pagination,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useHistory } from "react-router-dom";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment-timezone";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import EyeIcon from "../../../../assets/eyeIcon";
import PenIcon from "../../../../assets/penIcon";
// Styles
// style section for cell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8863FB1F",
    color: theme.palette.common.black,
    paddingLeft: 25,
    fontSize: 14,
    // textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    paddingLeft: 25,
    color: "#848484",
    opacity: "100%",
    // textAlign: "center",
  },
}));
// style section for row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "none",
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    fontSize: 13,
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FF4D4A" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export function TabelComponent(props) {
  const { state, loading, page, setPage, count, search, ScheduleEditActive } =
    props;
  const history = useHistory();
  moment.defaultFormat = "DD MMM YY";
  // Handle Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  // Listing Data
  const listrender = () => {
    let data = state;

    if (data?.length === 0) {
      return (
        <StyledTableRow>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell>
            <Typography>{`- found for '${search}'`}</Typography>
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </StyledTableRow>
      );
    } else {
      return data.map((val) => {
        return (
          <StyledTableRow key={val.id}>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              {val?.schedule_name ?? "-"}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              {val?.email_id ?? "-"}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              {val?.vendor?.first_name ?? "-"}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              {(val?.schedule_for === "Fetch" ? " Master" : " Specific") ?? "-"}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              {val?.cron_time ?? "-"}
            </StyledTableCell>

            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked
                    checked={val?.is_active}
                    onChange={(e) =>
                      ScheduleEditActive(
                        val,
                        val?.is_active ? "stopped" : "active"
                      )
                    }
                  />
                }
              />
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `center !important`,
              }}
            >
              <Stack direction={`row`} justifyContent="center">
                <IconButton
                  variant="outlined"
                  onClick={() => history.push(`/schedule/view/${val.id}`)}
                >
                  <EyeIcon sx={{ fontSize: "16px" }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    history.push(`/schedule/edit/${val.id}`);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <PenIcon sx={{ fontSize: "16px" }} />
                </IconButton>
              </Stack>
            </StyledTableCell>
          </StyledTableRow>
        );
      });
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "0px 12px 53px rgb(29 31 114 / 7%)",
        border: "1px solid #00000012",
      }}
    >
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table" size="medium">
          <TableHead>
            <TableRow>
              {props?.titles.map((val) => {
                return (
                  <StyledTableCell
                    sx={{
                      textAlign: `${val.align} !important`,
                    }}
                  >
                    {val.title}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>
                </StyledTableRow>
              </>
            ) : (
              <>{listrender()}</>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 5 && (
        <div
          style={{
            padding: "10px 10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            count={Math.round(count / 5)}
            page={page + 1}
            color="primary"
            showFirstButton
            showLastButton
            onChange={handleChangePage}
          />
        </div>
      )}
    </Paper>
  );
}
