import React from "react";
import { ListItem, TextBox, Select, Subheader } from "../../../components";
import { useStyles } from "./style";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Button,
  Typography,
  Grid,
  Snackbar,
  Divider,
  Stack,
  Pagination,
  Card,
  useTheme,
  IconButton,
} from "@mui/material";
// import { useStyles } from "../addVendorRule/style";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import axios from "axios";
import moment from "moment-timezone";
import { config } from "../../../config";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "../../../assets/deleteIcon";
import { NetworkCall } from "../../../networkcall";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/styles";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FF4D4A" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const listdata = [{ label: "rename", value: "rename" }];
const listdatas = [
  { label: "is equal to", value: "is equal to" },
  { label: "is not equal to", value: "is not equal to" },
  { label: "greater than", value: "greater than" },
  { label: "greater than or equal to", value: "greater than or equal to" },
  { label: "less than", value: "less than" },
  { label: "less then or equal to", value: "less then or equal to" },
  { label: "in", value: "in" },
  { label: "not in", value: "not in" },
  { label: "contains", value: "contains" },
  { label: "between", value: "between" },
];
// var listofoperators = [{ label: "Availability", value: "Availability" }];

let token = localStorage?.getItem("auth_token")?.toString();
token = token?.substring(1, token.length - 1);

const pageSize = 4;

export const VendorRenameRule = (props) => {
  const classes = useStyles(props);

  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const [page, setPage] = React.useState(1);
  const [_index, setindex] = React.useState(null);
  const [listofoperators, setlistofoperators] = React.useState([]);
  const [datafilter, setdatafilter] = React.useState([]);
  const [chooseVerdorIds, setchooseVerdorIds] = React.useState([]);

  const [selectRule, setSelectRule] = React.useState();
  const [ruleOptions, setRuleOptions] = React.useState();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const [search, setSearch] = React.useState(null);

  const [current, setCurrent] = React.useState(1);

  const [state, setstate] = React.useState({
    isopen: false,
    addlist: [],
    obj: {
      fact: "",
      operator: "rename",
      value: [
        {
          key: "",
          value: "",
        },
      ],
    },
    reference: false,
    listData: [],
    vendor_id: props?.vendor_id || location?.state?.ID,
    rule_name: "",
    // chooseVerdorIds: [],
    choosevendor: {},
    error: {},
  });
  const [refObj, setRefObj] = React.useState({
    obj: {
      fact: "",
      operator: "",
      value: [""],
      path: "payload",
    },
    error: {},
  });
  const [dataRefFilter, setDataRefFilter] = React.useState([]);

  const isClearState = async () => {
    await setstate({
      ...state,
      isopen: false,
      addlist: [],
      obj: {
        fact: "",
        operator: "rename",
        value: [
          {
            key: "",
            value: "",
          },
        ],
      },
      vendor_id: "",
      rule_name: "",
      choosevendor: {},
      error: {},
      id: "",
    });
    setindex(null);
  };

  const handleDelete = (index) => {
    state.addlist.splice(_index, 1);
    setstate({ ...state });
  };

  const handleAddChild = (index, i) => {
    state.obj?.value?.push({
      key: "",
      value: "",
    });
    setstate({ ...state });
  };

  const handleClear = () => {
    setstate({
      ...state,
      obj: {
        fact: "",
        operator: "rename",
        value: [
          {
            key: "",
            value: "",
          },
        ],
      },
      error: {},
    });
    setindex(null);
  };
  const handleRefOperatorDelete = (index) => {
    setRefObj({
      ...refObj,
      obj: {
        ...refObj.obj,
        operator: "",
      },
    });
  };
  const handleRefAddChild = (index, i) => {
    refObj.obj?.value?.push("");
    setRefObj({ ...refObj });
  };
  const handleDeleteChild = (index, i) => {
    state.obj?.value?.splice(i, 1);
    setstate({ ...state });
  };
  const handleRefDeleteChild = (index, i) => {
    refObj.obj.value.splice(i, 1);
    setRefObj({ ...refObj });
  };

  const updateStateChild = (key, value, index, i) => {
    state.obj.value[i][key] = value;
    setstate({ ...state });
  };
  const updateRefStateChild = (key, value, index, i) => {
    refObj.obj[key][i] = value;
    setRefObj({ ...refObj });
  };
  const updateState = (key, value) => {
    state.error[key] = false;
    if (key === "operator") {
      state.obj["value"] =
        value === "in" || value === "not in" || value === "between" ? [""] : "";
    }
    if (key === "reference") {
      state[key] = value;
    }
    if (!isNaN(+value) && value) {
      state.obj[key] = JSON.parse(value);
    } else if (typeof value === "object") {
      state.obj[key] = value;
    } else {
      state.obj[key] = value;
    }
    setstate({ ...state });
  };

  const updateRefObj = (key, value) => {
    refObj.error[key] = false;
    if (key === "operator") {
      refObj.obj["value"] =
        value === "in" || value === "not in" || value === "between" ? [""] : "";
    }
    if (key === "reference") {
      refObj[key] = value;
    }
    if (!isNaN(+value) && value) {
      refObj.obj[key] = JSON.parse(value);
    } else if (typeof value === "object") {
      refObj.obj[key] = value;
    } else {
      refObj.obj[key] = value;
    }
    setRefObj({ ...refObj });
  };
  const handleAdd = () => {
    setstate({ ...state });
    // if (!state.obj.operator) {
    //   state.error.operator = true;
    //   return;
    // } else if (!state.obj.fact) {
    //   state.error.fact = true;
    //   return;
    // }
    if (!state.obj.operator) {
      // state.error.operator = true;
      setAlert({
        open: true,
        message: "Please select Operator",
        backgroundColor: "#FF3232",
      });
      return;
    } else if (!state.obj.fact) {
      // state.error.fact = true;
      setAlert({
        open: true,
        message: "Please select Column Name",
        backgroundColor: "#FF3232",
      });
      return;
    } else if (state.obj.value[0]?.key === "") {
      console.log(state.obj);
      // state.error.value = true;
      setAlert({
        open: true,
        message: "Please select Key",
        backgroundColor: "#FF3232",
      });
      return;
    } else if (state.obj.value[0]?.value === "") {
      console.log(state.obj);
      // state.error.value = true;
      setAlert({
        open: true,
        message: "Please select Value",
        backgroundColor: "#FF3232",
      });
      return;
    }
    if (state?.reference) {
      if (!refObj.obj.operator) {
        setAlert({
          open: true,
          message: "Please select Operator",
          backgroundColor: "#FF3232",
        });
        return;
      } else if (!refObj.obj.fact) {
        setAlert({
          open: true,
          message: "Please select Column Name",
          backgroundColor: "#FF3232",
        });
        return;
      } else if (refObj.obj.value.length === 0) {
        setAlert({
          open: true,
          message: "Please select Value",
          backgroundColor: "#FF3232",
        });
        return;
      }
    }
    if (_index !== null && _index >= 0) {
      if (state.reference) {
        state.obj["reference"] = { ...refObj.obj };
      }

      state.addlist[_index] = { ...state.obj };
    } else {
      if (state.reference) {
        state.obj["reference"] = { ...refObj.obj };
      }
      state.addlist.push({ ...state.obj });
    }
    state.obj = {
      fact: "",
      operator: "rename",
      value: [
        {
          key: "",
          value: "",
        },
      ],
    };
    state.reference = false;
    refObj.obj = {
      fact: "",
      operator: "",
      value: [""],
      path: "payload",
    };
    setRefObj({ ...refObj });
    setstate({ ...state });
    setindex(null);
  };

  const handleClose = async () => {
    // setstate({
    //   ...state,
    //   isopen: !state.isopen,
    // });
    // isGetList();
    // await isClearState();
    history.goBack(-1);
  };

  const isGetList = async () => {
    const api_url = config.api_url + "rules/vendor/rename/get/all";

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    new Promise((resolve, reject) => {
      axios
        .get(`${api_url}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          const data = response.data?.rows?.map((v, i) => {
            if (v?.id) {
              return {
                ...v,
                last_modifyed: moment(v?.updated_at).format("YYYY-MM-DD"),
                created_at: moment(v?.created_at).format("YYYY-MM-DD"),
              };
            }
          });
          const vedor_id = data?.map((v) => {
            return {
              value: v?.vendor_id ?? "",
              label: v?.vendor?.first_name ?? "",
            };
          });
          // setstate({
          //   ...state,
          //   listData: data,
          //   chooseVerdorIds: vedor_id ?? [],
          //   isopen: false,
          // });
          setchooseVerdorIds(vedor_id ?? []);
          resolve(true);
        });
    });
  };

  React.useEffect(() => {
    if ((props?.isEdit || props?.vendor_id) && selectRule) {
      const api_url =
        config.api_url +
        `rules/vendor/rename/get?vendor_id=${
          props?.vendor_id || location?.state?.ID
        }&id=${selectRule}`;

      let token = localStorage.getItem("auth_token").toString();
      token = token.substring(1, token.length - 1);

      new Promise((resolve, reject) => {
        axios
          .get(`${api_url}`, { headers: { Authorization: `Bearer ${token}` } })
          .then((response) => {
            const paylod = response?.data?.rename_rules_data?.map((v) => {
              var results = [];
              Object.entries(v.value).map((val) =>
                results.push({
                  ["key"]: val[0],
                  ["value"]: val[1],
                })
              );
              v.value = results;
              return v;
            });
            console.clear();
            console.log(response?.data);
            setstate({
              ...state,
              id: response?.data?.id,
              vendor_id: props?.vendor_id || location?.state?.ID,
              rule_name: response?.data?.rule_name,
              addlist:
                paylod?.length > 0
                  ? paylod
                  : [
                      // {
                      //   fact: "",
                      //   operator: "rename",
                      //   value: [
                      //     {
                      //       key: "",
                      //       value: "",
                      //     },
                      //   ],
                      //   path: "payload",
                      // },
                    ],
            });
            resolve(true);
          })
          .catch((err) => {
            setAlert({
              open: true,
              message: "Something Went Wrong",
              backgroundColor: "#FF3232",
            });
          });
      });
    }
  }, [selectRule]);

  React.useEffect(() => {
    isGetList();
    let id = props?.vendor_id || location?.state?.ID;
    getOperatot(id);
  }, []);

  React.useEffect(() => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    NetworkCall(
      `${config.api_url}rules/get/all?vendor_id=${
        props?.vendor_id || location?.state?.ID
      }`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        console.log(res?.data);
        let rename_rules = res?.data?.rename_rules?.map((x) => {
          return { label: x.rule_name, value: x.id };
        });
        setRuleOptions(rename_rules);
        setSelectRule(rename_rules?.[0].value);
      })
      .catch((err) => {});
  }, [props?.vendor_id]);
  React.useEffect(() => {
    state?.obj?.fact && getValueData();
    // eslint-disable-next-line
  }, [state?.obj?.fact]);
  React.useEffect(() => {
    refObj?.obj?.fact && getValueRefData();
  }, [refObj?.obj?.fact]);
  const getOperatot = async (id) => {
    const api_url = config.api_url + "feed/unqiue/header/get";
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    await axios
      .post(
        `${api_url}`,
        {
          vendor_id: id || "",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response) {
          const data = response?.data?.headers;
          var arr = [];
          data?.map((v) => {
            return arr.push({ value: v, label: v });
          });
        }
        setlistofoperators(arr ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getValueData = async () => {
    let id = state?.vendor_id || state?.choosevendor?.value || "";
    await axios
      .post(
        `${config.api_url + "feed/unqiue/data/get/column"}`,
        {
          vendor_id: id || "",
          column: state?.obj?.fact ?? "",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response) {
          setdatafilter(response?.data?.column_data ?? []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getValueRefData = async () => {
    let id = state?.vendor_id || state?.choosevendor?.value || "";
    await axios
      .post(
        `${config.api_url + "feed/unqiue/data/get/column"}`,
        {
          vendor_id: id || "",
          column: refObj?.obj?.fact ?? "",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response) {
          setDataRefFilter(response?.data?.column_data ?? []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ishandleEdit = (data, isClone) => {
    getOperatot(data?.vendor_id);
    const paylod = data.rename_rules_data?.map((v) => {
      var results = [];
      Object.entries(v.value).map((val) =>
        results.push({
          ["key"]: val[0],
          ["value"]: val[1],
        })
      );
      v.value = results;
      return v;
    });

    state.obj = {
      fact: "",
      operator: "rename",
      value: [
        {
          key: "",
          value: "",
        },
      ],
    };

    setstate({
      ...state,
      isopen: !state.isopen,
      vendor_id: data?.vendor_id,
      rule_name: isClone ? data?.rule_name + "-Copy" : data?.rule_name,
      addlist: paylod,
      id: data?.id ?? "",
    });
  };

  const ishandleDelete = (data, i) => {
    const api_url = config.api_url + "rules/vendor/rename/delete";

    new Promise((resolve, reject) => {
      axios
        .post(
          `${api_url}`,
          {
            rule_id: data?.id ?? "",
            vendor_id: data?.vendor_id ?? "",
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          setAlert({
            open: true,
            message: `Rename has been deleted successfully`,
            backgroundColor: "#4BB543",
          });
          isGetList();
          resolve(true);
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something Went Wrong",
            backgroundColor: "#FF3232",
          });
        });
    });
  };

  const isValidation = () => {
    if (state.vendor_id) {
      if (!state?.rule_name) {
        state.error["rule_name"] = true;
      }
    } else {
      if (!state?.choosevendor?.value) {
        state.error["choosevendor"] = true;
      }
      if (!state?.rule_name) {
        state.error["rule_name"] = true;
      }
    }
    setstate({
      ...state,
    });
  };

  const handleSave = async () => {
    await isValidation();
    if (state?.error?.rule_name || state?.error?.choosevendor) {
      return;
    }
    let isdata = JSON.parse(JSON.stringify(state.addlist));
    const paylod = isdata?.map((v) => {
      var object = v?.value?.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }),
        {}
      );
      v.value = object;
      return v;
    });

    const api_url = config.api_url + "rules/vendor/rename/upsert";

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    let payload = {
      rule_name: state?.rule_name ?? "",
      vendor_id: state?.vendor_id || state?.choosevendor?.value || "",
      rename_rules_data: paylod ?? [],
    };
    if (state?.vendor_id || state?.choosevendor?.value) {
      payload.rule_id = state?.id;
    }

    new Promise((resolve, reject) => {
      axios
        .post(`${api_url}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setAlert({
            open: true,
            message: `Rename rule has been ${
              state?.vendor_id ? "updated" : "added"
            } successfully`,
            backgroundColor: "#4BB543",
          });
          props?.isStepper
            ? props.onSave(state?.vendor_id)
            : history.goBack(-1);
          // if (state?.vendor_id) {
          //   handleClose();
          // }
          // isGetList();
          resolve(true);
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something Went Wrong",
            backgroundColor: "#FF3232",
          });
        });
    });
  };

  const handleChange = (key, v) => {
    state[key] = v;

    if (key === "choosevendor") {
      getOperatot(v?.value ?? "");
    }
    state.error = { ...state.error, choosevendor: false };
    setstate({
      ...state,
    });
  };

  const handleAddNewRule = async () => {
    await isClearState();
    setstate({
      ...state,
      isopen: !state.isopen,
      rule_name: "",
      vendor_id: "",
      choosevendor: {},
      addlist: [],
      obj: {
        fact: "",
        operator: "rename",
        value: [
          {
            key: "",
            value: "",
          },
        ],
      },
      error: {},
    });
  };

  const { addlist, listData, vendor_id, rule_name } = state;

  const isFilterList = search
    ? listData?.filter(
        (v) => v?.rule_name?.toLowerCase()?.includes(search?.toLowerCase()) && v
      )
    : listData;

  const handleEdit = (d, i) => {
    setindex(i);
    state.obj = JSON.parse(JSON.stringify(d));
    state.reference = false;
    if (d?.reference) {
      state.reference = true;

      refObj.obj = JSON.parse(JSON.stringify(d?.reference));
      setRefObj({ ...refObj });
    }
    setstate({ ...state });
  };

  const handleForward = () => {
    console.clear();
    // console.log(ruleOptions)
    // console.log(selectRule)
    let len = ruleOptions.length;
    // let current = ruleOptions.filter((x, index) => x.value === selectRule)
    let current = 0;
    for (let z = 0; z < len; z++) {
      if (ruleOptions[z].value === selectRule) {
        current = z;
      }
    }
    console.log(current);
    if (current + 1 >= len) {
      // console.log(current)
      current = 0;
      console.log(current);
    } else {
      current = current + 1;
      console.log(current);
    }
    setCurrent(current);
    setSelectRule(ruleOptions[current]?.value);
  };

  const handleBackward = () => {
    console.clear();
    // console.log(ruleOptions)
    // console.log(selectRule)
    let len = ruleOptions.length;
    // let current = ruleOptions.filter((x, index) => x.value === selectRule)
    let current = 0;
    for (let z = 0; z < len; z++) {
      if (ruleOptions[z].value === selectRule) {
        current = z;
      }
    }
    console.log(current);
    if (current - 1 == 0) {
      // console.log(current)
      current = 0;
      console.log(current);
    } else if (current - 1 < 0) {
      current = len - 1;
    } else {
      current = current - 1;
      console.log(current);
    }
    // let new_val = (current+1) % len
    // current = new_val
    // console.log(current)
    setCurrent(current);
    setSelectRule(ruleOptions[current]?.value);
  };

  return (
    <>
      {!props.isStepper && (
        <div className={classes.titlesection}>
          <ArrowBackIcon className="icon" onClick={() => history.goBack(-1)} />
          <Typography variant="subtitle2">Edit Rename Rule</Typography>
        </div>
      )}
      <div
        className={classes.root}
        style={{ background: state.isopen && "#fff" }}
      >
        <div className={classes.rootBody}>
          {
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={8}
                style={{ borderRight: "1px solid #eee" }}
              >
                <div className={classes.addruleDrawer}>
                  {/*{!props?.isStepper && (
                  <div className={classes.titleSection}>
                    <Typography variant="subtitle2">
                      {vendor_id ? "Edit" : "Add New"} Rename Rule
                    </Typography>
                  </div>
                )} */}

                  <div className={classes.addruleDrawerBody}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextBox
                          label={"Rule Name"}
                          type={"text"}
                          value={rule_name}
                          isError={state?.error?.rule_name}
                          errorMessage={
                            state?.error?.rule_name &&
                            "Please enter the rule name"
                          }
                          placeholder={"Rule Name"}
                          onChange={(e) =>
                            setstate({
                              ...state,
                              rule_name: e.target.value,
                              error: { ...state.error, rule_name: false },
                            })
                          }
                        />
                      </Grid>

                      {chooseVerdorIds?.filter(
                        (v) => v.value === vendor_id
                      )?.[0]?.label && (
                        <Grid item xs={12}>
                          {vendor_id ? (
                            <TextBox
                              label={`Vendor ${
                                chooseVerdorIds?.filter(
                                  (v) => v.value === vendor_id
                                )?.[0]?.label
                                  ? `Name`
                                  : `Id`
                              }`}
                              type={"text"}
                              value={
                                chooseVerdorIds?.filter(
                                  (v) => v.value === vendor_id
                                )?.[0]?.label || vendor_id
                              }
                              disabled
                              placeholder={"Vendor"}
                            />
                          ) : (
                            <Select
                              disablePortal
                              options={chooseVerdorIds}
                              label="Choose Vendor"
                              placeholder="Choose Vendor"
                              isError={state?.error?.choosevendor}
                              errorMessage={
                                state?.error?.choosevendor &&
                                "Please choose the vendor"
                              }
                              onChange={(e, value) =>
                                handleChange("choosevendor", value)
                              }
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                    <br />
                    Condition :
                    <br /> <br /> <Divider />
                    <div className={classes.addlist}>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        style={{
                          padding: "16px 0px",
                          borderBottom: "1px solid #bdbdbd96",
                        }}
                      >
                        <Grid item xs={6} sm={6}>
                          <Select
                            disablePortal
                            value={{
                              label: state?.obj?.fact ?? "",
                              value: state?.obj?.fact ?? "",
                            }}
                            onChange={(e, value) =>
                              updateState("fact", value?.value, _index)
                            }
                            isError={state?.error?.fact}
                            errorMessage={
                              state?.error?.fact && "Please choose Column"
                            }
                            disabled={
                              !state?.choosevendor?.value && !state?.vendor_id
                            }
                            options={listofoperators}
                            label="Column Name"
                            placeholder="Column Name"
                          />
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <Select
                            value={{
                              label: state?.obj?.operator ?? "",
                              value: state?.obj?.operator ?? "",
                            }}
                            onChange={(e, value) =>
                              updateState("operator", value?.value, _index)
                            }
                            disabled
                            isError={state?.error?.operator}
                            errorMessage={
                              state?.error?.operator && "Please enter Operator"
                            }
                            disablePortal
                            options={listdata}
                            label="Operator"
                            placeholder={"Operator"}
                          />
                        </Grid>

                        {state?.obj?.value?.map((child, i) => {
                          return (
                            <Grid
                              item
                              container
                              spacing={2}
                              xs={5}
                              sm={12}
                              alignItems={"center"}
                            >
                              <Grid item xs={5} sm={4}>
                                <Select
                                  value={{
                                    label: child?.key ?? "",
                                    value: child?.key ?? "",
                                  }}
                                  onChange={(e, value) =>
                                    updateStateChild(
                                      "key",
                                      value?.value,
                                      _index,
                                      i
                                    )
                                  }
                                  disabled={!state?.obj?.fact}
                                  options={
                                    state?.obj?.fact &&
                                    // datafilter?.[state?.obj?.fact]
                                    datafilter
                                      // ? datafilter[state?.obj?.fact]?.map(
                                        ? datafilter.map(
                                          (v) => {
                                            return { value: v, label: v };
                                          }
                                        )
                                      : []
                                  }
                                  label="Key"
                                  placeholder={"key"}
                                />
                              </Grid>
                              <Grid item xs={5} sm={5}>
                                <TextBox
                                  type={"text"}
                                  value={child?.value}
                                  onChange={(e) =>
                                    updateStateChild(
                                      "value",
                                      e.target.value,
                                      _index,
                                      i
                                    )
                                  }
                                  label={"Value"}
                                  placeholder={"Value"}
                                />
                              </Grid>

                              <Grid item xs={2} sm={3}>
                                <div style={{ display: "flex" }}>
                                  <DeleteIcon
                                    style={{
                                      fontSize: "1rem",
                                      color: "#ff4d4a",
                                      cursor: "pointer",
                                      marginTop: 25,
                                    }}
                                    onClick={() => handleDeleteChild(_index, i)}
                                  />

                                  {state?.obj?.value?.length - 1 === i && (
                                    <AddOutlinedIcon
                                      style={{
                                        color: "#231535",
                                        cursor: "pointer",
                                        marginTop: 20,
                                      }}
                                      onClick={() => handleAddChild(_index, i)}
                                    />
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          );
                        })}

                        <Grid item xs={12} sm={12}>
                          <span>Reference : </span>
                          <IOSSwitch
                            sx={{ m: 1 }}
                            defaultChecked
                            checked={state?.reference}
                            onChange={(e) =>
                              updateState("reference", e.target.checked, _index)
                            }
                          />
                        </Grid>

                        {state?.reference && (
                          <>
                            <Grid item xs={6} sm={4}>
                              <Select
                                disablePortal
                                value={{
                                  label: refObj?.obj?.fact ?? "",
                                  value: refObj?.obj?.fact ?? "",
                                }}
                                onChange={(e, value) =>
                                  updateRefObj("fact", value?.value, _index)
                                }
                                isError={refObj?.error?.fact}
                                errorMessage={
                                  refObj?.error?.fact && "Please choose Column"
                                }
                                disabled={
                                  !state?.choosevendor?.value &&
                                  !state?.vendor_id
                                }
                                options={listofoperators}
                                label="Column Name"
                                placeholder="Column Name"
                              />
                            </Grid>

                            <Grid item xs={6} sm={4}>
                              <Select
                                value={{
                                  label: refObj?.obj?.operator ?? "",
                                  value: refObj?.obj?.operator ?? "",
                                }}
                                onChange={(e, value) =>
                                  updateRefObj("operator", value?.value, _index)
                                }
                                isError={refObj?.error?.operator}
                                errorMessage={
                                  refObj?.error?.operator &&
                                  "Please enter Operator"
                                }
                                disablePortal
                                options={listdatas}
                                label="Operator"
                                placeholder={"Operator"}
                              />
                            </Grid>

                            {(refObj?.obj?.operator === "in" ||
                              refObj?.obj?.operator === "not in") && (
                              <Grid item xs={1} sm={1}>
                                <div
                                  style={{
                                    border: "1px solid red",
                                    padding: "6px 3px 0px",
                                    borderRadius: "4px",
                                    marginTop: "23px",
                                    width: "fit-content",
                                  }}
                                >
                                  <DeleteIcon
                                    style={{
                                      color: "#ff4d4a",
                                      cursor: "pointer",
                                      fontSize: "1rem",
                                    }}
                                    onClick={() =>
                                      handleRefOperatorDelete(_index)
                                    }
                                  />
                                </div>
                              </Grid>
                            )}

                            {(refObj?.obj?.operator === "in" ||
                              refObj?.obj?.operator === "not in" ||
                              refObj?.obj?.operator === "between") &&
                              refObj?.obj?.value &&
                              refObj?.obj?.value?.map((child, i) => {
                                return (
                                  <Grid
                                    item
                                    container
                                    spacing={2}
                                    xs={5}
                                    sm={12}
                                    alignItems={"center"}
                                  >
                                    <Grid item xs={5} sm={4}>
                                      {refObj?.obj?.operator === "between" ? (
                                        <TextBox
                                          type={"text"}
                                          value={child ?? ""}
                                          isError={refObj?.error?.value}
                                          errorMessage={
                                            refObj?.error?.value &&
                                            "Please select Value"
                                          }
                                          label="Value"
                                          placeholder={"Value"}
                                          disabled={!refObj?.obj?.fact}
                                          onChange={(e) =>
                                            updateRefStateChild(
                                              "value",
                                              e.target.value,
                                              _index,
                                              i
                                            )
                                          }
                                        />
                                      ) : (
                                        <Select
                                          value={{
                                            label: child ?? "",
                                            value: child ?? "",
                                          }}
                                          onChange={(e, value) =>
                                            updateRefStateChild(
                                              "value",
                                              value?.value,
                                              _index,
                                              i
                                            )
                                          }
                                          disabled={!refObj?.obj?.fact}
                                          options={
                                            dataRefFilter
                                              ? dataRefFilter?.map((v) => {
                                                  return { value: v, label: v };
                                                })
                                              : []
                                          }
                                          label="Value"
                                          placeholder={"Value"}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={2} sm={3}>
                                      <div style={{ display: "flex" }}>
                                        <DeleteIcon
                                          style={{
                                            color: "#ff4d4a",
                                            cursor: "pointer",
                                            marginTop: 22,
                                            fontSize: 17,
                                          }}
                                          onClick={() =>
                                            handleRefDeleteChild(_index, i)
                                          }
                                        />
                                        {refObj?.obj?.value?.length - 1 ===
                                          i && (
                                          <AddOutlinedIcon
                                            style={{
                                              color: "#231535",
                                              cursor: "pointer",
                                              marginTop: 20,
                                            }}
                                            onClick={() =>
                                              handleRefAddChild(_index, i)
                                            }
                                          />
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                );
                              })}

                            {(refObj?.obj?.operator !== "in" ||
                              refObj?.obj?.operator !== "not in" ||
                              refObj?.obj?.operator === "between") && (
                              <Grid item xs={5} sm={3}>
                                {refObj?.obj?.operator === "in" ||
                                refObj?.obj?.operator === "not in" ||
                                refObj?.obj?.operator === "between" ? (
                                  <></>
                                ) : (
                                  <Select
                                    value={{
                                      label: refObj?.obj?.value ?? "",
                                      value: refObj?.obj?.value ?? "",
                                    }}
                                    onChange={(e, value) =>
                                      updateRefObj(
                                        "value",
                                        value?.value,
                                        _index
                                      )
                                    }
                                    disabled={!refObj?.obj?.fact}
                                    options={
                                      refObj?.obj?.fact && dataRefFilter
                                        ? dataRefFilter?.map((v) => {
                                            return { value: v, label: v };
                                          })
                                        : []
                                    }
                                    label="Value"
                                    placeholder={"Value"}
                                  />
                                )}
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                      <br />
                      <Grid container xs={12} justifyContent={"center"}>
                        <Button variant="contained" onClick={() => handleAdd()}>
                          {_index ? "UPDATE" : "ADD"}
                        </Button>
                        &nbsp;
                        {_index !== null && _index >= 0 ? (
                          <Button onClick={() => handleClear()}>CANCEL</Button>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </div>
                    {/* <div className={classes.footer}>
                      &nbsp;
                      <Button
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={() =>
                          props?.isStepper
                            ? props?.onSave(vendor_id)
                            : handleClose()
                        }
                      >
                        {props?.isStepper ? `Skip` : `Cancel`}
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        variant="contained"
                        sx={{ background: theme.palette.button.primary }}
                        onClick={() => handleSave()}
                      >
                        {!props?.isStepper ? "Update" : "Save & Continue"}
                      </Button>
                    </div> */}
                  </div>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                style={{ borderRight: "1px solid #eee" }}
              >
                <div
                  style={{ background: "#fff" }}
                  className={classes.addruleDrawer}
                >
                  {addlist?.length > 0 && (
                    <div
                      className={classes.titleSection}
                      style={{ borderBottom: "1px solid #ced3dd" }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: 1 }}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{ fontSize: 16 }}
                        >
                          &nbsp;
                          {`${state.rule_name} (${
                            ruleOptions?.length != 0
                              ? `${current == 0 ? current + 1 : current} / ${
                                  ruleOptions?.length
                                }`
                              : `0`
                          })`}
                        </Typography>
                        {!props.isStepper && (
                          <div>
                            <IconButton onClick={() => handleBackward()}>
                              <ArrowLeftOutlinedIcon />{" "}
                            </IconButton>{" "}
                            <IconButton onClick={() => handleForward()}>
                              <ArrowRightOutlinedIcon />
                            </IconButton>
                          </div>
                        )}
                      </Stack>
                      {/* <Select
                        value={selectRule}
                        onChange={(e, value) =>{
                          setSelectRule(value?.value)
                        }}
                        // disabled={!state?.obj?.fact}
                        options={ ruleOptions}
                        label="Vendor Rules"
                        placeholder={"Value"}
                      /> */}
                    </div>
                  )}

                  {addlist?.length > 0 && (
                    <Typography
                      style={{
                        margin: "0px 16px 0px",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      Condition :
                    </Typography>
                  )}

                  {!addlist?.length > 0 && (
                    <div
                      style={{
                        height: "395px",
                        margin: "auto",
                        display: "flex",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        <img src="../images/box@2x.png" />
                        <br />
                        No Conditions Added
                      </Typography>
                    </div>
                  )}

                  {addlist?.length > 0 && (
                    <div className={classes.addruleDrawerBody1}>
                      {addlist?.map((_item, _index) => {
                        return (
                          <div
                            className={classes.addruleDrawer}
                            style={{ marginBottom: 6 }}
                          >
                            <div style={{ height: "auto" }}>
                              <Card className={classes.card}>
                                <div className={classes.count}>
                                  {_index + 1}
                                </div>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <Typography className="title">
                                      Column Name{" "}
                                      <div className="body">
                                        {_item?.fact ?? ""}
                                      </div>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography className="title">
                                      Operator{" "}
                                      <div className="body">
                                        {_item?.operator ?? ""}
                                      </div>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <div
                                      style={{
                                        display: "flex",
                                        margin: "4px -8px",
                                      }}
                                    >
                                      <EditOutlinedIcon
                                        style={{
                                          color: "#8863FB",
                                          fontSize: 21,
                                        }}
                                        onClick={() =>
                                          handleEdit(_item, _index)
                                        }
                                      />
                                      <DeleteIcon
                                        style={{
                                          color: "#FF4D4A",
                                          fontSize: 17,
                                        }}
                                        onClick={() => handleDelete(_index)}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography
                                      className="title"
                                      style={{ marginTop: 6 }}
                                    >
                                      Key
                                      <div className="body">
                                        {_item?.value &&
                                          _item?.value?.map((child, i) => {
                                            return i ===
                                              _item?.value?.length - 1
                                              ? child?.key
                                              : child?.key + ", ";
                                          })}
                                      </div>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography
                                      className="title"
                                      style={{ marginTop: 6 }}
                                    >
                                      Value{" "}
                                      <div className="body">
                                        {_item?.value &&
                                          _item?.value?.map((child, i) => {
                                            return i ===
                                              _item?.value?.length - 1
                                              ? child?.value
                                              : child?.value + ", ";
                                          })}
                                      </div>
                                    </Typography>
                                  </Grid>

                                  {_item?.reference?.operator &&
                                    _item?.reference?.operator !== "" && (
                                      <Grid item xs={6}>
                                        <Typography
                                          className="title"
                                          style={{ marginTop: 6 }}
                                        >
                                          Reference Operator
                                          <div className="body">
                                            {_item?.reference?.operator ?? ""}
                                          </div>
                                        </Typography>
                                      </Grid>
                                    )}
                                  {_item?.reference?.operator &&
                                    _item?.reference?.operator !== "" && (
                                      <Grid item xs={6}>
                                        <Typography
                                          className="title"
                                          style={{ marginTop: 6 }}
                                        >
                                          Reference Value
                                          <div className="body">
                                            {_item?.reference?.operator ===
                                              "in" ||
                                            _item?.reference?.operator ===
                                              "not in" ||
                                            Array.isArray(
                                              _item?.reference?.value
                                            ) ? (
                                              <>
                                                {_item?.reference?.value &&
                                                  _item?.reference?.value?.map(
                                                    (child, i) => {
                                                      return i ===
                                                        _item?.reference?.value
                                                          ?.length -
                                                          1
                                                        ? child
                                                        : child + ", ";
                                                    }
                                                  )}
                                              </>
                                            ) : _item?.reference?.operator ===
                                              "between" ? (
                                              <>{`${
                                                _item?.reference?.value?.from ??
                                                ""
                                              }, ${
                                                _item?.reference?.value?.to ??
                                                ""
                                              }`}</>
                                            ) : _item?.reference?.operator ===
                                              "same value" ? (
                                              <>{"same value"}</>
                                            ) : (
                                              <>
                                                {_item?.reference?.value ?? ""}
                                              </>
                                            )}
                                          </div>
                                        </Typography>
                                      </Grid>
                                    )}
                                </Grid>
                              </Card>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </Grid>
              <div className={classes.footer}>
                      &nbsp;
                      <Button
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={() =>
                          props?.isStepper
                            ? props?.onSave(vendor_id)
                            : handleClose()
                        }
                      >
                        {props?.isStepper ? `Skip` : `Cancel`}
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        variant="contained"
                        sx={{ background: theme.palette.button.primary }}
                        onClick={() => handleSave()}
                      >
                        {!props?.isStepper ? "Update" : "Save & Continue"}
                      </Button>
                    </div>
            </Grid>
          }

          <Snackbar
            open={alert.open}
            message={alert.message}
            ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setAlert({ ...alert, open: false })}
            autoHideDuration={4000}
          />
        </div>
      </div>
    </>
  );
};
