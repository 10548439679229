import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import propTypes from "prop-types";

const useStyles = makeStyles({
  label: {
    color: "#848484",
    fontWeight: 500,
    fontSize: "13px",
  },
  value: {
    color: "#231535",
    fontWeight: 500,
    fontSize: "13px",
  },
});

function LabelValueInfo({ label, value }) {
  const classes = useStyles();
  return (
    <Stack rowGap={1.625}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Stack>
  );
}

LabelValueInfo.defaultProps = {
  label: "",
  value: "",
};

LabelValueInfo.propTypes = {
  label: propTypes.string,
  value: propTypes.string,
};

export default LabelValueInfo;
