import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import MuiPaper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles, styled } from "@mui/styles";
import { VendorItem, VendorRow } from "./components";
import { useHistory, useLocation } from "react-router-dom";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import moment from "moment-timezone";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Snackbar } from "@mui/material";
import addressIcon from "../../assets/icons/address.svg";
import calendarIcon from "../../assets/icons/calendar.svg";
import mailIcon from "../../assets/icons/mail.svg";
import personIcon from "../../assets/icons/person.svg";
import phoneIcon from "../../assets/icons/phone.svg";
import { formatAddress } from "../../utils";
import RunProcess from "./runProcess";

const Paper = styled(MuiPaper, {
  shouldForwardProp: (prop) => prop !== "moreGutter",
})(({ moreGutter }) => ({
  height: "100%",
  border: "1px solid #DCDCDC",
  borderRadius: 10,
  overflow: "auto",
  padding: "32px 24px",
  ...(moreGutter && {
    padding: "32px",
  }),
}));

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "20px 16px 0px",
  },
  headerText: {
    fontSize: 20,
    fontWeight: 700,
  },
  editDetailButton: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1,
    textTransform: "unset",
  },
  main: {
    marginTop: 24,
    height: "80%",
  },
  divider: {
    borderColor: "#84848429",
    marginTop: 18,
    marginBottom: 16,
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      display: "flex",
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: "-10px",
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
      marginTop: "-2px",
    },
  },
  cardStyle: {
    background: "#F2F3F9",
    padding: "16px",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardLabel: {
    fontSize: "14px",
    color: "#231535",
  },
  cardText: {
    color: "#8863FB",
    fontSize: "18px",
  },
}));

function VendorDetail() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const vendor_id =
    location.state?.vendor_id || "33812502-b1b5-49b8-ba01-7ea67f5e8186";

  useEffect(() => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    setLoading(true);
    NetworkCall(
      `${config.api_url}vendors/get`,
      "POST",
      { vendor_id: vendor_id },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        console.log(res.data?.data);
        setData(res.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    NetworkCall(
      `${config.api_url}files/get/stats?vendor_id=${vendor_id}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        setStats(res.data?.data?.rows[0] ?? null);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const onEditVendorDetailsClick = () => {
    history.push({
      pathname: "/edit",
      state: {
        vendor_id: location?.state?.ID ?? "",
      },
    });
  };

  const onViewVendorDetailsClick = (data) => {
    if (data === "vendor") {
      history.push({
        pathname: "/addvendorrule",
        state: {
          ID: location?.state?.ID ?? "",
          Location: "/viewvendor",
          IndexMenu: 1,
        },
      });
    } else if (data === "margin") {
      history.push({
        pathname: "/addmarginrule",
        state: {
          ID: location?.state?.ID ?? "",
          Location: "/viewvendor",
          IndexMenu: 1,
        },
      });
    } else if (data === "apiconfigure") {
      history.push({
        pathname: "/apiconfigure",
        state: {
          vendor_id: location?.state?.ID ?? "",
          Location: "/viewvendor",
          IndexMenu: 1,
        },
      });
    } else if (data === "dbschema") {
      history.push({
        pathname: "/schema",
        state: {
          vendor_id: location?.state?.ID ?? "",
          Location: "/viewvendor",
          IndexMenu: 1,
        },
      });
    } else {
      history.push({
        pathname: "/addrenamerule",
        state: {
          ID: location?.state?.ID ?? "",
          Location: "/viewvendor",
          IndexMenu: 1,
        },
      });
    }
  };

  const updateMessage = (value, color) => {
    setAlert({
      open: true,
      message: `${value}`,
      backgroundColor: `${color}`,
    });
  };
  const DownloadUrl = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = url.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <span>
          <Typography variant="subtitle2">
            <ArrowBackIcon
              className="icon"
              onClick={() => history.goBack(-1)}
            />
            Vendors Details
          </Typography>
        </span>
        <div style={{display:"flex",  gap:20}}>
          <Button
            color="primary"
            disableRipple
            variant="contained"
            className={classes.editDetailButton}
            onClick={() => setOpen(true)}
          >
            Run Process
          </Button>
          <Button
            className={classes.editDetailButton}
            color="primary"
            disableRipple
            onClick={onEditVendorDetailsClick}
          >
            Edit Details
          </Button>
        </div>
      </div>
      <Grid
        container
        xs={12}
        columnGap={1.5}
        wrap="nowrap"
        className={classes.main}
      >
        <Grid item xs={12} lg={8}>
          <Paper elevation={0} moreGutter>
            <Stack justifyContent="space-between">
              <Grid container rowGap={5}>
                <Grid item xs={4}>
                  <VendorItem
                    loading={loading}
                    icon={personIcon}
                    label={"Vendor Name"}
                    value={data?.first_name}
                  />
                </Grid>

                <Grid item xs={4}>
                  <VendorItem
                    loading={loading}
                    icon={mailIcon}
                    label={"Email Address"}
                    value={data?.email_id}
                  />
                </Grid>
                <Grid item xs={4}>
                  <VendorItem
                    loading={loading}
                    icon={phoneIcon}
                    label={"Contact Number"}
                    value={
                      data?.mobile && data?.mobile_no_country_code
                        ? `${data?.mobile_no_country_code}-${data?.mobile}`
                        : `-`
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <VendorItem
                    loading={loading}
                    icon={personIcon}
                    label={"Status"}
                    value={data?.is_active ? `Active` : `Inactive`}
                  />
                </Grid>
                <Grid item xs={4}>
                  <VendorItem
                    loading={loading}
                    icon={calendarIcon}
                    label={"Created Date"}
                    value={moment(data?.created_at)
                      .tz(moment.tz.guess())
                      .format("DD/MM/YYYY")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <VendorItem
                    loading={loading}
                    icon={calendarIcon}
                    label={"Last Updated"}
                    value={moment(data?.updated_at)
                      .tz(moment.tz.guess())
                      .format("DD/MM/YYYY")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <VendorItem
                    loading={loading}
                    icon={addressIcon}
                    label={"Address"}
                    value={formatAddress(data)}
                  />
                </Grid>
              </Grid>
              <Divider style={{ margin: "30px 0px" }} />
              <Grid container xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography style={{ fontSize: "18px", color: "#000" }}>
                    Statistics
                  </Typography>
                  {stats?.exported_file_url && (
                    <Button
                      variant="contained"
                      className={classes.editDetailButton}
                      onClick={() => DownloadUrl(stats?.exported_file_url)}
                    >
                      Download
                    </Button>
                  )}
                </Grid>

                <Grid
                  container
                  xs={12}
                  style={{ paddingTop: "30px" }}
                  columnSpacing={3}
                  rowSpacing={3}
                >
                  <Grid item xs={4}>
                    <Grid className={classes.cardStyle}>
                      <Typography className={classes.cardLabel}>
                        Total
                        <br />
                        Diamond Count
                      </Typography>

                      <Typography className={classes.cardText}>
                        {stats?.total_count ?? "0"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid className={classes.cardStyle}>
                      <Typography className={classes.cardLabel}>
                        Diamond
                        <br />
                        Active
                      </Typography>

                      <Typography className={classes.cardText}>
                        {stats?.total_active ?? "0"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid className={classes.cardStyle}>
                      <Typography className={classes.cardLabel}>
                        Diamond
                        <br />
                        Inactive
                      </Typography>

                      <Typography className={classes.cardText}>
                        {stats?.total_inactive ?? "0"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid className={classes.cardStyle}>
                      <Typography className={classes.cardLabel}>
                        Diamond
                        <br />
                        Sold
                      </Typography>

                      <Typography className={classes.cardText}>
                        {stats?.total_sold ?? "0"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Paper elevation={0}>
            <Stack>
              <VendorRow
                loading={loading}
                label={"Feed Details"}
                onViewClick={() => onViewVendorDetailsClick("apiconfigure")}
              />
              <Divider className={classes.divider} flexItem />
              <VendorRow
                loading={loading}
                label={"Map DB Schema"}
                onViewClick={() => onViewVendorDetailsClick("dbschema")}
              />
              <Divider className={classes.divider} flexItem />
              <VendorRow
                loading={loading}
                label={"Filter Rules"}
                onViewClick={() => onViewVendorDetailsClick("vendor")}
              />
              <Divider className={classes.divider} flexItem />
              <VendorRow
                loading={loading}
                label={"Rename Rules"}
                onViewClick={() => onViewVendorDetailsClick("rename")}
              />
              <Divider className={classes.divider} flexItem />
              <VendorRow
                loading={loading}
                label={"Margin Rules"}
                onViewClick={() => onViewVendorDetailsClick("margin")}
              />
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <RunProcess
        open={open}
        vendor_id={data?.id}
        vendor_name={data?.first_name}
        handleClose={() => setOpen(false)}
        updateMessage={(value, color) => updateMessage(value, color)}
      />
      <Snackbar
        open={alert.open}
        message={alert.message}
        ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={4000}
      />
    </div>
  );
}

export default VendorDetail;
