import React from "react";
import { withNavBars } from "./../../HOCs";
import { ScheduleHome } from "./scheduleHome";
class Schedule extends React.Component {
  render() {
    return <ScheduleHome />;
  }
}

export default withNavBars(Schedule);
