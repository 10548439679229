import * as React from "react"

const MappedArrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12.8} height={6.4} {...props}>
    <path
      data-name="icons8-link (1)"
      d="M3.2 0a3.2 3.2 0 0 0 0 6.4h1.309a.436.436 0 1 0 0-.873H3.2a2.327 2.327 0 1 1 0-4.654h1.309a.436.436 0 1 0 0-.873Zm5.091 0a.436.436 0 1 0 0 .873H9.6a2.327 2.327 0 1 1 0 4.654H8.291a.436.436 0 1 0 0 .873H9.6a3.2 3.2 0 1 0 0-6.4ZM2.764 2.763a.436.436 0 1 0 0 .873h7.273a.436.436 0 1 0 0-.873Z"
      fill="#9d9fa4"
    />
  </svg>
)

export default MappedArrow