import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Snackbar,
  IconButton,
  Stack,
  useTheme,
  FormLabel,
  OutlinedInput,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { TextBox, MobileNumberInputComponent } from "../../components";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";
import { ListContext } from "../../contexts/listcontext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { propsToClassKey } from "@mui/styles";
import { AlertContext } from "../../contexts";
import { KeyboardBackspaceOutlined } from "@mui/icons-material";
import { styled } from "@mui/styles";
import { currencyValue } from "../../utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    margin: "0px 100px 50px",
    backgroundColor: theme.palette.common.white,
    // padding:"20px"
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: 30,
    },
    position: "relative",
  },
  tittle: {
    fontWeight: 600,
    fontSize: "20px",
    marginBottom: "10px",
  },
  sumbit: {
    textTransform: "capitalize",
  },
  btnmain: {
    backgroundColor: theme.palette.common.white,
    marginTop: "50px",
    marginBottom: 20,
    // display: "flex",
  },
  filessection: {
    display: "grid",
    gridTemplateColumns: "88% 12%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
    },
  },
  btnMin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "left",
    },
  },
  paddedSpace: {
    // padding: "10px 50px",
    // [theme.breakpoints.down("sm")]: {
    //   padding: 0
    // },
  },
  footer: {
    borderTop: "1px solid #eeeeee",
    bottom: 96,
    right: 0,
    display: "flex",

    justifyContent: "flex-end",

    overflow: "hidden",
    padding: 10,
    background: "#fff",
    // position: "absolute",
    width: "96%",
    left: "24px",
    borderRadius:6,
  },
  cancelButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    borderColor: "#84848466 !important",
    "& :hover": {
      color: theme.palette.secondary.main,
      borderColor: "#84848466 !important",
    },
  },
  titlesection: {
    zIndex: 10000000,
    display: "flex",
    alignItems: "center",
    margin: "20px 20px 14px 20px",
    // align-items: center;
    justifyContent: "left",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: 16,

    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
    },
  },
}));

const StyledLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "12px",
  // color: "#231535",
  marginBottom: "0.35em",
  "& .css-u0qe18-MuiFormLabel-root.Mui-focused": {
    color: "#00000099 !important",
  },
}));
export const Styledselect = styled(Select)(({ theme }) => ({
  // fontSize: "12px",
  // color:"#231535",
  // marginBottom:"0.35em",
  label: {
    color: theme.palette.text.secondary,
  },
  borderRadius: "10px",
  "& .MuiOutlinedInput-input": {
    padding: "7px 14px !important",
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  // [`& fieldset`]: {
  //   "& :hover": {
  //     border: "0px solid #5078E1",
  //   },
  // },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CED3DD !important",
  },
}));

const initstate = {
  first_name: "",
  mobile: "",
  mobile_no_country_code: "+91",
  email_id: "",
  description: "",
  address1: "",
  // address2: "",
  city: "",
  state: "",
  country_name: "",
  currency_code: "",
  pin: "",
};

export const AddVendor = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  let editData = props?.editData;
  const { list, updateState } = useContext(ListContext);
  const snack = useContext(AlertContext);

  const [data, setData] = useState(initstate);

  const [nvalue, setNValue] = useState({});

  const vendor_id = props?.isEdit
    ? props.vendor_id ||
      location.state.vendor_id ||
      "7f4bc3cc-4287-4640-b672-ca020ae3d413"
    : props.vendor_id
    ? props.vendor_id
    : "";

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const validation = () => {
    if (
      data?.first_name === "" ||
      data?.mobile === "" ||
      data?.email_id === "" ||
      data?.address1 === "" ||
      // data?.address2 === "" ||
      data?.city === "" ||
      data?.state === "" ||
      data?.country_name === "" ||
      data?.currency_code === "" ||
      data?.pin === "" ||
      data?.first_name === undefined ||
      data?.mobile === undefined ||
      data?.email_id === undefined ||
      data?.address1 === undefined ||
      // data?.address2 === undefined ||
      data?.city === undefined ||
      data?.state === undefined ||
      data?.country_name === undefined ||
      data?.currency_code === undefined ||
      data?.pin === undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    console.log(vendor_id);
    if (props.isEdit || vendor_id) {
      let token = localStorage.getItem("auth_token").toString();
      token = token.substring(1, token.length - 1);
      NetworkCall(
        `${config.api_url}vendors/get`,
        "POST",
        { vendor_id: vendor_id },
        {
          // auth_token: "auth_token",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      )
        .then((res) => {
          console.log(res.data?.data);
          setNValue(res.data?.data);
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    if (nvalue !== {}) {
      let temp_data = {
        first_name: nvalue?.first_name,
        is_active: nvalue?.is_active,
        mobile: nvalue?.mobile,
        mobile_no_country_code: nvalue?.mobile_no_country_code,
        email_id: nvalue?.email_id,
        country_name: nvalue?.country_name,
        currency_code: nvalue?.currency_code,
        description: nvalue?.description,
        address1: nvalue?.address1,
        // address2: nvalue?.address2,
        city: nvalue?.city,
        state: nvalue?.state,
        pin: nvalue?.pin,
      };
      setData(temp_data);
    }
    // if (editData !=)
  }, [nvalue]);

  const Continue = () => {
    // props.onSave("1", {name: "data"})

    // setTimeout(() => {props.onSave("1")}, 5000);
    if (validation()) {
      console.log("Validation Passed");
      let payload = {
        ...data,
        mobile: data?.mobile,
        mobile_no_country_code: data?.mobile_no_country_code
          ? data?.mobile_no_country_code
          : "+91",
      };

      if (props.isEdit || props.vendor_id) {
        payload.vendor_id = vendor_id;
      }
      let token = localStorage.getItem("auth_token").toString();
      token = token.substring(1, token.length - 1);
      NetworkCall(
        props.isEdit || props.vendor_id
          ? `${config.api_url}vendors/update`
          : `${config.api_url}vendors/new`,
        "POST",
        { ...payload },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      )
        .then((res) => {
          setAlert({
            open: true,
            message: `Vendor ${
              props.isEdit || props.vendor_id ? `updated` : `created`
            }  successfully`,
            backgroundColor: "#4BB543",
          });

          setTimeout(() => {
            props.isEdit
              ? history.goBack(-1)
              : props.onSave(res?.data?.vendor_id || vendor_id, payload);
          }, 3000);

          // history.push("/apiconfigure", { vendor_id: res?.data?.vendor_id });
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something went wrong. Please try again later.",
            backgroundColor: "#FF3232",
          });
        });
    } else {
      setAlert({
        open: true,
        message: "Please fill the Mandatory field",
        backgroundColor: "#FF3232",
      });
    }
  };

  const setValue = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const back = () => {
    history.goBack(-1);
  };

  return (
    <>
      {!props.isStepper && (
        <div className={classes.titlesection}>
          <ArrowBackIcon className="icon" onClick={() => back()} />
          <Typography variant="subtitle2">Edit Vendor Details</Typography>
        </div>
      )}
      <div
        className={classes.root}
        style={{
          margin: !props?.isStepper ? " 0px 30px" : "0px 100px 50px",
          padding: !props?.isStepper ? "20px 50px 50px" : 0,
          border: !props?.isStepper ? "0.2px solid #DCDCDC" : "none",
          borderRadius: !props?.isStepper ? 8 : 0,
          overflow: "auto",
          height: !props?.isStepper ? "70vh" : "auto",
        }}
      >
        {/* {!props.isStepper ? (
          <Typography className={classes.tittle}>
            <IconButton onClick={back}>
              <KeyboardBackspaceOutlined />
            </IconButton>{" "}
            Edit New Vendor
          </Typography>
        ) : (
          <></>
        )} */}
        <div className={classes.paddedSpace}>
          <Grid container spacing={15} gap={0}>
            <Grid item md={6} xs={12}>
              <Stack gap={3}>
                <TextBox
                  label={"Vendor Name"}
                  type={"text"}
                  isRequired
                  placeholder={"Vendor Name"}
                  value={data?.["first_name"]}
                  onChange={(e) => setValue("first_name", e.target.value)}
                />
                <MobileNumberInputComponent
                  label="Contact Number "
                  isRequired
                  value={{
                    mobile: data?.mobile,
                    mobile_code: data?.mobile_no_country_code,
                  }}
                  handleChange={(value) => setValue("mobile", value.mobile)}
                  handleDropDown={(value) =>
                    setValue("mobile_no_country_code", value.mobile_code)
                  }
                />
                <TextBox
                  label={"Email Address"}
                  type={"email"}
                  isRequired
                  placeholder={"Email Address"}
                  value={data?.["email_id"]}
                  onChange={(e) => setValue("email_id", e.target.value)}
                />
                <TextBox
                  label={"Description"}
                  type={"text"}
                  isRequired={false}
                  rows={3.6}
                  placeholder={"Description"}
                  multiline
                  value={data?.["description"]}
                  onChange={(e) => setValue("description", e.target.value)}
                />
                <FormControl fullWidth>
                  <StyledLabel>
                    Currency code
                    <sup style={{ color: theme.palette.error.main }}>*</sup>
                  </StyledLabel>
                  <Styledselect
                    sx={{ padding: "0px !important" }}
                    // labelId="demo-simple-select-label"
                    placeholder={`Network Call Methods`}
                    value={data?.["currency_code"]}
                    required={true}
                    isRequired
                    label={`Network Call Methods`}
                    input={<OutlinedInput sx={{ padding: "0px !important" }} />}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(e) => setValue("currency_code", e.target.value)}
                  >
                    {currencyValue?.map((x) => {
                      return <MenuItem value={x.value}>{x.label}</MenuItem>;
                    })}
                  </Styledselect>
                </FormControl>
              </Stack>
            </Grid>
            {/* <Grid item md={1} xs={12}></Grid> */}
            <Grid item md={6} xs={12}>
              <Stack gap={3}>
                <TextBox
                  label={"Address Line 1"}
                  type={"text"}
                  isRequired
                  placeholder={"Address Line 1"}
                  value={data?.["address1"]}
                  onChange={(e) => setValue("address1", e.target.value)}
                />

                {/* <TextBox
                  label={"Address Line 2"}
                  type={"text"}
                  isRequired
                  placeholder={"Address Line 2"}
                  value={data?.["address2"]}
                  onChange={(e) => setValue("address2", e.target.value)}
                /> */}

                <TextBox
                  label={"City"}
                  type={"text"}
                  isRequired
                  placeholder={"City"}
                  value={data?.["city"]}
                  onChange={(e) => setValue("city", e.target.value)}
                />
                <TextBox
                  label={"State"}
                  type={"text"}
                  isRequired
                  placeholder={"State"}
                  value={data?.["state"]}
                  onChange={(e) => setValue("state", e.target.value)}
                />
                <TextBox
                  label={"Country"}
                  type={"text"}
                  placeholder={"Country"}
                  isRequired
                  value={data?.["country_name"]}
                  onChange={(e) => setValue("country_name", e.target.value)}
                />

                <TextBox
                  label={"Pin Code"}
                  type={"text"}
                  isRequired
                  placeholder={"PinCode"}
                  value={data?.["pin"]}
                  onChange={(e) => setValue("pin", e.target.value)}
                />
              </Stack>
            </Grid>
          </Grid>
          {/* <div className={classes.footer}>
            <Stack gap={2} direction={"row"} justifyContent={"flex-end"}>
              <Button
                variant="outlined"
                className={`${classes.sumbit} ${classes.cancelButton}`}
                onClick={back}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.sumbit}
                onClick={Continue}
                sx={{ background: theme.palette.button.primary }}
              >
                {props.isEdit ? `Update` : `Save & Continue`}
              </Button>
            </Stack>
          </div> */}
        </div>

        <Snackbar
          open={alert.open}
          message={alert.message}
          ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAlert({ ...alert, open: false })}
          autoHideDuration={4000}
        />
      </div>

      <div className={classes.footer}>
        <Button
          className={classes.cancelButton}
          variant="outlined"
          onClick={() => back()}
        >
          Cancel
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          sx={{ background: theme.palette.button.primary }}
          variant="contained"
          onClick={() => Continue()}
        >
          {props?.isEdit || vendor_id ? `Update` : `Save & Continue`}
        </Button>
        &nbsp;
      </div>
    </>
  );
};
