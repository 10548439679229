import React, { useContext, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Snackbar,
  IconButton,
  Stack,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { TextBox, MobileNumberInputComponent } from "../../components";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { ListContext } from "../../contexts/listcontext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { propsToClassKey } from "@mui/styles";
import { AlertContext } from "../../contexts";
import { KeyboardBackspaceOutlined } from "@mui/icons-material";
import { AddVendor } from "./addvendor";
import { ApiConfigure } from "../apiconfigure/apiconfigure";
import { MapSchema } from "../schemamapping/schemamapping";
import { ClassNames } from "@emotion/react";
import { AddVendorRule } from "../addAndEditVendor/addVendorRule/vendorRule";
import { VendorRenameRule } from "../addAndEditVendor/addRenameRule/renameRule";
import { MarginAddRule } from "../addAndEditVendor/marginVendorRule/addMarginRule";

const steps = [
  "Add Vendor details",
  "Configure Source Feed",
  "DB Schema Mapping",
  "Add Filter Rules",
  "Add Rename Rules",
  "Add Margin Rules",
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    // padding: 30,
    margin: 24,
    border: "0.2px solid #DCDCDC",
    borderRadius: "10px",
    // minHeight: 546,
    overflow:"auto",
    height: "85vh",
  },
  paddedSpace: {
    padding: "10px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  stepper: {
    padding: "40px 58px 40px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "&. MuiStepLabel-iconContainer": {
      border: "1px solid",
    },
    "& .MuiStepIcon-root": {
      width: "35px",
      height: "35px",
      border: `1px solid ${theme.palette.stepper.grey}`,
      borderRadius: "50%",
      color: "transparent",
    },
    "& .MuiStepLabel-iconContainer > .Mui-active": {
      border: `1px solid ${theme.palette.stepper.green}`,
    },
    "& .MuiStepIcon-text": {
      fill: `${theme.palette.stepper.grey}`,
    },

    "& .Mui-active > .MuiStepIcon-text": {
      fill: `${theme.palette.stepper.green}`,
    },

    "& .Mui-completed .MuiSvgIcon-root": {
      fill: `${theme.palette.stepper.green}`,
      color: `${theme.palette.stepper.green} !important`,
      border: `1px solid ${theme.palette.stepper.green}`,
    },

    "& .MuiStepConnector-root": {
      left: "calc(-50% + 17px) !important",
      right: "calc(50% + 17px) !important",
      top: `17px`,
    },

    "& .Mui-active > .MuiStepConnector-line": {
      borderColor: `${theme.palette.stepper.green} !important`,
    },
    "& .Mui-completed > .MuiStepConnector-line": {
      borderColor: `${theme.palette.stepper.green} !important`,
    },
    "& .MuiStepLabel-label": {
      margin: "auto",
      marginTop: "16px",
      width: "95px",
    },
    "& .Mui-active": {
      fontWeight: "bold",
    },
    "& .MuiStepLabel-label .Mui-completed": {
      fontWeight: "normal",
      color: "rgba(0, 0, 0, 0.6) !important",
    },
  },
}));

export const StepperRegistration = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [vendor_id, setVendorId] = React.useState();
  const { stepper, updateStepper } = useContext(ListContext);

  const [finishedSteps, setFinishedSteps] = React.useState([]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const getContextKey = (index) => {
    switch (index) {
      case 0:
        return "vendor_detail";
      case 1:
        return "feed_source";
      case 2:
        return "schema_mapping";
      case 3:
        return "filter_rules";
      case 4:
        return "rename_rules";
      case 5:
        return "margin_rules";
    }
  };

  const handleNext = (data) => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    let temp_arr = finishedSteps;
    temp_arr.push(newActiveStep-1);
    temp_arr = [...new Set(temp_arr)];
    setFinishedSteps(temp_arr);
    // updateStepper(getContextKey(newActiveStep - 1), data);
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const getStepForm = (stepCount) => {
    switch (stepCount) {
      case 0:
        return (
          <div className={classes.paddedSpace}>
            <AddVendor
              {...props}
              isStepper={true}
              vendor_id={vendor_id}
              isEdit={false}
              editData={stepper?.vendor_detail}
              onSave={(id, data) => {
                setVendorId(id);
                handleNext(data);
              }}
            />
          </div>
        );
      case 1:
        return (
          <div className={classes.paddedSpace}>
            <ApiConfigure
              {...props}
              vendor_id={vendor_id}
              isStepper={true}
              isEdit={false}
              stepper_registration={true}
              onSave={(id, data) => handleNext(data)}
            />
          </div>
        );
      case 2:
        return (
          <div className={classes.paddedSpace}>
            <MapSchema
              {...props}
              vendor_id={vendor_id}
              isEdit={false}
              isStepper={true}
              onSave={() => handleNext()}
            />
          </div>
        );
      case 3:
        return (
          <div className={classes.paddedSpace}>
            <AddVendorRule
              {...props}
              vendor_id={vendor_id}
              isEdit={false}
              isStepper={true}
              onSave={() => handleNext()}
            />
          </div>
        );
      case 4:
        return (
          <div className={classes.paddedSpace}>
            <VendorRenameRule
              {...props}
              vendor_id={vendor_id}
              isStepper={true}
              onSave={() => handleNext()}
            />
          </div>
        );
      case 5:
        return (
          <div className={classes.paddedSpace}>
            <MarginAddRule
              {...props}
              vendor_id={vendor_id}
              isStepper={true}
              onSave={() => handleNext()}
            />
          </div>
        );
      default:
        return <p>default</p>;
    }
  };

  const handleForward = (index) => {
    console.log(index);
    console.log(finishedSteps);
    if (finishedSteps?.includes(index) || true) {
      setActiveStep(index);
    }
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepper}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            onClick={() => {
              handleForward(index);
            }}
            sx={{cursor:"pointer"}}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepForm(activeStep)}
    </div>
  );
};
