import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Checkbox,
  ListItemButton,
  ListItemText,
  useTheme,
  Snackbar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { KeyboardBackspace } from "@mui/icons-material";
import { property } from "lodash";
import { TextBox } from "../../components";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { caratlaneSchema } from "../../utils";
import MappedArrow from "../../assets/mappedArrow";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    margin: "10px 11px 10px",
    // backgroundColor: theme.palette.common.white,
    padding: "15px",
    borderRadius: "15px",
  },
  rootsection: {
    textAlign: "left",
    margin: "0px 11px 10px",
    backgroundColor: theme.palette.common.white,
    borderRadius: "15px",
    border: `0.2px solid #8484843D`,
    boxShadow: "0px 8px 69px #0000001A",
    // borderColor: theme.palette.common.grey,
    position: "relative",
    "& .MuiList-root": {
      // maxHeight: "300px",
      height: "320px",
      overflow: "auto",
      paddingTop: "10px",
    },
  },
  tittle: {
    fontWeight: 600,
    fontSize: "20px",
    position: "sticky",
  },
  tittlesection: {
    fontWeight: 600,
    // fontSize: "20px",
    position: "sticky",
    padding: "13px 15px",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.choose.dark,
  },
  mapbtn: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px 28px 10px",
  },
  menulist: {
    padding: "3px 48px 3px 16px",
  },
  listView: {
    padding: "4px 48px 4px 16px",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderRadius: "14px",
    "& .MuiTypography-root": {
      // fontWeight: 600,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderRadius: "14px",
      "& .MuiTypography-root": {
        // fontWeight: 600,
      },
    },
  },
  list: {
    padding: "10px 15px 15px",
  },
  main: {
    width: "100%",
    maxWidth: "90%",
    margin: "0 auto 50px",

    // maxHeight: '100vh'
    // backgroundColor: theme.palette.common.grey,
  },
  search: {
    borderWidth: 0,
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderWidth: 0,
      backgroundColor: "#2B31450D",
    },
  },
  cancelButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    borderColor: "#84848466 !important",
    "& :hover": {
      color: theme.palette.secondary.main,
      borderColor: "#84848466 !important",
    },
  },
  footer: {
    borderTop: "1px solid #eeeeee",
    bottom: 96,
    right: 0,
    display: "flex",

    justifyContent: "flex-end",

    overflow: "hidden",
    padding: 10,
    background: "#fff",
    // position: "absolute",
    width: "96%",
    left: "24px",
    borderRadius: 6,
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    margin: "20px 20px 14px 20px",
    // align-items: center;
    justifyContent: "left",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: 16,
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
    },
  },
}));

const custom = [
  // {
  //   id: "1",
  //   tittle: "size",
  //   isEnabled: true,
  // },
  // {
  //   id: "2",
  //   tittle: "color",
  //   isEnabled: true,
  // },
  // {
  //   id: "3",
  //   tittle: "company",
  //   isEnabled: true,
  // },
  // {
  //   id: "4",
  //   tittle: "amount",
  //   isEnabled: true,
  // },
];

const custom2 = caratlaneSchema;
// const custom2 = [
//   {
//     id: "1",
//     tittle: "size",
//     isEnabled: true,
//   },
//   {
//     id: "2",
//     tittle: "color",
//     isEnabled: true,
//   },
//   {
//     id: "3",
//     tittle: "company",
//     isEnabled: true,
//   },
//   {
//     id: "4",
//     tittle: "amount",
//     isEnabled: true,
//   },
// ]

export const MapSchema = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  // let custom = [];
  // let loading = true

  const vendor_id = props?.vendor_id || location.state?.vendor_id;
  const backurl = location.state?.Location;

  const [arr1, setArr] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const updateCustom = (obj, index, length) => {
    let arr = arr1;
    custom.push(obj);
    setArr(arr);
    if (index + 1 === length) {
      loadMapped();
      console.log(custom);
      setLoading(false);
    }
  };
  // console.log(arr1)

  const [data1, setData1] = React.useState({});
  const [data2, setData2] = React.useState({});

  const [multiData, setmultiData] = React.useState([]);

  let call;

  const [selected, Setselected] = React.useState([]);
  const [show, setShow] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const [dbsearch, setdbSearch] = React.useState("");
  const [mapsearch, mapsetSearch] = React.useState("");

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const hiddenFileInput = React.useRef(null);

  const [checked, setChecked] = React.useState([]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    console.log(checked);
    console.log(currentIndex);
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // useEffect(() => {
  //   console.log(custom)
  // }, [custom])

  const handleData1 = (e, value) => {
    if (e === undefined) {
      console.log(multiData);
      let allProperty = [...selected];

      custom2.map((x) => {
        x.isEnabled = true;
      });
      allProperty.map((z) => {
        custom2.map((x) => {
          if (z.dbdata === x.tittle) {
            x.isEnabled = false;
          }
          // else
          // {
          //   x.isEnabled = true
          // }
          // console.log(`custom2 - ${JSON.stringify(x)}`)
          // console.log(`allProperty - ${JSON.stringify(z)}\n`)
        });
      });
      let temp_data = [...multiData];
      temp_data.push(value);
      setmultiData(temp_data);
      setData1(value);
    } else {
      setData1("");
    }
  };
  const handleData2 = (e, value) => {
    if (checked.length <= 0) {
      setAlert({
        open: true,
        message:
          "Please select a Vendor Schema before selecting Caratlane Schema",
        backgroundColor: "#FF3232",
      });
    } else {
      if (e === undefined) {
        setData2(value);
        custom2.map((x) => {
          if (x.id === value.id) {
            x.isEnabled = false;
          }
        });
        console.log(checked);
        // checked.map((x) => {
        //   custom.map((z) => {
        //     if(z.id === x.id)
        //     {
        //       x.isEnabled = true
        //     }
        //   })
        // })

        custom.map((x) => {
          checked.map((z) => {
            if (x.id === z.id) {
              x.isEnabled = false;
            }
            // else{
            //   x.isEnabled = true
            // }
            // console.log(z.id === x.id)
          });
        });
      } else {
        setData2("");
      }
    }
  };

  const submit = () => {
    console.log(props);

    if (Object.keys(data2).length > 0) {
      console.log(checked.map((x) => x.tittle).join(" * "));
      setChecked([]);
      const tempdata = {
        uploaddata: checked.map((x) => x.tittle).join(" * "),
        dbdata: data2.tittle,
      };
      if (tempdata.uploaddata && tempdata.dbdata) {
        Setselected([...selected, tempdata]);
        // setShow(true);
      } else {
        // if(Object.keys(data1).length === 0)
        // {
        //   console.log(props)
        // }
        console.log(props);
      }
    }
  };

  const Skip = () => {
    if (backurl) {
      clearInterval(call);
      history.push(backurl, { ID: vendor_id });
    } else {
      // history.push("/listing", { vendor_id: vendor_id });
      clearInterval(call);
      props.onSave(vendor_id);
    }
  };

  useEffect(() => {
    submit();
  }, [data2]);

  useEffect(() => {
    let id = vendor_id;
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    // let id = vendor_id;
    // let token = localStorage.getItem("auth_token").toString();
    // token = token.substring(1, token.length - 1);

    call = setInterval(() => {
      if (!vendor_id) {
        return;
      }
      NetworkCall(
        `${config.api_url}feed/unqiue/header/get`,
        "POST",
        {
          vendor_id: vendor_id,
        },
        {
          // auth_token: "auth_token",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      )
        .then((res) => {
          let temp_head = res?.data?.original_headers;
          temp_head.map((x, index) => {
            let obj = {
              id: (index + 1).toString(),
              tittle: x,
              isEnabled: true,
            };
            if (temp_head) {
              clearInterval(call);
              updateCustom(obj, index, temp_head?.length);
            }

            // custom.push(obj)
          });
        })
        .catch((err) => {});
    }, 5000);
  }, []);

  useEffect(() => {
    let id = vendor_id;
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    if (show) {
      NetworkCall(
        `${config.api_url}feed/unqiue/header/get`,
        "POST",
        {
          vendor_id: vendor_id,
        },
        {
          // auth_token: "auth_token",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      )
        .then((res) => {
          let temp_head = res?.data?.original_headers;
          temp_head.map((x, index) => {
            let obj = {
              id: (index + 1).toString(),
              tittle: x,
              isEnabled: true,
            };
            if (temp_head) {
              clearInterval(call);
              updateCustom(obj, index, temp_head?.length);
              setShow(false);
            }

            // custom.push(obj)
          });
        })
        .catch((err) => {});
    }
  });

  const loadMapped = () => {
    let id = vendor_id;
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    NetworkCall(
      `${config.api_url}schema/get?vendor_id=${id}`,
      "GET",
      {},
      {
        // auth_token: "auth_token",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        console.clear();
        console.log(res?.data?.schema_mapping_data);
        let keys = Object.keys(res?.data?.schema_mapping_data);
        let values = Object.values(res?.data?.schema_mapping_data);
        let arr = [];

        // keys.map((z, index) => {
        //   let arr = z.split("*")
        //   console.log(arr)
        //   keys.remove(index, 1)
        //   arr.map((k) => {
        //     keys.push(k)
        //   })

        // })

        console.log(custom);
        console.log(keys);
        let temp_arr = [];
        custom.map((z, index) => {
          keys.map((y) => {
            if (z.tittle === y) {
              custom[index].isEnabled = false;
            }
          });
          // if (!keys.indexOf(z.tittle)) {
          //   console.log(z)
          //   temp_arr.push(index)
          //   console.log(temp_arr)
          //   // custom[index].isEnabled = false;
          //   // z.isEnabled = false
          // }
          return null;
        });
        custom2.map((k, index) => {
          values.map((p) => {
            if (k.tittle === p) {
              custom2[index].isEnabled = false;
            }
          });
          // if (!values.indexOf(k.tittle)) custom2[index].isEnabled = false;
          return null;
        });

        values.map((x, index) => {
          const tempdata = {
            uploaddata: keys[index],
            dbdata: x,
          };
          arr.push(tempdata);
        });
        Setselected(arr);
        // setLoading(false);
      })
      .catch((err) => {});
  };

  const back = () => {
    Setselected([]);
    setChecked([]);
    custom2.map((x) => {
      x.isEnabled = true;
    });
    custom.length = 0;
    // custom2.length = 0
    clearInterval(call);
    history.goBack(-1);
  };
  const next = () => {
    // props.onSave("1");
    // props.onSave("1");
    // if (backurl) {
    //   history.push(backurl, { vendor_id: vendor_id });
    // } else
    {
      const payload = {
        vendor_id: vendor_id,
        schema_mapping_data: {},
      };
      selected.map((x) => {
        payload.schema_mapping_data[x.uploaddata] = x.dbdata;
      });
      let token = localStorage.getItem("auth_token").toString();
      token = token.substring(1, token.length - 1);
      NetworkCall(`${config.api_url}schema/map`, "POST", payload, {
        // auth_token: "auth_token",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      })
        .then((res) => {
          // props.onSave("1");
          clearInterval(call);
          props.isStepper ? props.onSave(vendor_id) : history.goBack(-1);
          // history.push({
          //   pathname: "/addvendorrule",
          //   state: {
          //     ID: location.state?.vendor_id,
          //     isAdd: true,
          //     Location: "/addrenamerule",
          //   },
          // });
        })
        .catch((err) => {});
    }
  };

  const onDelete = (deletedIndex) => {
    let allProperty = [...selected];
    allProperty.map((property, index) => {
      if (index === deletedIndex) {
        console.log(property.uploaddata.split("*"));
        let temp = property.uploaddata.split(" * ");
        temp.length > 0
          ? custom.map((x) => {
              temp.map((z) => {
                if (z === x.tittle) {
                  x.isEnabled = true;
                }
              });
            })
          : custom.map((x) => {
              console.log(x);

              if (x.tittle === property.uploaddata) {
                x.isEnabled = true;
              }
            });
        custom2.map((x) => {
          console.log(x);
          if (x.tittle === property.dbdata) {
            x.isEnabled = true;
          }
        });
      }
    });
    let temp_selected = allProperty
      .filter((property, index) => index !== deletedIndex)
      .map((x) => x);
    Setselected(temp_selected);
    setData2({});
    if (temp_selected.length <= 0) {
      // setShow(false);
    }
  };
  const isSelected = (data1, val) => {
    if (data1) {
      return val === data1;
    }
    // return val.filter((x) => x.tittle === data1).length > 0;
    // val.map((x) => {
    //   return x.tittle === data1;
    // });
  };
  return (
    <>
      {!props.isStepper && (
        <div className={classes.titleSection}>
          <ArrowBackIcon className="icon" onClick={() => back()} />
          <Typography variant="subtitle2">Schema Mapping</Typography>
        </div>
      )}
      <div
        className={classes.main}
        style={{ paddingTop: props?.isStepper ? 20 : 0 }}
      >
        {/* {!props.isStepper && (
        <div className={classes.root}>
          <Typography className={classes.tittle}>
            <IconButton onClick={back}>
              <KeyboardBackspace />
            </IconButton>
            DB Schema Mapping
          </Typography>
        </div>
      )} */}
        <Grid container>
          <Grid item md={4} xs={12}>
            <div className={classes.rootsection}>
              {!loading ? (
                <>
                  <Typography className={classes.tittlesection}>
                    {`Vendor Schema (${custom?.length})`}
                  </Typography>
                  <div className={classes.list}>
                    {custom?.length > 0 ? (
                      <>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            size="small"
                            type="search"
                            fullWidth
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                            placeholder="search by data"
                            className={classes.search}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {/* <Button variant="outlined" style={{ marginLeft: "10px" }}>
                      {" "}
                      <FilterAltOutlinedIcon />
                    </Button> */}
                        </div>
                        <List>
                          {custom
                            .filter((val) => {
                              if (search === "") {
                                return val;
                              } else if (
                                val.tittle
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              ) {
                                return val;
                              }
                            })
                            .map((val, index) => {
                              return (
                                <>
                                  <ListItem
                                    className={
                                      // val.tittle === data1.tittle
                                      checked.indexOf(val) !== -1
                                        ? classes.listView
                                        : classes.menulist
                                    }
                                    sx={{
                                      cursor: "pointer",
                                      // "& :hover": {
                                      //   backgroundColor: "#0000000a",
                                      // },
                                    }}
                                    // onClick={(e) =>
                                    //   handleData1(e.target.checked, val)
                                    // }
                                    onClick={
                                      val?.isEnabled && handleToggle(val)
                                    }
                                    // disabled={checked.indexOf(val) !== -1}
                                    disabled={!val?.isEnabled}
                                    secondaryAction={
                                      <Checkbox
                                        // disabled={checked.indexOf(val) !== -1}
                                        checked={checked.indexOf(val) !== -1}
                                        // onChange={checked.indexOf(val) === -1 && handleToggle(val)}
                                        icon={<></>}
                                        checkedIcon={
                                          <CheckCircleIcon
                                            style={{
                                              position: "absolute",
                                              right: "7px",
                                            }}
                                          />
                                        }
                                      />
                                    }
                                  >
                                    <ListItemText primary={val.tittle} />
                                  </ListItem>
                                </>
                              );
                            })}
                        </List>
                      </>
                    ) : (
                      <>
                        <Typography
                          color={"textSecondary"}
                          style={{ padding: "6px 0px" }}
                        >
                          No Schema updated yet
                        </Typography>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Typography className={classes.tittlesection}>
                    {`Vendor Schema (${custom?.length})`}
                  </Typography>
                  <div className={classes.list}>
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          size="small"
                          type="search"
                          fullWidth
                          onChange={(e) => setSearch(e.target.value)}
                          value={search}
                          placeholder="search by data"
                          className={classes.search}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <List>
                        <div
                          style={{
                            width: "60%",
                            margin: "auto",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            color={"textSecondary"}
                            style={{ padding: "6px 0px" }}
                          >
                            columns & values are being extracted ...
                          </Typography>
                        </div>
                      </List>
                    </>
                  </div>
                </>
              )}
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <div className={classes.rootsection}>
              {!false && (
                <>
                  <Typography
                    className={classes.tittlesection}
                  >{`Caratlane Schema (${custom2.length})`}</Typography>
                  <div className={classes.list}>
                    <TextField
                      size="small"
                      type="search"
                      onChange={(e) => setdbSearch(e.target.value)}
                      value={dbsearch}
                      fullWidth
                      placeholder="search by data"
                      className={classes.search}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <List>
                      {custom2
                        .filter((val) => {
                          if (dbsearch === "") {
                            return val;
                          } else if (
                            val.tittle
                              .toLowerCase()
                              .includes(dbsearch.toLowerCase())
                          ) {
                            return val;
                          }
                        })
                        .map((val, index) => {
                          return (
                            <>
                              <ListItem
                                className={
                                  val.tittle === data2.tittle
                                    ? classes.listView
                                    : classes.menulist1
                                }
                                button
                                key={index}
                                disabled={!val?.isEnabled}
                                onClick={(e) => {
                                  handleData2(e.target.checked, val);
                                }}
                              >
                                {val.tittle === data2.tittle ? (
                                  <Box
                                    style={{
                                      display: "flex",
                                      margin: "5px 0px",
                                    }}
                                  >
                                    <Box flexGrow={1}>
                                      <Typography>{val.tittle}</Typography>
                                    </Box>
                                    <Box>
                                      <CheckCircleIcon
                                        style={{
                                          position: "absolute",
                                          right: "7px",
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                ) : (
                                  <Typography>{val.tittle}</Typography>
                                )}
                                {/* end */}
                              </ListItem>
                            </>
                          );
                        })}
                    </List>
                  </div>
                </>
              )}
            </div>
          </Grid>
          {true && (
            <Grid item md={4} xs={12}>
              <div className={classes.rootsection}>
                <Typography className={classes.tittlesection}>
                  {`Mapped Schema (${selected?.length})`}
                </Typography>
                <div className={classes.list}>
                  <TextField
                    size="small"
                    type="search"
                    onChange={(e) => mapsetSearch(e.target.value)}
                    value={mapsearch}
                    fullWidth
                    placeholder="search by data"
                    className={classes.search}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <List>
                    {selected?.length > 0 ? (
                      <>
                        {selected
                          .filter((val) => {
                            if (mapsearch === "") {
                              return val;
                            } else if (
                              val.uploaddata
                                .toLowerCase()
                                .includes(mapsearch.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                          .map((val, index) => {
                            return (
                              <>
                                <ListItem className={classes.menulist} button>
                                  <Box
                                    style={{
                                      display: "flex",
                                      margin: "5px 0px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <>{val.uploaddata}</> &nbsp;
                                    <>
                                      {" "}
                                      <MappedArrow style={{ width: 20 }} />
                                    </>{" "}
                                    &nbsp; <>{val.dbdata}</>
                                    <>
                                      <CloseIcon
                                        style={{
                                          position: "absolute",
                                          right: "7px",
                                        }}
                                        fontSize="small"
                                        onClick={() => onDelete(index)}
                                      />
                                    </>
                                  </Box>
                                  {/* <Stack flexDirection={"row"} alignItems="center" sx={{width:"100%"}}>
                                    <>
                                      <Stack flexDirection={"row"} spacing={2} sx={{width:"100%"}} justifyContent="space-between" alignItems="center">
                                        <>{val.uploaddata}</>
                                        <> <MappedArrow sx={{padding: "0px 2px"}}/></>
                                        <>{val.dbdata}</>
                                      </Stack>
                                    </>
                                    <>
                                      <CloseIcon
                                        style={{
                                          position: "absolute",
                                          right: "7px",
                                        }}
                                        fontSize="small"
                                        onClick={() => onDelete(index)}
                                      />
                                    </>
                                  </Stack> */}
                                </ListItem>
                                {/* end */}
                              </>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {/* <Typography className={classes.tittlesection}>
                        {`Vendor Schema (${custom?.length})`}
                      </Typography>
                      <div className={classes.list}>
                        <> */}
                        <div
                          style={{
                            width: "60%",
                            margin: "auto",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            color={"textSecondary"}
                            style={{ padding: "6px 0px" }}
                          >
                            No Schema mapped yet
                          </Typography>
                        </div>
                        {/* </>
                      </div> */}
                      </>
                    )}
                  </List>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        {/* <div className={classes.mapbtn}> */}
        {/* <Button
          color="primary"
          variant="contained"
          style={{ textTransform: "capitalize" }}
          onClick={back}
        >
          Back
        </Button> */}
        {/* <Button
          color="primary"
          variant="contained"
          style={{ textTransform: "capitalize" }}
          onClick={submit}
        >
          Map
        </Button> */}
        {/* <Stack gap={2} direction={"row"} justifyContent={"flex-end"}> */}
        <div className={classes.footer}>
          <Button
            variant="outlined"
            onClick={Skip}
            className={`${classes.sumbit} ${classes.cancelButton}`}
          >
            {props?.isStepper ? `Skip` : `Cancel`}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            className={classes.sumbit}
            style={{
              // textTransform: "capitalize",
              background: theme.palette.button.primary,
            }}
            onClick={next}
            // disabled={show ? false : true}
          >
            {!props?.isStepper ? `Update` : `Save & Countinue`}
          </Button>
          &nbsp;
        </div>
        {/* </Stack> */}
        {/* </div> */}
        <Snackbar
          open={alert.open}
          message={alert.message}
          ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAlert({ ...alert, open: false })}
          autoHideDuration={4000}
        />
      </div>
    </>
  );
};
