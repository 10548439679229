import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { LocalStorageKeys } from "../../../utils";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, Hidden, Typography } from "@mui/material";
import { SideNavBar } from "..";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../router/routes";
import { Person } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background:
      "transparent linear-gradient(90deg, #DE57E5 0%, #8863FB 100%) 0% 0% no-repeat padding-box",
    // backgroundColor: "linear-gradient(90deg, #DE57E5 0%, #8863FB 100%)",
  },
  title: {
    display: "block",
    marginTop: "7px",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const TopNavBar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    openSideNavBar: false,
  });

  const onLogOut = () => {
    localStorage.removeItem(LocalStorageKeys.authToken);
    history.push(Routes.login);
  };

  const toogleSideNavBar = () => {
    setState({
      ...state,
      openSideNavBar: !state.openSideNavBar,
    });
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.menuIcon}
            onClick={toogleSideNavBar}
            size="large"
          >
            <MenuIcon htmlColor="white" />
          </IconButton>

          <div className={classes.titleContainer}>
            {/* <Hidden mdUp> */}
            <Typography className={classes.title} variant="h6" noWrap>
              {/* <img
                src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRL9jCDzgzw-_i6gNZbKutHVKf4HEQywfVnutdwm1z7yWjVvDML"
                alt="logo"
                width="30%"
                height="30%"
              /> */}
            </Typography>
            {/* </Hidden> */}
          </div>

          <div className={classes.grow} />
          <Person style={{ fontSize: 22 }} />
          <Typography variant="subtitle2" sx={{ paddingLeft: 1, fontSize: 16 }}>
            {localStorage.getItem('user_role')}

          </Typography>

          <Drawer
            open={state.openSideNavBar}
            variant={"temporary"}
            anchor="left"
            onClose={toogleSideNavBar}
          >
            <div style={{ width: 240 }}>
              <SideNavBar isMobile={true} />
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
    </div>
  );
};
