import React from "react";
import { EditVendor } from "./editvendor";
import { withNavBars } from "./../../HOCs";
import ListContextProvider from "../../contexts/listcontext";
import { AddVendor } from "../addnewvendor/addvendor";

class EditVendorParent extends React.Component {
  render() {
    return (
      <ListContextProvider>
        {/* <EditVendor /> */}
        <div
          sx={{
            padding: "10px 50px",
            // [theme.breakpoints.down("sm")]: {
            //   padding: 0,
            // },
          }}
        >
          <AddVendor isStepper={false} isEdit={true} />
        </div>
      </ListContextProvider>
    );
  }
}

export default withNavBars(EditVendorParent);
