import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    position: "fixed",
    top: "15px",
    background: "#fff",
    padding: "6px",
    borderRadius: "50%",
    right: "420px",
    height: "36px",
    width: "36px",
    cursor: "pointer",
    zIndex: 100000,
  },
}));

export const DrawerComponent = (props) => {
  const classes = useStyles();

  const { children, anchor, handleClose, open } = props;

  return (
    <div className={classes.root}>
      <Drawer anchor={anchor} open={open} 
      onClose={handleClose && handleClose}
      >
        <div
          onClick={() => handleClose && handleClose()}
          className={classes.icon}
        >
          <CloseIcon />
        </div>
        {children}
      </Drawer>
    </div>
  );
};
