import React from "react";
import {
  DrawerComponent,
  // List,
  TextBox,
  Select,
  ListItem,
  Subheader,
} from "../../../components";
import {
  useStyles,
  useDetailDrawerStyles,
  useDetailDrawerItemStyles,
} from "./styles";
import {
  Button,
  Typography,
  Grid,
  Snackbar,
  Divider,
  Switch,
  Pagination,
  Stack,
} from "@mui/material";
import axios from "axios";
import moment from "moment-timezone";
import { config } from "../../../config";
import classNames from "classnames";
import { useTheme } from "@emotion/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NetworkCall } from "../../../networkcall";
import { UseDebounce } from "../../../components";

const listofoperators = [
  { label: "min", value: "min" },
  { label: "max", value: "max" },
];

const DetailDrawerItem = ({ label, value }) => {
  const classes = useDetailDrawerItemStyles();
  return (
    <Stack
      className={classes.root}
      flexDirection="row"
      justifyContent="space-between"
    >
      <Typography className={classNames(classes.text, classes.label)}>
        {label}
      </Typography>
      <Typography className={classNames(classes.text, classes.value)}>
        {value}
      </Typography>
    </Stack>
  );
};

const pageSize = 5;

export const MarginAddRule = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const detailDrawerClasses = useDetailDrawerStyles();

  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [detailDrawerOpen, setDetailDrawerOpen] = React.useState(false);
  const [openedRule, setOpenedRule] = React.useState({});

  const handleDetailDrawerClose = () => {
    setDetailDrawerOpen((prevOpen) => !prevOpen);
  };

  const debounce = UseDebounce();

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const [search, setSearch] = React.useState(null);

  const [state, setstate] = React.useState({
    isopen: false,
    listData: [],
    vendor_id: "",
    rule_name: "",
    chooseVerdorIds: [],
    choosevendor: {},
    error: {},
    fixed_markup: "",
    percentage_markup: "",
    fixed_price: "",
    shipping_fee: "",
    output: "",
    constraints: {
      Cost: {
        from: "",
        to: "",
      },
      Carat: {
        from: "",
        to: "",
      },
    },
    id: "",
    active: false,
  });

  const isClearState = async () => {
    await setstate({
      ...state,
      isopen: false,
      constraints: {
        Cost: {
          from: "",
          to: "",
        },
        Carat: {
          from: "",
          to: "",
        },
      },
      vendor_id: "",
      rule_name: "",
      choosevendor: {},
      error: {},
      fixed_markup: "",
      percentage_markup: "",
      fixed_price: "",
      shipping_fee: "",
      output: "",
      active: false,
      id: "",
    });
  };

  const handleAddChild = (p, c, l, v) => {
    if (v) {
      state[p][c][l] = +v;
    } else {
      state[p][c][l] = v;
    }
    setstate({ ...state });
  };

  const handleClose = async () => {
    setstate({
      ...state,
      isopen: !state.isopen,
    });
    isGetList();
    await isClearState();
  };

  const searchTableFunction = (text) => {
    let api_url;

    if (text !== "") {
      api_url = config.api_url + `rules/margin/get/all?search=${text}`;
    } else {
      api_url = config.api_url + `rules/margin/get/all`;
    }

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    NetworkCall(
      `${api_url}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((response) => {
        const data = response.data?.rows?.map((v, i) => {
          if (v?.id) {
            return {
              ...v,
              active: v?.fixed_markup > 0 ? true : false,
              margin: true,
              last_modifyed: moment(v?.updated_at).format("YYYY-MM-DD"),
              created_at: moment(v?.created_at).format("YYYY-MM-DD"),
            };
          }
        });
        const vedor_id = data?.map((v) => {
          return {
            value: v?.vendor_id ?? "",
            label: v?.vendor?.first_name ?? "",
          };
        });
        setstate({
          ...state,
          listData: data,
          chooseVerdorIds: vedor_id ?? [],
          isopen: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (e) => {
    // setSearch(e)
    // setstate({
    //   listData:e
    //   ? listData?.filter(
    //       (v) => v?.rule_name?.toLowerCase()?.includes(e?.toLowerCase()) && v
    //     )
    //   : listData
    // })
    debounce(() => searchTableFunction(e), 800);
  };

  const isGetList = async () => {
    const api_url = config.api_url + "rules/margin/get/all";
    // ?limit=5&offset=0

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    new Promise((resolve, reject) => {
      axios
        .get(`${api_url}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          const data = response.data?.rows?.map((v, i) => {
            if (v?.id) {
              return {
                ...v,
                active: v?.fixed_markup > 0 ? true : false,
                margin: true,
                last_modifyed: moment(v?.updated_at).format("YYYY-MM-DD"),
                created_at: moment(v?.created_at).format("YYYY-MM-DD"),
              };
            }
          });
          const vedor_id = data?.map((v) => {
            return {
              value: v?.vendor_id ?? "",
              label: v?.vendor?.first_name ?? "",
            };
          });
          setstate({
            ...state,
            listData: data,
            chooseVerdorIds: vedor_id ?? [],
            isopen: false,
          });
          resolve(true);
        });
    });
  };

  React.useEffect(() => {
    isGetList();
  }, []);

  const ishandleEdit = (data, isClone) => {
    // const paylod = data.margin_rules_data;
    console.log(data, isClone);
    debugger;
    state["constraints"] = {
      Cost: {
        from: data?.constraints?.all?.[0]?.value?.from ?? "",
        to: data?.constraints?.all?.[0]?.value?.to ?? "",
      },
      Carat: {
        from: data?.constraints?.all?.[1]?.value?.from ?? "",
        to: data?.constraints?.all?.[1]?.value?.to ?? "",
      },
    };
    state["fixed_markup"] = data?.fixed_markup ?? "";
    state["percentage_markup"] = data?.percentage_markup ?? "";
    state["fixed_price"] = data?.fixed_price ?? "";
    state["shipping_fee"] = data?.shipping_fee ?? "";
    state["output"] = data?.output ?? "";

    setstate({
      ...state,
      isopen: !state.isopen,
      vendor_id: data?.vendor_id,
      rule_name: isClone ? data?.rule_name + "-Copy" : data?.rule_name,
      error: {},
      active: data?.fixed_price > 0 ? true : false,
      id: data?.id ?? "",
    });
  };

  const ishandleDelete = (data, i) => {
    const api_url = config.api_url + "rules/margin/delete";

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    new Promise((resolve, reject) => {
      axios
        .post(
          `${api_url}`,
          {
            rule_id: data?.id ?? "",
            vendor_id: data?.vendor_id ?? "",
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          setAlert({
            open: true,
            message: `Margin rule has been deleted successfully`,
            backgroundColor: "#4BB543",
          });
          isGetList();
          resolve(true);
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something Went Wrong",
            backgroundColor: "#FF3232",
          });
        });
    });
  };

  const isValidation = () => {
    if (state.vendor_id) {
      if (!state?.rule_name) {
        state.error["rule_name"] = true;
      }
      if (!state?.output) {
        state.error["output"] = true;
      }
      if(!state?.fixed_price){
        state.error["fixed_price"] = true;
      }
      if(!state?.active){
        if (!state?.percentage_markup) {
          state.error["percentage_markup"] = true;
        }
      }
      if(state?.active){
        if (!state?.fixed_markup) {
          state.error["fixed_markup"] = true;
        }
      }
      // if (!state?.active) {
      //   if (!state?.fixed_markup) {
      //     state.error["fixed_markup"] = true;
      //   }
      // }
      // if (state?.active) {
      //   if (!state?.fixed_price) {
      //     state.error["fixed_price"] = true;
      //   }
      // }
      // if (!state?.percentage_markup) {
      //   state.error["percentage_markup"] = true;
      // }
      if (!state?.shipping_fee) {
        state.error["shipping_fee"] = true;
      }
      if (
        state?.constraints?.Cost?.from === "" ||
        state?.constraints?.Cost?.to === "" ||
        state?.constraints?.Carat?.from === "" ||
        state?.constraints?.Carat?.to === ""
      ) {
        state.error["constraints"] = true;
      }
    } else {
      if (!state?.choosevendor?.value) {
        state.error["choosevendor"] = true;
      }
      if (!state?.rule_name) {
        state.error["rule_name"] = true;
      }
      if (!state?.output) {
        state.error["output"] = true;
      }
      if(state?.fixed_price.length > 0 || parseInt(state?.fixed_price) < 0 ){
        state.error["fixed_price"] = true;
      }
      if(!state?.active){
        if (!state?.percentage_markup) {
          state.error["percentage_markup"] = true;
        }
      }
      if(state?.active){
        if (!state?.fixed_markup) {
          state.error["fixed_markup"] = true;
        }
      }
      // if (!state?.active) {
      //   if (!state?.fixed_markup) {
      //     state.error["fixed_markup"] = true;
      //   }
      // }
      // if (state?.active) {
      //   if (!state?.fixed_price) {
      //     state.error["fixed_price"] = true;
      //   }
      // }
      // if (!state?.percentage_markup) {
      //   state.error["percentage_markup"] = true;
      // }
      if (!state?.shipping_fee) {
        state.error["shipping_fee"] = true;
      }
      if (
        state?.constraints?.Cost?.from === "" ||
        state?.constraints?.Cost?.to === "" ||
        state?.constraints?.Carat?.from === "" ||
        state?.constraints?.Carat?.to === ""
      ) {
        state.error["constraints"] = true;
      }
    }
    setstate({
      ...state,
    });
  };

  const getErrorMsg = (state) => {
    if (state?.error?.output) {
      return "Output cannot be empty";
    }
    if (state?.error?.rule_name) {
      return "Rule Name is mandatory";
    }
    if (state?.error?.choosevendor) {
      return "Vendor Name is Mandatory";
    }
    if (state?.error?.constraints) {
      return "From and to values cannot be empty";
    }
    if (state?.error?.fixed_markup) {
      return "Fixed markup is mandatory";
    }
    if (state?.error?.fixed_price) {
      if(parseInt(state?.fixed_price) < 0 )
      {
        return "Fixed Price cannot be negative"
      }
      else{
        return "Fixed price is mandatory";
      }
    }
    if (state?.error?.percentage_markup) {
      return "Percentage Markup is mandatory";
    }
    if (state?.error?.shipping_fee) {
      return "Shipping Fees is mandatory";
    } else {
      return "";
    }
  };

  const handleSave = async () => {
    await isValidation();

    if (
      state?.error?.fixed_markup ||
      state?.error?.fixed_price ||
      state?.error?.percentage_markup ||
      state?.error?.shipping_fee ||
      state?.error?.output ||
      state?.error?.rule_name ||
      state?.error?.choosevendor ||
      state.error["constraints"]
    ) {
      let err_message = getErrorMsg(state);
      setAlert({
        open: true,
        message: err_message,
        backgroundColor: "#FF3232",
      });
      setstate({
        ...state,
        error: {},
      });
      return;
    }

    const payload = {
      // constraints: [{

      //   Cost: {

      //     from: +state["constraints"]["Cost"]["from"],
      //     to: +state["constraints"]["Cost"]["to"],
      //   },
      //   Carat: {
      //     from: +state["constraints"]["Carat"]["from"],
      //     to: +state["constraints"]["Carat"]["to"],
      //   },
      // }],
      constraints: {
        all: [
          {
            fact: "Cost",
            path: "payload",
            operator: "between",
            value: {
              from: +state["constraints"]["Cost"]["from"],
              to: +state["constraints"]["Cost"]["to"],
            },
          },
          {
            fact: "Carat",
            path: "payload",
            operator: "between",
            value: {
              from: +state["constraints"]["Carat"]["from"],
              to: +state["constraints"]["Carat"]["to"],
            },
          },
        ],
      },
      fixed_markup: +state["fixed_markup"],
      percentage_markup: +state["percentage_markup"],
      fixed_price: +state["fixed_price"],
      shipping_fee: +state["shipping_fee"],
      output: state["output"],
    };

    const api_url = config.api_url + "rules/margin/upsert";

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    var pay_ = {};
    if (state?.vendor_id) {
      pay_ = {
        id: state?.id ?? "",
        rule_name: state?.rule_name ?? "",
        vendor_id: state?.vendor_id || state?.choosevendor?.value || "",
        ...payload,
      };
    } else {
      pay_ = {
        rule_name: state?.rule_name ?? "",
        vendor_id: state?.vendor_id || state?.choosevendor?.value || "",
        ...payload,
      };
    }

    console.clear()
    console.log(parseInt(state?.fixed_price))
    console.log(pay_)

    new Promise((resolve, reject) => {
      axios
        .post(
          `${api_url}`,
          {
            ...pay_,
          },
          { headers: { 
              Authorization: `Bearer ${token}` 
            } }
        )
        .then((response) => {
          setAlert({
            open: true,
            message: `Margin rule has been ${
              state?.vendor_id ? "updated" : "added"
            } successfully`,
            backgroundColor: "#4BB543",
          });
          if (state?.vendor_id) {
            handleClose();
          }
          isGetList();
          resolve(true);
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something Went Wrong",
            backgroundColor: "#FF3232",
          });
        });
    });
  };

  const handleChange = (key, v) => {
    debugger
    if (key === "active" && !v) {
      state["fixed_price"] = "";
    }
    if (key === "active" && v) {
      state["fixed_markup"] = "";
    }
    if (!isNaN(+v) && v) {
      state[key] = +v;
    } else {
      state[key] = v;
    }
    debugger
    state.error = { ...state.error, choosevendor: false };
    setstate({ ...state });
  };

  const handleAddNewRule = async () => {
    await isClearState();
    setstate({
      ...state,
      isopen: !state.isopen,
      rule_name: "",
      vendor_id: "",
      choosevendor: {},
      constraints: {
        Cost: {
          from: "",
          to: "",
        },
        Carat: {
          from: "",
          to: "",
        },
      },
      fixed_markup: "",
      percentage_markup: "",
      fixed_price: "",
      shipping_fee: "",
      output: "",
      id: "",
      error: {},
      active: false,
    });
  };

  const handleViewMoreClick = (rule) => {
    setDetailDrawerOpen(true);
    setOpenedRule(rule);
  };

  const { listData, vendor_id, rule_name, chooseVerdorIds } = state;

  return (
    <div className={classes.root}>
      {!state.isopen && (
        <Grid container rowGap={3}>
          <Grid item xs={12}>
            <Subheader
              title="Margin Rules"
              count={listData?.length}
              search={search}
              setSearch={(e) => handleSearch(e.target.value)}
              onAddClick={handleAddNewRule}
            />
          </Grid>

          <Grid container item rowGap={1.25}>
            {listData?.length === 0 || !listData ? (
              <Grid item xs={12}>
                <Typography style={{ margin: "25vh 0px", textAlign: "center" }}>
                  No Data
                </Typography>
              </Grid>
            ) : (
              listData
                .slice(page * pageSize - pageSize, page * pageSize)
                .map((rule) => (
                  <Grid item xs={12}>
                    <ListItem
                      key={rule?.id}
                      item={{
                        name: rule?.rule_name,
                        createdAt: rule?.created_at,
                        updatedAt: rule?.last_modifyed,
                        vendorName: rule?.vendor?.first_name ?? "",
                      }}
                      handleEdit={() => ishandleEdit(rule)}
                      handleClone={() => ishandleEdit(rule, true)}
                      handleDelete={() => ishandleDelete(rule)}
                      showViewMore
                      onViewMoreClick={() => handleViewMoreClick(rule)}
                    />
                  </Grid>
                ))
            )}
          </Grid>

          {listData?.length > 0 && (
            <Grid item xs={12}>
              <Stack
                flexDirection="row"
                justifyContent="flex-end"
                style={{ marginTop: "-6px" }}
              >
                <Pagination
                  count={Math.ceil(listData?.length / pageSize)}
                  page={page}
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                  color="primary"
                />
              </Stack>
            </Grid>
          )}
        </Grid>
      )}

      {state.isopen && (
        <>
          <div className={classes.titleSection}>
            <ArrowBackIcon className="icon" onClick={() => handleClose()} />
            <Typography variant="subtitle2">
              {vendor_id ? "Edit" : "Add New"} Margin Rule
            </Typography>
          </div>
          <div className={classes.addruleDrawer}>
            <div className={classes.addruleDrawerBody}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextBox
                    label={"Margin Name"}
                    type={"text"}
                    value={rule_name}
                    isError={state?.error?.rule_name}
                    errorMessage={
                      state?.error?.rule_name && "Please enter the rule name"
                    }
                    isRequired={true}
                    placeholder={"Margin Name"}
                    onChange={(e) =>
                      setstate({
                        ...state,
                        rule_name: e.target.value,
                        error: { ...state.error, rule_name: false },
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  {vendor_id ? (
                    <TextBox
                      label={"Vendor Name"}
                      type={"text"}
                      value={
                        chooseVerdorIds?.filter(
                          (v) => v.value === vendor_id
                        )?.[0]?.label
                      }
                      disabled
                      placeholder={"Vendor Name"}
                    />
                  ) : (
                    <Select
                      disablePortal
                      options={chooseVerdorIds}
                      label="Choose Vendor"
                      placeholder="Choose Vendor"
                      isError={state?.error?.choosevendor}
                      // value={choosevendor ?? {}}
                      errorMessage={
                        state?.error?.choosevendor && "Please choose the vendor"
                      }
                      isRequired={true}
                      onChange={(e, value) =>
                        handleChange("choosevendor", value)
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={2}>
                  {/* Markup Or Price */}
                  <Stack direction="row" spacing={1} alignItems="center" style={{marginTop: 15}}>
                  <Typography color={'#848484'} fontSize={14}>Percentage Markup</Typography>
                  <Switch
                    checked={state?.active}
                    onChange={() => {
                      handleChange("active", !state?.active);
                    }}
                    className={classes?.switch}
                  />
                  <Typography color={'#848484'} fontSize={14}>Fixed Markup</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextBox
                    //label={!state?.active ? "Fixed Markup" : "Fixed Price"}
                    label={'Fixed Price'}
                    type={"number"}
                    // value={
                    //   !state?.active
                    //     ? state["fixed_markup"]
                    //     : state["fixed_price"]
                    // }
                    value={state["fixed_price"]}
                    placeholder={
                      // !state?.active ? "Fixed Markup" : "Fixed Price"
                      'Fixed Price'
                    }
                    isRequired={true}
                    onChange={(e) => {
                      handleChange(
                        // !state?.active ? "fixed_markup" : "fixed_price",
                        "fixed_price",
                        e.target.value
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextBox
                    label={state?.active ? "Fixed Markup" : "Percentage Markup"}
                    type={"number"}
                    isRequired={true}
                    value={state?.active ? state["fixed_markup"] : state["percentage_markup"]}
                    placeholder={state?.active ? "Fixed Markup" : "Percentage Markup"}
                    onChange={(e) => {
                      handleChange(state?.active ? "fixed_markup" : "percentage_markup", e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextBox
                    label={"Shipping Fee"}
                    type={"number"}
                    isRequired={true}
                    value={state["shipping_fee"]}
                    placeholder={"Shipping Fee"}
                    onChange={(e) => {
                      handleChange("shipping_fee", e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Select
                    value={{
                      label: state?.output ?? "",
                      value: state?.output ?? "",
                    }}
                    onChange={(e, value) =>
                      handleChange("output", value?.value)
                    }
                    options={listofoperators}
                    disablePortal
                    label="Output"
                    placeholder={"Output"}
                    isRequired={true}
                  />
                </Grid>
              </Grid>
              <br />
              Condition :
              <br /> <br /> <Divider />
              <div className={classes.addlist}>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  style={{ padding: "16px 0px" }}
                >
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item xs={3}>
                        <Select
                          value={{ label: "Cost", value: "Cost" }}
                          onChange={(e, value) =>
                            handleChange("Cost", value?.value)
                          }
                          disabled
                          options={[{ label: "Cost", value: "Cost" }]}
                          label="Cost"
                          placeholder={"Cost"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Select
                          value={{ label: "between", value: "between" }}
                          onChange={(e, value) =>
                            handleChange("operator", value?.value)
                          }
                          disabled
                          options={[{ label: "between", value: "between" }]}
                          label="Operator"
                          placeholder={"Operator"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox
                          label={"From"}
                          type={"number"}
                          value={state["constraints"]["Cost"]["from"]}
                          placeholder={"From"}
                          onChange={(e) => {
                            handleAddChild(
                              "constraints",
                              "Cost",
                              "from",
                              e.target.value
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox
                          label={"To"}
                          type={"number"}
                          value={state["constraints"]["Cost"]["to"]}
                          placeholder={"To"}
                          onChange={(e) => {
                            handleAddChild(
                              "constraints",
                              "Cost",
                              "to",
                              e.target.value
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} sm={12}>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item xs={3}>
                        <Select
                          value={{ label: "Size", value: "Size" }}
                          onChange={(e, value) =>
                            handleChange("Carat", value?.value)
                          }
                          disabled
                          options={[{ label: "Size", value: "Size" }]}
                          label="Size"
                          placeholder={"Size"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Select
                          value={{ label: "between", value: "between" }}
                          onChange={(e, value) =>
                            handleChange("operator", value?.value)
                          }
                          disabled
                          options={[{ label: "between", value: "between" }]}
                          label="Operator"
                          placeholder={"Operator"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox
                          label={"From"}
                          type={"number"}
                          value={state["constraints"]["Carat"]["from"]}
                          placeholder={"From"}
                          onChange={(e) => {
                            handleAddChild(
                              "constraints",
                              "Carat",
                              "from",
                              e.target.value
                            );
                          }}
                        />{" "}
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox
                          label={"To"}
                          type={"number"}
                          value={state["constraints"]["Carat"]["to"]}
                          placeholder={"To"}
                          onChange={(e) => {
                            handleAddChild(
                              "constraints",
                              "Carat",
                              "to",
                              e.target.value
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <Button
              variant="outlined"
              className={classes.cancelButton}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              sx={{ background: theme.palette.button.primary }}
              variant="contained"
              onClick={() => handleSave()}
            >
              {vendor_id ? "Update" : "Save"}
            </Button>
          </div>
        </>
      )}

      <DrawerComponent
        open={detailDrawerOpen}
        handleClose={handleDetailDrawerClose}
        children={
          <div className={detailDrawerClasses.drawerRoot}>
            <div className={detailDrawerClasses.titleSection}>
              <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                {openedRule?.rule_name}&nbsp;Details
              </Typography>
              {/* <CloseOutlinedIcon onClick={() => handleDetailDrawerClose()} /> */}
            </div>

            <div className={detailDrawerClasses.drawerBody}>
              <Stack>
                {openedRule?.percentage_markup > 0 && (
                  <>
                    <DetailDrawerItem
                      label="Percentage Markup"
                      value={openedRule?.percentage_markup}
                    />
                    <Divider className={detailDrawerClasses.divider} flexItem />
                  </>
                )}
                {openedRule?.shipping_fee > 0 && (
                  <>
                    <DetailDrawerItem
                      label="Shipping Fee"
                      value={openedRule?.shipping_fee}
                    />
                    <Divider className={detailDrawerClasses.divider} flexItem />
                  </>
                )}

                {(openedRule?.fixed_markup > 0 ||
                  openedRule?.fixed_price > 0) && (
                  <>
                    <DetailDrawerItem
                      label="Fixed Price"
                      value={
                        openedRule?.active
                          ? openedRule?.fixed_markup
                          : openedRule?.fixed_price
                      }
                    />
                    <Divider className={detailDrawerClasses.divider} flexItem />
                  </>
                )}

                {openedRule?.output && (
                  <>
                    <DetailDrawerItem
                      label="Output"
                      value={openedRule?.output}
                    />
                    <Divider className={detailDrawerClasses.divider} flexItem />
                  </>
                )}
                {openedRule?.constraints?.Cost?.from >= 0 && (
                  <>
                    <DetailDrawerItem
                      label="Cost - From"
                      value={openedRule?.constraints?.Cost?.from}
                    />
                    <Divider className={detailDrawerClasses.divider} flexItem />
                  </>
                )}
                {openedRule?.constraints?.Cost?.to >= 0 && (
                  <>
                    <DetailDrawerItem
                      label="Cost - To"
                      value={openedRule?.constraints?.Cost?.to}
                    />
                    <Divider className={detailDrawerClasses.divider} flexItem />
                  </>
                )}

                {openedRule?.constraints?.Carat?.from >= 0 && (
                  <>
                    <DetailDrawerItem
                      label="Size - From"
                      value={openedRule?.constraints?.Carat?.from}
                    />
                    <Divider className={detailDrawerClasses.divider} flexItem />
                  </>
                )}
                {openedRule?.constraints?.Carat?.to >= 0 && (
                  <>
                    <DetailDrawerItem
                      label="Size - To"
                      value={openedRule?.constraints?.Carat?.to}
                    />
                    <Divider className={detailDrawerClasses.divider} flexItem />
                  </>
                )}
              </Stack>
            </div>
          </div>
        }
        anchor={"right"}
      />

      <Snackbar
        open={alert.open}
        message={alert.message}
        ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={4000}
      />
    </div>
  );
};
