import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, ListItemText, Select, Checkbox } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    Label: {
        color: theme.palette.primary.main,
        fontSize: "12px",
        textAlign: 'left',
        "& sup": {
            color: theme.palette.error.main,
        },
    },
    selectbox: {
        [`& fieldset`]: {
            borderRadius: "10px",
            height: 50,
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "11px 14px",
        },
        "& .MuiOutlinedInput-root": {
            padding: 3
        }
    },
}));

export const MultiSelect = (props) => {

    const classes = useStyles(props);

    const getLabel = (props) => {
        return (
            <Typography variant="body1" className={classes.Label} gutterBottom>
                {props.label}
                <sup>*</sup>
                {/* {props?.isrequired && <sup>*</sup>} */}
            </Typography>
        );
    };
    return (
        <div className={classes.root}>

            {getLabel(props)}

            <div className={classes.text}>
                <Select
                    variant="outlined"
                    fullWidth
                    renderValue={(selected) => selected.join(", ")}
                    {...props}
                    label={false}
                    className={classes.selectbox}
                >
                    {props.options.map((option) => (
                        <div key={option} value={option} style={{ display: "flex" }}>
                            <Checkbox checked={props?.value?.indexOf(option) > -1} style={{ marginTop: "-4px" }} />  <ListItemText primary={option} />
                        </div>
                    ))}
                </Select>
            </div>

            {props.isError && (
                <Typography variant={"caption"} color={"error"}>
                    {props.errorMessage}
                </Typography>
            )}

        </div>
    );
};
Select.propTypes = {
    value: PropTypes.object,
    label: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
};
Select.defaultProps = {
    label: "Textbox",
    placeholder: "Type Here...",
};