import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {Button,DialogTitle} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import makeStyles from "@mui/styles/makeStyles";



const useStyles = makeStyles((theme) => ({
  tittle: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    fontWeight:600,
  },
  sumbit:{
    textTransform: 'capitalize'
  }
}));
export const ModalComponent = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.tittle}>
            {" "}
            {props?.Tittle}
            <HighlightOffIcon onClick={props?.handleClose} style={{cursor:'pointer'}}/>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props?.component}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className={classes.sumbit} onClick={props?.handleClose}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
