import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Switch,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";
import {
  TextBox,
  DateTime,
  MobileNumberInputComponent,
} from "../../components";
import { ListContext } from "../../contexts/listcontext";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import UploaderWithPreview from "../../components/uploaderWithPreview";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    margin: "30px 28px 100px",
    backgroundColor: theme.palette.common.white,
    padding: "20px",
  },
  tittle: {
    fontWeight: 600,
    fontSize: "20px",
    marginBottom: "10px",
  },
  sumbit: {
    textTransform: "capitalize",
  },
  btnmain: {
    marginTop: "20px",
  },
  filessection: {
    display: "grid",
    gridTemplateColumns: "88% 12%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
    },
  },
  btnMin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "left",
    },
  },
}));

const initstate = {
  first_name: "",
  mobile: "",
  mobile_no_country_code: "",
  email_id: "",
  // description: "",
  // address1: "",
  // address2: "",
  // city: "",
  // state: "",
  // country_name: "",
  // pin: "",
};

export const EditVendor = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { list, updateState } = useContext(ListContext);

  const vendor_id = location?.state?.vendor_id;

  const [value, setValue] = useState(initstate);

  const [data, setData] = useState({});

  const updateData = (name, key) => {
    setValue({
      ...value,
      [name]: key,
    });
  };

  const Continue = () => {
    // history.push("/schema");
    console.clear("");
    console.log(value);
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    NetworkCall(
      `${config.api_url}vendors/new`,
      "POST",
      { ...value, id: vendor_id },
      {
        // auth_token: "auth_token",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    ).then((res) => {
      alert(JSON.stringify(res))
    }).catch((err) => {})
  };

  useEffect(() => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    NetworkCall(
      `${config.api_url}vendors/get`,
      "POST",
      { vendor_id: vendor_id },
      {
        // auth_token: "auth_token",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        console.log(res.data?.data);
        setData(res.data?.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (data !== {}) {
      let temp_data = {
        first_name: data?.first_name,
        is_active: data?.is_active,
        mobile: data?.mobile,
        mobile_no_country_code: data?.mobile_no_country_code,
        email_id: data?.email_id,
        country_name: data?.country_name,
      };
      setValue(temp_data);
    }
  }, [data]);

  return (
    <div className={classes.root}>
      <Typography className={classes.tittle}>Edit Vendor</Typography>
      <Grid container spacing={2}>
        {/* Vendor ID */}
        <Grid item md={3} xs={12}>
          <TextBox
            label={"Vendor ID"}
            type={"text"}
            disabled
            placeholder={"Vendor ID"}
            value={data?.id}
            onChange={(e) => updateData("id", e.target.value)}
          />
        </Grid>
        {/* Vendor Name */}
        <Grid item md={4} xs={12}>
          <TextBox
            label={"Vendor Name"}
            type={"text"}
            placeholder={"Vendor Name"}
            value={value.first_name}
            onChange={(e) => updateData("first_name", e.target.value)}
          />
        </Grid>
        {/* Contact Number */}
        <Grid item md={4} xs={12}>
          <MobileNumberInputComponent
            label="Contact Number "
            isRequired
            value={{
              mobile: value?.mobile,
              mobile_code: value?.mobile_no_country_code,
            }}
            handleChange={(value) => updateData("mobile", value.mobile)}
            handleDropDown={(value) =>
              setValue("mobile_no_country_code", value.mobile_code)
            }
          />
        </Grid>
        <Grid item md={1} xs={12}>
          Status
          <Switch
            checked={value?.is_active}
            onChange={() => {
              // handleChange("active", !state?.active);
            }}
          />
        </Grid>
        {/* Email */}
        <Grid item md={3} xs={12}>
          <TextBox
            label={"Email Address"}
            type={"email"}
            placeholder={"Email Address"}
            value={value?.email_id}
            onChange={(e) => updateData("email_id", e.target.value)}
          />
        </Grid>

        {/* Created */}
        <Grid item md={4} xs={12}>
          <DateTime
            title={"Created Date"}
            disabled
            value={data?.created_at}
            onChange={(e) => updateState("Created", e)}
          />
        </Grid>
        {/* Last */}
        <Grid item md={4} xs={12}>
          <DateTime
            title={"Last Date"}
            disabled
            value={data?.updated_at}
            onChange={(e) => updateState("Last", e)}
          />
        </Grid>

        {/* Status */}
        {/* <Grid item md={4} xs={12}>
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className={classes.Label}
            >
              Status <sup>*</sup>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="active"
                control={<Radio />}
                label="Active"
                onClick={(e) => updateState("status", "active")}
              />
              <FormControlLabel
                value="inactive"
                control={<Radio />}
                label="Inactive"
                onClick={(e) => updateState("status", "inactive")}
              />
            </RadioGroup>
          </FormControl>
        </Grid> */}
        {/* Created By */}
        <Grid item md={3} xs={12}>
          <TextBox
            label={"Created By"}
            type={"text"}
            placeholder={"Created By"}
            disabled
            value={data?.created_by}
            onChange={(e) => updateState("createdBy", e.target.value)}
          />
        </Grid>

        {/* Updated By */}
        <Grid item md={3} xs={12}>
          <TextBox
            label={"Updated By"}
            type={"text"}
            placeholder={"Updated By"}
            disabled
            value={data?.updated_by}
            onChange={(e) => updateState("upadtedBy", e.target.value)}
          />
        </Grid>

        <Grid item md={5} xs={12}>
          <div className={classes.filessection}>
            <TextBox
              label={"API URL Configure"}
              type={"text"}
              placeholder={"API URL Configure"}
              disabled={list?.file !== ""}
              value={list.API}
              onChange={(e) => updateState("API", e.target.value)}
            />
            <div className={classes.btnMin}>
              <Button
                variant="contained"
                className={classes.sumbit}
                disabled={list?.file !== ""}
              >
                Check
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.filessection}>
            {/* <TextBox
              label={"Upload Sample csv file"}
              type={"file"}
              placeholder={"City"}
              value={list.file}
              disabled={list.API !== ""}
              onChange={(e) => updateState("file", e.target.value)}
            />
            <div className={classes.btnMin}>
              <Button
                variant="contained"
                className={classes.sumbit}
                disabled={list.API !== ""}
              >
                Upload
              </Button>
            </div> */}
            <UploaderWithPreview/>
          </div>
        </Grid>

        <div className={classes.btnmain}>
          <Button
            variant="contained"
            className={classes.sumbit}
            onClick={Continue}
          >
            Update
          </Button>
        </div>
      </Grid>
    </div>
  );
};
