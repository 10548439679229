import React from "react";
// import { AddVendor } from "./addvendor";
import { withNavBars } from "./../../HOCs";
import { ApiConfigure } from "./apiconfigure";
import ListContextProvider from "../../contexts/listcontext";

class ApiConfigureParent extends React.Component {
  render() {
    return (
      <ListContextProvider>
        <ApiConfigure {...this.props} isEdit={true} isStepper={false}/>
      </ListContextProvider>
    );
  }
}

export default withNavBars(ApiConfigureParent);
