import React from "react";
import { TextBox, Select } from "../../../components";
import { useStyles } from "./style";
import {
  Button,
  Typography,
  Grid,
  Snackbar,
  Divider,
  Stack,
  Card,
  useTheme,
  IconButton,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import axios from "axios";
import moment from "moment-timezone";
import { config } from "../../../config";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "../../../assets/deleteIcon";
import { NetworkCall } from "../../../networkcall";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/styles";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FF4D4A" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export const Styledselect = styled(Select)(({ theme }) => ({
  label: {
    color: theme.palette.text.secondary,
  },
  borderRadius: "10px",
  "& .MuiOutlinedInput-input": {
    padding: "7px 14px !important",
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CED3DD !important",
  },
}));

const listdata = [
  { label: "is equal to", value: "is equal to" },
  { label: "is not equal to", value: "is not equal to" },
  { label: "greater than", value: "greater than" },
  { label: "greater than or equal to", value: "greater than or equal to" },
  { label: "less than", value: "less than" },
  { label: "less then or equal to", value: "less then or equal to" },
  { label: "in", value: "in" },
  { label: "not in", value: "not in" },
  { label: "contains", value: "contains" },
  { label: "between", value: "between" },
];

let token = localStorage?.getItem("auth_token")?.toString();
token = token?.substring(1, token.length - 1);

export const AddVendorRule = (props) => {
  const classes = useStyles(props);

  const theme = useTheme();

  const location = useLocation();
  const history = useHistory();

  const [_index, setindex] = React.useState(null);
  const [listofoperators, setlistofoperators] = React.useState([]);
  const [datafilter, setdatafilter] = React.useState([]);

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const [search, setSearch] = React.useState(null);
  const [chooseVerdorIds, setchooseVerdorIds] = React.useState([]);

  const [state, setstate] = React.useState({
    isopen: false,
    addlist: [],
    obj: {
      fact: "",
      operator: "",
      value: [],
      path: "payload",
    },
    reference: false,
    listData: [],
    vendor_id: props?.vendor_id || location?.state?.ID,
    rule_name: "",
    choosevendor: {},
    error: {},
    id: "",
  });
  const [refObj, setRefObj] = React.useState({
    obj: {
      fact: "",
      operator: "",
      value: [""],
      path: "payload",
    },
    error: {},
  });
  const [selectRule, setSelectRule] = React.useState();
  const [ruleOptions, setRuleOptions] = React.useState();
  const [dataRefFilter, setDataRefFilter] = React.useState([]);

  const [current, setCurrent] = React.useState(1);

  const isClearState = async () => {
    await setstate({
      ...state,
      isopen: false,
      addlist: [],
      obj: {
        fact: "",
        operator: "",
        value: [""],
        path: "payload",
      },
      reference: false,
      vendor_id: "",
      rule_name: "",
      choosevendor: {},
      error: {},
    });
    setindex(null);
  };

  const handleDelete = (index) => {
    state.addlist.splice(index, 1);
    setstate({ ...state });
  };

  const handleOperatorDelete = (index) => {
    setstate({
      ...state,
      obj: {
        ...state.obj,
        operator: "",
      },
    });
  };
  const handleRefOperatorDelete = (index) => {
    setRefObj({
      ...refObj,
      obj: {
        ...refObj.obj,
        operator: "",
      },
    });
  };
  const handleAddChild = (index, i) => {
    state.obj?.value?.push("");
    setstate({ ...state });
  };
  const handleRefAddChild = (index, i) => {
    refObj.obj?.value?.push("");
    setRefObj({ ...refObj });
  };
  const handleClear = () => {
    setstate({
      ...state,
      obj: {
        fact: "",
        operator: "",
        value: [""],
        path: "payload",
      },
      error: {},
    });
    setindex(null);
  };

  const handleDeleteChild = (index, i) => {
    state?.obj?.value?.splice(i, 1);
    setstate({ ...state });
  };
  const handleRefDeleteChild = (index, i) => {
    refObj.obj.value.splice(i, 1);
    setRefObj({ ...refObj });
  };
  const updateStateChild = (key, value, index, i) => {
    state.obj[key][i] = value;
    setstate({ ...state });
  };
  const updateRefStateChild = (key, value, index, i) => {
    refObj.obj[key][i] = value;
    setRefObj({ ...refObj });
  };

  const updateState = (key, value) => {
    state.error[key] = false;
    if (key === "operator") {
      state.obj["value"] =
        value === "in" || value === "not in" || value === "between" ? [""] : "";
    }
    if (key === "reference") {
      state[key] = value;
    }
    if (!isNaN(+value) && value) {
      state.obj[key] = JSON.parse(value);
    } else if (typeof value === "object") {
      state.obj[key] = value;
    } else {
      state.obj[key] = value;
    }
    setstate({ ...state });
  };

  const updateRefObj = (key, value) => {
    refObj.error[key] = false;
    if (key === "operator") {
      refObj.obj["value"] =
        value === "in" || value === "not in" || value === "between" ? [""] : "";
    }
    if (key === "reference") {
      refObj[key] = value;
    }
    if (!isNaN(+value) && value) {
      refObj.obj[key] = JSON.parse(value);
    } else if (typeof value === "object") {
      refObj.obj[key] = value;
    } else {
      refObj.obj[key] = value;
    }
    setRefObj({ ...refObj });
  };

  const handleAdd = () => {
    setstate({ ...state });
    console.clear();

    if (!state.obj.operator) {
      // state.error.operator = true;
      setAlert({
        open: true,
        message: "Please select Operator",
        backgroundColor: "#FF3232",
      });
      return;
    } else if (!state.obj.fact) {
      // state.error.fact = true;
      setAlert({
        open: true,
        message: "Please select Column Name",
        backgroundColor: "#FF3232",
      });
      return;
    } else if (state.obj.value.length === 0) {
      setAlert({
        open: true,
        message: "Please select Value",
        backgroundColor: "#FF3232",
      });
      return;
    }
    if (state?.reference) {
      if (!refObj.obj.operator) {
        setAlert({
          open: true,
          message: "Please select Operator",
          backgroundColor: "#FF3232",
        });
        return;
      } else if (!refObj.obj.fact) {
        setAlert({
          open: true,
          message: "Please select Column Name",
          backgroundColor: "#FF3232",
        });
        return;
      } else if (refObj.obj.value.length === 0) {
        setAlert({
          open: true,
          message: "Please select Value",
          backgroundColor: "#FF3232",
        });
        return;
      }
    }
    if (_index !== null && _index >= 0) {
      if (state.obj.operator === "between") {
        let val = state?.obj?.value;
        let valObj = {
          from: val[1] ?? "",
          to: val[0] ?? "",
        };

        state.obj.value = valObj;
      }
      if (state.reference) {
        state.obj["reference"] = { ...refObj.obj };
      }

      state.addlist[_index] = { ...state.obj };
      state.addlist[_index] = { ...state.obj };
    } else {
      if (state.obj.operator === "between") {
        let val = state?.obj?.value;
        let valObj = {
          from: val[0],
          to: val[1],
        };
        state.obj.value = valObj;
      }
      if (state.reference) {
        state.obj["reference"] = { ...refObj.obj };
      }
      state.addlist.push({ ...state.obj });
    }

    state.obj = {
      fact: "",
      operator: "",
      value: [""],
      path: "payload",
    };
    state.reference = false;
    setstate({ ...state });
    refObj.obj = {
      fact: "",
      operator: "",
      value: [""],
      path: "payload",
    };
    setRefObj({ ...refObj });
    setindex(null);
  };

  const handleClose = async () => {
    // setstate({
    //   ...state,
    //   isopen: !state.isopen,
    // });
    // isGetList();
    // await isClearState();
    history.goBack(-1);
  };

  React.useEffect(() => {
    if ((!props.isStepper || props?.vendor_id) && selectRule) {
      const api_url =
        config.api_url +
        `rules/vendor/get?vendor_id=${
          props?.vendor_id || location?.state?.ID
        }&id=${selectRule}`;

      let token = localStorage.getItem("auth_token").toString();
      token = token.substring(1, token.length - 1);

      new Promise((resolve, reject) => {
        axios
          .get(`${api_url}`, { headers: { Authorization: `Bearer ${token}` } })
          .then((response) => {
            if (response?.data?.rules_data) {
              setstate({
                ...state,
                id: response?.data?.id,
                vendor_id: props?.vendor_id || location?.state?.ID,
                rule_name: response?.data?.rule_name,
                addlist:
                  response?.data?.rules_data?.all?.length > 0
                    ? response?.data?.rules_data?.all
                    : [
                        {
                          fact: "",
                          operator: "",
                          value: [{ key: "", value: "" }],
                          path: "payload",
                          // fact: "",
                          // operator: "",
                          // value: [],
                          // path: "payload",
                        },
                      ],
              });
            }
            resolve(response?.data);
          })
          .catch((err) => {
            setAlert({
              open: true,
              message: "Something Went Wrong",
              backgroundColor: "#FF3232",
            });
          });
      });
    }
  }, [selectRule]);

  const isGetList = async () => {
    const api_url = config.api_url + "rules/vendor/get/all";

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    await axios
      .get(`${api_url}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        const data = response.data?.rows?.map((v, i) => {
          if (v?.id) {
            return {
              ...v,
              last_modifyed: moment(v?.updated_at).format("YYYY-MM-DD"),
              created_at: moment(v?.created_at).format("YYYY-MM-DD"),
            };
          }
        });
        const vedor_id = data?.map((v) => {
          return {
            value: v?.vendor_id ?? "",
            label: v?.vendor?.first_name ?? "",
          };
        });
        setchooseVerdorIds(vedor_id ?? []);
      });
  };

  React.useEffect(() => {
    isGetList();
    let id = props?.vendor_id || location?.state?.ID;
    id && getOperatot(id);
  }, []);

  React.useEffect(() => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    NetworkCall(
      `${config.api_url}rules/get/all?vendor_id=${
        props?.vendor_id || location?.state?.ID
      }`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        let filter_rules = res?.data?.filter_rules?.map((x) => {
          return { label: x.rule_name, value: x.id };
        });
        setRuleOptions(filter_rules);
        setSelectRule(filter_rules?.[0].value);
      })
      .catch((err) => {});
  }, [props?.vendor_id]);

  React.useEffect(() => {
    state?.obj?.fact && getValueData();
  }, [state?.obj?.fact]);
  React.useEffect(() => {
    refObj?.obj?.fact && getValueRefData();
  }, [refObj?.obj?.fact]);
  const getOperatot = async (id) => {
    const api_url = config.api_url + "feed/unqiue/header/get";
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    await axios
      .post(
        `${api_url}`,
        {
          vendor_id: id || "",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response) {
          const data = response?.data?.headers;
          var arr = [];
          data?.map((v) => {
            return arr.push({ value: v, label: v });
          });
        }
        setlistofoperators(arr ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getValueData = async () => {
    let id = state?.vendor_id || state?.choosevendor?.value || "";
    await axios
      .post(
        `${config.api_url + "feed/unqiue/data/get/column"}`,
        {
          vendor_id: id || "",
          column: state?.obj?.fact ?? "",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response) {
          setdatafilter(response?.data?.column_data ?? []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getValueRefData = async () => {
    let id = state?.vendor_id || state?.choosevendor?.value || "";
    await axios
      .post(
        `${config.api_url + "feed/unqiue/data/get/column"}`,
        {
          vendor_id: id || "",
          column: refObj?.obj?.fact ?? "",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response) {
          setDataRefFilter(response?.data?.column_data ?? []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isValidation = () => {
    if (state.vendor_id) {
      if (!state?.rule_name) {
        state.error["rule_name"] = true;
      }
    } else {
      if (!state?.choosevendor?.value) {
        state.error["choosevendor"] = true;
      }
      if (!state?.rule_name) {
        state.error["rule_name"] = true;
      }
    }
    setstate({
      ...state,
    });
  };

  const handleSave = async () => {
    await isValidation();
    if (state?.error?.rule_name || state?.error?.choosevendor) {
      return;
    }
    const api_url = config.api_url + "rules/vendor/upsert";

    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    let payload = {
      rule_name: state?.rule_name ?? "",
      vendor_id: state?.vendor_id || state?.choosevendor?.value || "",
      rules_data: {
        all: state.addlist ?? [],
      },
    };
    if (state?.vendor_id || state?.choosevendor?.value) {
      payload.rule_id = state?.id;
    }

    new Promise((resolve, reject) => {
      axios
        .post(`${api_url}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setAlert({
            open: true,
            message: `Rule has been ${
              state?.vendor_id ? "updated" : "added"
            } successfully`,
            backgroundColor: "#4BB543",
          });
          props?.isStepper ? props.onSave(vendor_id) : history.goBack(-1);
          // if (state?.vendor_id) {
          //   handleClose();
          // }
          // isGetList();
          resolve(true);
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Something Went Wrong",
            backgroundColor: "#FF3232",
          });
        });
    });
  };

  const handleChange = (key, v) => {
    state[key] = v;

    if (key === "choosevendor") {
      getOperatot(v?.value ?? "");
    }
    state.error = { ...state.error, choosevendor: false };
    setstate({
      ...state,
    });
  };

  const { addlist, listData, vendor_id, rule_name } = state;

  const isFilterList = search
    ? listData?.filter(
        (v) => v?.rule_name?.toLowerCase()?.includes(search?.toLowerCase()) && v
      )
    : listData;

  const handleEdit = (d, i) => {
    if (d?.operator === "between") {
      d.value = Object.values(d?.value);
    }
    setindex(i);
    state.obj = JSON.parse(JSON.stringify(d));
    state.reference = false;
    if (d?.reference) {
      state.reference = true;

      refObj.obj = JSON.parse(JSON.stringify(d?.reference));
      setRefObj({ ...refObj });
    }
    setstate({ ...state });
  };

  const handleForward = () => {
    let len = ruleOptions.length;
    let current = 0;
    for (let z = 0; z < len; z++) {
      if (ruleOptions[z].value === selectRule) {
        current = z;
      }
    }

    if (current + 1 >= len) {
      current = 0;
    } else {
      current = current + 1;
    }
    setCurrent(current);
    setSelectRule(ruleOptions[current]?.value);
  };

  const handleBackward = () => {
    let len = ruleOptions.length;
    let current = 0;
    for (let z = 0; z < len; z++) {
      if (ruleOptions[z].value === selectRule) {
        current = z;
      }
    }

    if (current - 1 == 0) {
      current = 0;
    } else if (current - 1 < 0) {
      current = len - 1;
    } else {
      current = current - 1;
    }

    setCurrent(current);
    setSelectRule(ruleOptions[current]?.value);
  };

  return (
    <>
      {!props.isStepper && (
        <div className={classes.titlesection}>
          <ArrowBackIcon className="icon" onClick={() => history.goBack(-1)} />
          <Typography variant="subtitle2">Edit Vendor Rule</Typography>
        </div>
      )}
      <div
        className={classes.root}
        // style={{ background: state.isopen && "#fff" }}
      >
        <div className={classes.rootBody}>
          {
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={8}
                style={{ borderRight: "1px solid #eee" }}
              >
                <div className={classes.addruleDrawer}>
                  {/* {!props?.isStepper && (
                  <div className={classes.titleSection}>
                    <Typography variant="subtitle2">
                      {vendor_id ? "Edit" : "Add New"} Vendor Rule
                    </Typography>
                  </div>
                )} */}

                  <div className={classes.addruleDrawerBody}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextBox
                          label={"Rule Name"}
                          type={"text"}
                          value={rule_name}
                          isError={state?.error?.rule_name}
                          errorMessage={
                            state?.error?.rule_name &&
                            "Please enter the rule name"
                          }
                          placeholder={"Rule Name"}
                          onChange={(e) =>
                            setstate({
                              ...state,
                              rule_name: e.target.value,
                              error: { ...state.error, rule_name: false },
                            })
                          }
                        />
                      </Grid>

                      {chooseVerdorIds?.filter(
                        (v) => v.value === vendor_id
                      )?.[0]?.label && (
                        <Grid item xs={12}>
                          {vendor_id ? (
                            <TextBox
                              label={`Vendor ${
                                chooseVerdorIds?.filter(
                                  (v) => v.value === vendor_id
                                )?.[0]?.label
                                  ? `Name`
                                  : `Id`
                              }`}
                              type={"text"}
                              value={
                                chooseVerdorIds?.filter(
                                  (v) => v.value === vendor_id
                                )?.[0]?.label || vendor_id
                              }
                              disabled
                              placeholder={"Vendor"}
                            />
                          ) : (
                            <Select
                              disablePortal
                              options={chooseVerdorIds}
                              label="Choose Vendor"
                              placeholder="Choose Vendor"
                              isError={state?.error?.choosevendor}
                              errorMessage={
                                state?.error?.choosevendor &&
                                "Please choose the vendor"
                              }
                              onChange={(e, value) =>
                                handleChange("choosevendor", value)
                              }
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                    <br />
                    Condition :
                    <br /> <br /> <Divider />
                    <div className={classes.addlist}>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        style={{
                          padding: "16px 0px",
                          borderBottom: "1px solid #bdbdbd96",
                        }}
                      >
                        <Grid item xs={6} sm={4}>
                          <Select
                            disablePortal
                            value={{
                              label: state?.obj?.fact ?? "",
                              value: state?.obj?.fact ?? "",
                            }}
                            onChange={(e, value) => {
                              updateState("fact", value?.value, _index);
                            }}
                            isError={state?.error?.fact}
                            errorMessage={
                              state?.error?.fact && "Please choose Column"
                            }
                            disabled={
                              !state?.choosevendor?.value && !state?.vendor_id
                            }
                            options={listofoperators}
                            label="Column Name"
                            placeholder="Column Name"
                          />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                          <Select
                            value={{
                              label: state?.obj?.operator ?? "",
                              value: state?.obj?.operator ?? "",
                            }}
                            onChange={(e, value) =>
                              updateState("operator", value?.value, _index)
                            }
                            isError={state?.error?.operator}
                            errorMessage={
                              state?.error?.operator && "Please enter Operator"
                            }
                            disablePortal
                            options={listdata}
                            label="Operator"
                            placeholder={"Operator"}
                          />
                        </Grid>

                        {(state?.obj?.operator === "in" ||
                          state?.obj?.operator === "not in") && (
                          <Grid item xs={1} sm={1}>
                            <div
                              style={{
                                border: "1px solid red",
                                padding: "6px 3px 0px",
                                borderRadius: "4px",
                                marginTop: "23px",
                                width: "fit-content",
                              }}
                            >
                              <DeleteIcon
                                style={{
                                  fontSize: "1rem",
                                  color: "#ff4d4a",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleOperatorDelete(_index)}
                              />
                            </div>
                          </Grid>
                        )}

                        {(state?.obj?.operator === "in" ||
                          state?.obj?.operator === "not in" ||
                          state?.obj?.operator === "between") &&
                          state?.obj?.value &&
                          state?.obj?.value?.map((child, i) => {
                            return (
                              <Grid
                                item
                                container
                                spacing={2}
                                xs={5}
                                sm={12}
                                alignItems={"center"}
                              >
                                <Grid item xs={5} sm={4}>
                                  {state?.obj?.operator === "between" ? (
                                    <TextBox
                                      type={"text"}
                                      value={child ?? ""}
                                      isError={state?.error?.value}
                                      errorMessage={
                                        state?.error?.value &&
                                        "Please select Value"
                                      }
                                      label="Value"
                                      placeholder={"Value"}
                                      disabled={!state?.obj?.fact}
                                      onChange={(e) =>
                                        updateStateChild(
                                          "value",
                                          e.target.value,
                                          _index,
                                          i
                                        )
                                      }
                                    />
                                  ) : (
                                    <Select
                                      value={{
                                        label: child ?? "",
                                        value: child ?? "",
                                      }}
                                      onChange={(e, value) =>
                                        updateStateChild(
                                          "value",
                                          value?.value,
                                          _index,
                                          i
                                        )
                                      }
                                      disabled={!state?.obj?.fact}
                                      options={
                                        datafilter
                                          ? datafilter?.map((v) => {
                                              return { value: v, label: v };
                                            })
                                          : []
                                      }
                                      isError={state?.error?.value}
                                      errorMessage={
                                        state?.error?.value &&
                                        "Please select Value"
                                      }
                                      label="Value"
                                      placeholder={"Value"}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={2} sm={3}>
                                  <div style={{ display: "flex" }}>
                                    <DeleteIcon
                                      style={{
                                        color: "#ff4d4a",
                                        fontSize: "1rem",
                                        cursor: "pointer",
                                        marginTop: 25,
                                      }}
                                      onClick={() =>
                                        handleDeleteChild(_index, i)
                                      }
                                    />
                                    {state?.obj?.value?.length - 1 === i && (
                                      <AddOutlinedIcon
                                        style={{
                                          color: "#231535",
                                          cursor: "pointer",
                                          marginTop: 20,
                                        }}
                                        onClick={() =>
                                          handleAddChild(_index, i)
                                        }
                                      />
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            );
                          })}

                        {(state?.obj?.operator !== "in" ||
                          state?.obj?.operator !== "not in" ||
                          state?.obj?.operator === "between") && (
                          <Grid item xs={5} sm={3}>
                            {state?.obj?.operator === "in" ||
                            state?.obj?.operator === "not in" ||
                            state?.obj?.operator === "between" ? (
                              <></>
                            ) : (
                              <Select
                                value={{
                                  label: state?.obj?.value ?? "",
                                  value: state?.obj?.value ?? "",
                                }}
                                onChange={(e, value) =>
                                  updateState("value", value?.value, _index)
                                }
                                disabled={!state?.obj?.fact}
                                options={
                                  state?.obj?.fact && datafilter
                                    ? datafilter?.map((v) => {
                                        return { value: v, label: v };
                                      })
                                    : []
                                }
                                label="Value"
                                isError={state?.error?.value}
                                errorMessage={
                                  state?.error?.value && "Please select Value"
                                }
                                placeholder={"Value"}
                              />
                            )}
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                          <span>Reference : </span>
                          <IOSSwitch
                            sx={{ m: 1 }}
                            defaultChecked
                            checked={state?.reference}
                            onChange={(e) =>
                              updateState("reference", e.target.checked, _index)
                            }
                          />
                        </Grid>

                        {state?.reference && (
                          <>
                            <Grid item xs={6} sm={4}>
                              <Select
                                disablePortal
                                value={{
                                  label: refObj?.obj?.fact ?? "",
                                  value: refObj?.obj?.fact ?? "",
                                }}
                                onChange={(e, value) =>
                                  updateRefObj("fact", value?.value, _index)
                                }
                                isError={refObj?.error?.fact}
                                errorMessage={
                                  refObj?.error?.fact && "Please choose Column"
                                }
                                disabled={
                                  !state?.choosevendor?.value &&
                                  !state?.vendor_id
                                }
                                options={listofoperators}
                                label="Column Name"
                                placeholder="Column Name"
                              />
                            </Grid>

                            <Grid item xs={6} sm={4}>
                              <Select
                                value={{
                                  label: refObj?.obj?.operator ?? "",
                                  value: refObj?.obj?.operator ?? "",
                                }}
                                onChange={(e, value) =>
                                  updateRefObj("operator", value?.value, _index)
                                }
                                isError={refObj?.error?.operator}
                                errorMessage={
                                  refObj?.error?.operator &&
                                  "Please enter Operator"
                                }
                                disablePortal
                                options={listdata}
                                label="Operator"
                                placeholder={"Operator"}
                              />
                            </Grid>

                            {(refObj?.obj?.operator === "in" ||
                              refObj?.obj?.operator === "not in") && (
                              <Grid item xs={1} sm={1}>
                                <div
                                  style={{
                                    border: "1px solid red",
                                    padding: "6px 3px 0px",
                                    borderRadius: "4px",
                                    marginTop: "23px",
                                    width: "fit-content",
                                  }}
                                >
                                  <DeleteIcon
                                    style={{
                                      color: "#ff4d4a",
                                      cursor: "pointer",
                                      fontSize: "1rem",
                                    }}
                                    onClick={() =>
                                      handleRefOperatorDelete(_index)
                                    }
                                  />
                                </div>
                              </Grid>
                            )}

                            {(refObj?.obj?.operator === "in" ||
                              refObj?.obj?.operator === "not in" ||
                              refObj?.obj?.operator === "between") &&
                              refObj?.obj?.value &&
                              refObj?.obj?.value?.map((child, i) => {
                                return (
                                  <Grid
                                    item
                                    container
                                    spacing={2}
                                    xs={5}
                                    sm={12}
                                    alignItems={"center"}
                                  >
                                    <Grid item xs={5} sm={4}>
                                      {refObj?.obj?.operator === "between" ? (
                                        <TextBox
                                          type={"text"}
                                          value={child ?? ""}
                                          isError={refObj?.error?.value}
                                          errorMessage={
                                            refObj?.error?.value &&
                                            "Please select Value"
                                          }
                                          label="Value"
                                          placeholder={"Value"}
                                          disabled={!refObj?.obj?.fact}
                                          onChange={(e) =>
                                            updateRefStateChild(
                                              "value",
                                              e.target.value,
                                              _index,
                                              i
                                            )
                                          }
                                        />
                                      ) : (
                                        <Select
                                          value={{
                                            label: child ?? "",
                                            value: child ?? "",
                                          }}
                                          onChange={(e, value) =>
                                            updateRefStateChild(
                                              "value",
                                              value?.value,
                                              _index,
                                              i
                                            )
                                          }
                                          disabled={!refObj?.obj?.fact}
                                          options={
                                            dataRefFilter
                                              ? dataRefFilter?.map((v) => {
                                                  return { value: v, label: v };
                                                })
                                              : []
                                          }
                                          label="Value"
                                          placeholder={"Value"}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={2} sm={3}>
                                      <div style={{ display: "flex" }}>
                                        <DeleteIcon
                                          style={{
                                            color: "#ff4d4a",
                                            cursor: "pointer",
                                            marginTop: 22,
                                            fontSize: 17,
                                          }}
                                          onClick={() =>
                                            handleRefDeleteChild(_index, i)
                                          }
                                        />
                                        {refObj?.obj?.value?.length - 1 ===
                                          i && (
                                          <AddOutlinedIcon
                                            style={{
                                              color: "#231535",
                                              cursor: "pointer",
                                              marginTop: 20,
                                            }}
                                            onClick={() =>
                                              handleRefAddChild(_index, i)
                                            }
                                          />
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                );
                              })}

                            {(refObj?.obj?.operator !== "in" ||
                              refObj?.obj?.operator !== "not in" ||
                              refObj?.obj?.operator === "between") && (
                              <Grid item xs={5} sm={3}>
                                {refObj?.obj?.operator === "in" ||
                                refObj?.obj?.operator === "not in" ||
                                refObj?.obj?.operator === "between" ? (
                                  <></>
                                ) : (
                                  <Select
                                    value={{
                                      label: refObj?.obj?.value ?? "",
                                      value: refObj?.obj?.value ?? "",
                                    }}
                                    onChange={(e, value) =>
                                      updateRefObj(
                                        "value",
                                        value?.value,
                                        _index
                                      )
                                    }
                                    disabled={!refObj?.obj?.fact}
                                    options={
                                      refObj?.obj?.fact && dataRefFilter
                                        ? dataRefFilter?.map((v) => {
                                            return { value: v, label: v };
                                          })
                                        : []
                                    }
                                    label="Value"
                                    placeholder={"Value"}
                                  />
                                )}
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                      <br />
                      <Grid container xs={12} justifyContent={"center"}>
                        <Button
                          variant="contained"
                          sx={{ background: theme.palette.button.primary }}
                          onClick={() => handleAdd()}
                        >
                          {_index ? "UPDATE" : "ADD"}
                        </Button>
                      </Grid>
                    </div>
                    {/* <div
                      className={classes.footer}
                    >
                      <Button
                        variant="outlined"
                        onClick={() =>
                          props?.isStepper
                            ? props.onSave(vendor_id)
                            : handleClose()
                        }
                        className={classes.cancelButton}
                      >
                        {props?.isStepper ? `Skip` : `Cancel`}
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        variant="contained"
                        sx={{ background: theme.palette.button.primary }}
                        onClick={() => handleSave()}
                      >
                        {vendor_id
                          ? props?.isStepper
                            ? "Save & Continue"
                            : "Update"
                          : "Save & Continue"}
                      </Button>
                    </div> */}
                  </div>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                style={{ borderRight: "1px solid #eee" }}
              >
                <div
                  style={{ background: "#fff" }}
                  className={classes.addruleDrawer}
                >
                  {ruleOptions && (
                    <div
                      className={classes.titleSection}
                      style={{ borderBottom: "1px solid #ced3dd" }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: 1 }}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{ fontSize: 16 }}
                        >
                          &nbsp;
                          {`${state.rule_name} (${
                            ruleOptions?.length != 0
                              ? `${current == 0 ? current + 1 : current} / ${
                                  ruleOptions?.length
                                }`
                              : `0`
                          })`}
                        </Typography>
                        {!props.isStepper && (
                          <div>
                            <IconButton onClick={() => handleBackward()}>
                              <ArrowLeftOutlinedIcon />{" "}
                            </IconButton>{" "}
                            <IconButton onClick={() => handleForward()}>
                              <ArrowRightOutlinedIcon />
                            </IconButton>
                          </div>
                        )}
                      </Stack>
                      {/* <Select
                        value={selectRule}
                        onChange={(e, value) =>{
                          setSelectRule(value?.value)
                        }}
                        // disabled={!state?.obj?.fact}
                        options={ ruleOptions}
                        label="Vendor Rules"
                        placeholder={"Value"}
                      /> */}
                    </div>
                  )}

                  {addlist?.length > 0 && (
                    <Typography
                      style={{
                        margin: "0px 16px 0px",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      Condition :
                    </Typography>
                  )}

                  {!addlist?.length > 0 && (
                    <div
                      style={{
                        height: "395px",
                        margin: "auto",
                        display: "flex",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        <img src="../images/box@2x.png" />
                        <br />
                        No Conditions Added
                      </Typography>
                    </div>
                  )}

                  <div
                    className={classes.addruleDrawerBody1}
                    style={{ height: addlist?.length > 0 ? "50vh" : 0 }}
                  >
                    {addlist?.map((_item, _index) => {
                      return (
                        <div
                          className={classes.addruleDrawer}
                          style={{ marginBottom: 6 }}
                        >
                          <div style={{ height: "auto" }}>
                            <Card className={classes.card}>
                              <div className={classes.count}>{_index + 1}</div>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography className="title">
                                    Column Name{" "}
                                    <div className="body">
                                      {_item?.fact ?? ""}
                                    </div>
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography className="title">
                                    Operator{" "}
                                    <div className="body">
                                      {_item?.operator ?? ""}
                                    </div>
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <div
                                    style={{
                                      display: "flex",
                                      margin: "4px -8px",
                                    }}
                                  >
                                    <EditOutlinedIcon
                                      style={{ color: "#8863FB", fontSize: 21 }}
                                      onClick={() => handleEdit(_item, _index)}
                                    />
                                    <DeleteIcon
                                      style={{ color: "#FF4D4A", fontSize: 17 }}
                                      onClick={() => handleDelete(_index)}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    className="title"
                                    style={{ marginTop: 6 }}
                                  >
                                    Value
                                    <div className="body">
                                      {_item?.operator === "in" ||
                                      _item?.operator === "not in" ||
                                      Array.isArray(_item?.value) ? (
                                        <>
                                          {_item?.value &&
                                            _item?.value?.map((child, i) => {
                                              return i ===
                                                _item?.value?.length - 1
                                                ? child
                                                : child + ", ";
                                            })}
                                        </>
                                      ) : _item?.operator === "between" ? (
                                        <>{`${_item?.value?.from ?? ""}, ${
                                          _item?.value?.to ?? ""
                                        }`}</>
                                      ) : _item?.operator === "same value" ? (
                                        <>{"same value"}</>
                                      ) : (
                                        <>{_item?.value ?? ""}</>
                                      )}
                                    </div>
                                  </Typography>
                                </Grid>
                                {_item?.reference?.operator &&
                                  _item?.reference?.operator !== "" && (
                                    <Grid item xs={6}>
                                      <Typography
                                        className="title"
                                        style={{ marginTop: 6 }}
                                      >
                                        Reference Operator
                                        <div className="body">
                                          {_item?.reference?.operator ?? ""}
                                        </div>
                                      </Typography>
                                    </Grid>
                                  )}
                                {_item?.reference?.operator &&
                                  _item?.reference?.operator !== "" && (
                                    <Grid item xs={6}>
                                      <Typography
                                        className="title"
                                        style={{ marginTop: 6 }}
                                      >
                                        Reference Value
                                        <div className="body">
                                          {_item?.reference?.operator ===
                                            "in" ||
                                          _item?.reference?.operator ===
                                            "not in" ||
                                          Array.isArray(
                                            _item?.reference?.value
                                          ) ? (
                                            <>
                                              {_item?.reference?.value &&
                                                _item?.reference?.value?.map(
                                                  (child, i) => {
                                                    return i ===
                                                      _item?.reference?.value
                                                        ?.length -
                                                        1
                                                      ? child
                                                      : child + ", ";
                                                  }
                                                )}
                                            </>
                                          ) : _item?.reference?.operator ===
                                            "between" ? (
                                            <>{`${
                                              _item?.reference?.value?.from ??
                                              ""
                                            }, ${
                                              _item?.reference?.value?.to ?? ""
                                            }`}</>
                                          ) : _item?.reference?.operator ===
                                            "same value" ? (
                                            <>{"same value"}</>
                                          ) : (
                                            <>{_item?.reference?.value ?? ""}</>
                                          )}
                                        </div>
                                      </Typography>
                                    </Grid>
                                  )}
                              </Grid>
                            </Card>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
              <div
                      className={classes.footer}
                    >
                      <Button
                        variant="outlined"
                        onClick={() =>
                          props?.isStepper
                            ? props.onSave(vendor_id)
                            : handleClose()
                        }
                        className={classes.cancelButton}
                      >
                        {props?.isStepper ? `Skip` : `Cancel`}
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        variant="contained"
                        sx={{ background: theme.palette.button.primary }}
                        onClick={() => handleSave()}
                      >
                        {vendor_id
                          ? props?.isStepper
                            ? "Save & Continue"
                            : "Update"
                          : "Save & Continue"}
                      </Button>
                    </div>
            </Grid>
          }

          <Snackbar
            open={alert.open}
            message={alert.message}
            ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setAlert({ ...alert, open: false })}
            autoHideDuration={4000}
          />
        </div>
      </div>
    </>
  );
};
