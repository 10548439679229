import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/Person";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@mui/styles";

const useVendorItemStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateAreas: `
            "icon data"
            "icon data"
        `,
    gridTemplateColumns: "24px 1fr",
    gridGap: 16,
  },
  icon: {
    gridArea: "icon",
  },
  data: {
    gridArea: "data",
    rowGap: 12,
  },
  label: {
    color: "#848484",
    fontSize: "14px",
  },
  value: {
    color: "#231535",
    fontSize: "16px",
    fontWeight: 500,
  },
}));

export function VendorItem({ loading, icon, label, value }) {
  const classes = useVendorItemStyles();
  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        {icon ? <img src={icon} alt={label} width="30px" /> : <PersonIcon />}
      </div>
      <Stack className={classes.data}>
        <Typography className={classes.label}>{label}</Typography>
        {loading ? (
          <Skeleton variant="text" />
        ) : (
          <Typography className={classes.value}>{value}</Typography>
        )}
      </Stack>
    </div>
  );
}

const useVendorRowStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontSize: "14px",
  },
  viewButton: {
    textTransform: "unset",
    lineHeight: 1,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export function VendorRow({ loading, label, onViewClick }) {
  const classes = useVendorRowStyles();
  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems={"center"}
    >
      <Typography className={classes.label}>{label}</Typography>
      {loading ? (
        <Skeleton variant="text" width={"70px"} />
      ) : (
        <Button
          className={classes.viewButton}
          color="primary"
          disableRipple
          // endIcon={<ChevronRightIcon />}
          onClick={onViewClick}
        >
          View <ChevronRightIcon />
        </Button>
      )}
    </Stack>
  );
}
