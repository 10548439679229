import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Select,
  MenuItem,
  OutlinedInput,
  IconButton,
  Stack,
  Snackbar,
  TextField,
} from "@mui/material";
import { TextBox, MobileNumberInputComponent } from "../../components";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";
import { ListContext } from "../../contexts/listcontext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SimpleSelect } from "../../components/mobile/select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { feedResponseAs, NetworkMethodsOptions } from "../../utils";
import { styled } from "@mui/styles";
import { KeyboardBackspace } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { useTheme } from "@mui/material/styles";
import UploaderWithPreview from "../../components/uploaderWithPreview";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploaderFile from "../../components/uploaderWithPreview/uploadFile";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    margin: "6px 28px 30px",
    backgroundColor: theme.palette.common.white,
    padding: "10px",
  },
  footer: {
    borderTop: "1px solid #eeeeee",
    bottom: 96,
    right: 0,
    display: "flex",

    justifyContent: "flex-end",

    overflow: "hidden",
    padding: 10,
    background: "#fff",
    // position: "absolute",
    width: "96%",
    left: "24px",
    borderRadius: 6,
  },
  tittle: {
    fontWeight: 600,
    fontSize: "20px",
    marginBottom: "10px",
  },
  sumbit: {
    // textTransform: "capitalize",
  },
  btnmain: {
    marginTop: "4px",
    // display: "flex",
  },
  filessection: {
    display: "flex",
    // padding: "0px 80px",
    flexDirection: "column",
    gridTemplateColumns: "88% 12%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
    },
  },
  btnMin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    // marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "left",
    },
  },
  cancelButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    borderColor: "#84848466 !important",
    "& :hover": {
      color: theme.palette.secondary.main,
      borderColor: "#84848466 !important",
    },
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    margin: "20px 20px 14px 20px",
    // align-items: center;
    justifyContent: "left",
    paddingLeft: 10,
    // borderBottom: "1px solid #ced3dd",
    "& .MuiTypography-subtitle2": {
      fontWeight: 600,
      fontSize: 16,
    },
    "& svg": {
      cursor: "pointer",
    },
    height: 46,
    maxHeight: 46,
    marginBottom: 16,
    "& .icon": {
      background: "#ffffff",
      boxShadow: "#d9d9d97a 0px 20px 25px",
      borderRadius: 50,
      padding: 4,
      width: "30px",
      height: "30px",
      marginRight: 8,
    },
  },
}));

const StyledLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: "12px",
  // color: "#231535",
  marginBottom: "0.35em",
  "& .css-u0qe18-MuiFormLabel-root .Mui-focused": {
    color: "#00000099 !important",
  },
}));
export const Styledselect = styled(Select)(({ theme }) => ({
  // fontSize: "12px",
  // color:"#231535",
  // marginBottom:"0.35em",
  label: {
    color: theme.palette.text.secondary,
  },
  borderRadius: "10px",
  "& .MuiOutlinedInput-input": {
    padding: "7px 14px !important",
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  // [`& fieldset`]: {
  //   "& :hover": {
  //     border: "0px solid #5078E1",
  //   },
  // },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CED3DD !important",
  },
}));

export const ApiConfigure = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  //   const { list, updateState } = useContext(ListContext);

  const [feedType, setFeedType] = useState("");
  const [feedAPI, setfeedAPI] = useState("");
  const [networkMethods, setNetworkMethods] = useState("");
  const [feedResponse, setFeedResponse] = useState("");
  const [arraykey, setArraykey] = useState("");
  const [uploadedFile, setUploadedFile] = useState();
  const [feed_id, setFeedId] = useState();
  const [isFetched, setIsFetched] = useState(false);

  const [header, setHeader] = useState()
  const [body, setBody] = useState()

  const [sourceType, setSourceType] = useState("");

  // const [vendor_options, setVendorOptions] = useState([])
  // const [selected_vendor, setSelectedVendor] = useState()

  const [sftp_host, setSftpHost] = useState("");
  const [sftp_private_key, setSftpPrivateKey] = useState("");
  const [sftp_username, setSftpUsername] = useState("");
  const [sftp_password, setSftpPassword] = useState("");
  const [sftp_file_location, setSftpFileLocation] = useState("");
  const [sftp_filename, setSftpFileName] = useState("");
  const [sftp_source_type, setSftpSourceType] = useState("SFTP");

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    backgroundColor: "",
  });

  const [valid, setValid] = useState(null);

  const vendor_id = props?.vendor_id || location.state?.vendor_id;
  const backurl = location.state?.Location;
  // alert(vendor_id)

  const isJSON = (item) => {
    try {
      let parsedJSON = JSON.parse(item);
      return parsedJSON;
    } catch (error) {
      return null;
    }
  }

  const validation = () => {
    if (feedType === "API") {
      if (
        feedAPI === "" ||
        networkMethods === "" ||
        feedResponse === "" ||
        arraykey === "" || isJSON(header) === null || isJSON(body) === null
      ) {
        return false;
      } else {
          return true;
      }
    } else if (feedType === "FILE") {
      if (
        uploadedFile === null ||
        uploadedFile === undefined ||
        uploadedFile === ""
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        sftp_host === "" ||
        // sftp_source_type === "" ||
        // sftp_private_key === "" ||
        sftp_username === "" ||
        // sftp_password === "" ||
        sftp_file_location === "" ||
        sftp_filename === ""
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const Continue = () => {
    {
      let token = localStorage.getItem("auth_token").toString();
      token = token.substring(1, token.length - 1);
      if (feedType === undefined) {
        setAlert({
          open: true,
          message: "Please select a feed source to continue",
          backgroundColor: "#FF3232",
        });
      } else {
        if (feedType === "API") {
          // API Feed
          if (validation()) {

            NetworkCall(
              `${config.api_url}feed/source/upsert`,
              "POST",
              {
                vendor_id: vendor_id,
                source_type: "API",
                file_name: "",
                headers: header ? isJSON(header) : null,
                body: body ? isJSON(body) : null,
                sheet_name: "",
                url: feedAPI,
                method: networkMethods,
                response_as: feedResponse,
                response_array_key: arraykey,
                paginated: false,
                startTxt: "",
                endTxt: "",
              },
              {
                // auth_token: "auth_token",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              }
            )
              .then(() => {
                // history.push("/schema", { vendor_id: vendor_id });
                setAlert({
                  open: true,
                  message: `Vendor API ${
                    props.isEdit || isFetched ? `updated` : `created`
                  }  successfully`,
                  backgroundColor: "#4BB543",
                });
                setTimeout(() => {
                  props?.isStepper
                    ? props?.onSave(vendor_id, {
                        vendor_id: vendor_id,
                        source_type: "API",
                        file_name: "",
                        sheet_name: "",
                        url: feedAPI,
                        method: networkMethods,
                        response_as: feedResponse,
                        response_array_key: arraykey,
                        paginated: false,
                        startTxt: "",
                        endTxt: "",
                      })
                    : history.goBack(-1);
                }, 2000);
              })
              .catch((err) => {
                setAlert({
                  open: true,
                  message: "Something went wrong. Please try again later. 1",
                  backgroundColor: "#FF3232",
                });
              });
          } else {
            if(isJSON(header) === null || isJSON(body) === null )
            {
              setAlert({
                open: true,
                message: `${(isJSON(header) === null && isJSON(body) === null) 
                    ? `Header and Body` 
                    : (isJSON(header) === null && isJSON(body) !== null) ? `Header` 
                    : (isJSON(header) !== null && isJSON(body) === null) ? 'Body' : 'Header & Body'} should be in JSON format`,
                backgroundColor: "#FF3232"
              })
            }
            else{
              setAlert({
                open: true,
                message: "Please fill the mandatory field",
                backgroundColor: "#FF3232",
              });
            }
          }
        } else if (feedType === "FILE") {
          // File Feed
          // console.log(uploadedFile)
          if (
            validation() &&
            (uploadedFile?.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              uploadedFile.type === "text/csv")
          ) {
            NetworkCall(
              `${config.api_url}feed/source/upsert`,
              "POST",
              {
                vendor_id: vendor_id,
                source_type: "FILE",
                file_name: uploadedFile?.name,
                sheet_name: "",
                url: "",
                method: "",
                response_as: "",
                response_array_key: "",
                paginated: false,
                startTxt: "",
                endTxt: "",
              },
              {
                // auth_token: "auth_token",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              }
            )
              .then((res) => {
                console.clear();

                var formData = new FormData();
                formData.append("feed_file", uploadedFile);
                formData.append("vendor_id", vendor_id);
                formData.append(
                  "feed_source_id",
                  res?.data?.feed_source || feed_id
                );
                formData.append(
                  "is_csv",
                  uploadedFile.type === "text/csv" ? true : false
                );
                formData.append("file_name", uploadedFile?.name);
                formData.append("sheet_name", "");
                console.log(formData);
                NetworkCall(
                  `${config.api_url}feed/file/upload`,
                  "POST",
                  formData,
                  {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                  }
                )
                  .then((response) => {
                    // console.log(response)
                    // history.push("/schema", { vendor_id: vendor_id });
                    props.isStepper
                      ? props.onSave(vendor_id, formData)
                      : history.goBack(-1);
                  })
                  .catch((error) => {});
              })
              .catch((err) => {});
          } else {
            setAlert({
              open: true,
              message:
                uploadedFile?.type !==
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                uploadedFile.type !== "text/csv"
                  ? "Please Upload Excel or CSV file alone"
                  : "Please upload  a file to continue",
              backgroundColor: "#FF3232",
            });
          }
        } else {
          if (validation()) {
            console.clear();
            console.log(sftp_private_key);

            var formData = new FormData();
            // formData.append("feed_file", uploadedFile);
            formData.append("vendor_id", vendor_id);
            formData.append("source_type", "SFTP");
            formData.append("sftp_host", sftp_host);
            formData.append("sftp_username", sftp_username);
            formData.append("sftp_password", sftp_password);
            if (sftp_private_key) {
              formData.append("sftp_private_key", sftp_private_key);
            }
            formData.append("sftp_file_location", sftp_file_location);
            formData.append("sftp_filename", sftp_filename);

            console.log(formData);

            NetworkCall(`${config.api_url}feed/sftp/upsert`, "POST", formData, {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            })
              .then((response) => {
                props.isStepper
                  ? props.onSave(vendor_id, formData)
                  : history.goBack(-1);
              })
              .catch((error) => {});
          } else {
            setAlert({
              open: true,
              message: "Please fill the mandatory field",
              backgroundColor: "#FF3232",
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (props?.isEdit || vendor_id) {
      let token = localStorage.getItem("auth_token").toString();
      token = token.substring(1, token.length - 1);
      NetworkCall(
        `${config.api_url}feed/source/get`,
        "POST",
        { vendor_id: vendor_id },
        {
          // auth_token: "auth_token",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      )
        .then((res) => {
          console.log(res.data?.data);
          setFeedType(res.data?.data?.source_type);
          setFeedResponse(res.data?.data?.response_as);
          setArraykey(res?.data?.data?.response_array_key);
          setNetworkMethods(res.data?.data?.method);
          setfeedAPI(res?.data?.data?.url);
          setUploadedFile(res?.data?.data?.file_url);
          setFeedId(res?.data?.data?.id);
          setIsFetched(true);
          setHeader(JSON.stringify(res.data?.data?.headers))
          setBody(JSON.stringify(res.data?.data?.body))

          setSftpHost(res?.data?.data?.sftp_host);
          // setSftpPrivateKey(res?.data?.data?.sftp_private_key)
          setSftpUsername(res?.data?.data?.sftp_username);
          setSftpPassword(res?.data?.data?.sftp_password);
          setSftpFileLocation(res?.data?.data?.sftp_file_location);
          setSftpFileName(res?.data?.data?.sftp_filename);
          setSftpSourceType(res?.data?.data?.source_type);
          // setfeedAPI(res.data?.data?.)
          // setNValue(res.data?.data);
        })
        .catch((err) => {});
    } else {
      setFeedType("API");
    }
  }, []);

  const Skip = () => {
    if (backurl) {
      history.push(backurl, { vendor_id: vendor_id, state: { IndexMenu: 1 } });
    } else {
      // history.push("/schema", { vendor_id: vendor_id });
      props.onSave(vendor_id);
    }
  };

  const checkURL = (url) => {
    if (networkMethods === "") {
      setAlert({
        open: true,
        message: "Please select a Network Call Methods to check",
        backgroundColor: "#FF3232",
      });
    } else {
      NetworkCall(url, networkMethods, {}, {})
        .then((res) => {
          console.log(res?.data);
          setValid(true);
        })
        .catch((err) => {
          setValid(false);
        });
    }
  };

  const back = () => {
    history.goBack(-1);
  };

  return (
    <>
      {!props.isStepper && (
        <div className={classes.titleSection}>
          <ArrowBackIcon className="icon" onClick={() => back()} />
          <Typography variant="subtitle2">Configure Source Feed</Typography>
        </div>
      )}
      <div className={classes.root} style={{ borderRadius: 8 }}>
        {/* {!props.isStepper && (
          <Typography className={classes.tittle}>
            <IconButton onClick={back}>
              <KeyboardBackspace />
            </IconButton>
            Configure Source Feed{" "}
          </Typography>
        )} */}

        <Grid
          container
          spacing={3}
          style={{ padding: !props?.isStepper ? "30px 40px" : 0 }}
        >
          <Grid item md={12}>
            <FormLabel>Feed Source</FormLabel>
            <RadioGroup
              row
              defaultValue={feedType}
              value={feedType}
              sx={{ gap: 3 }}
            >
              <FormControlLabel
                onClick={(e) => {
                  setFeedType("API");
                }}
                value="API"
                control={<Radio sx={{ fontSize: "1rem !important" }} />}
                label={`API`}
              />
              <FormControlLabel
                onClick={(e) => {
                  setFeedType("FILE");
                }}
                value="FILE"
                control={<Radio sx={{ fontSize: "1rem" }} />}
                label={`File`}
              />
              <FormControlLabel
                onClick={(e) => {
                  setFeedType("SFTP");
                }}
                value="SFTP"
                control={<Radio sx={{ fontSize: "1rem" }} />}
                label={`SFTP`}
              />
            </RadioGroup>
          </Grid>

          {/* Description */}
          <Grid item md={12}>
            <Grid container spacing={3}>
              {/* API Url */}

              <Grid item md={12} xs={12}>
                {feedType === "API" && (
                  <>
                    {/* <div className={classes.filessection}> */}
                    <Grid container gap={2}>
                      <Grid item xs={12} md={6}>
                        <TextBox
                          label={"API URL Configure"}
                          type={"text"}
                          placeholder={"API URL Configure"}
                          value={feedAPI}
                          isRequired
                          //   disabled={list?.file !== ""}
                          //   value={list.API}
                          onChange={(e, index) =>
                            // updateState("API", e.target.value, index)
                            {
                              setValid(null);
                              setfeedAPI(e.target.value);
                            }
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <StyledLabel>
                            Network Call Methods
                            <sup style={{ color: theme.palette.error.main }}>
                              *
                            </sup>
                          </StyledLabel>
                          <Styledselect
                            sx={{ padding: "0px !important" }}
                            // labelId="demo-simple-select-label"
                            placeholder={`Network Call Methods`}
                            value={networkMethods}
                            required={true}
                            label={`Network Call Methods`}
                            input={
                              <OutlinedInput
                                sx={{ padding: "0px !important" }}
                              />
                            }
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(e) => setNetworkMethods(e.target.value)}
                          >
                            {NetworkMethodsOptions?.map((x) => {
                              return (
                                <MenuItem value={x.value}>{x.label}</MenuItem>
                              );
                            })}
                          </Styledselect>
                        </FormControl>
                      </Grid>
                      
                      <Grid item xs={12} md={1}>
                        <div className={classes.btnMin}>
                          {valid !== null ? (
                            <>
                              {valid ? (
                                <CheckCircleIcon color={"success"} />
                              ) : (
                                <CancelOutlinedIcon color={"error"} />
                              )}
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              fullWidth
                              className={classes.sumbit}
                              sx={{
                                background: theme.palette.button.primary,
                                width: "100%",
                              }}
                              onClick={() => checkURL(feedAPI)}
                              // disabled={list?.file !== ""}
                            >
                              Check
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    {/* </div> */}
                    <Grid container gap={2} sx={{marginTop: 3}}>
                    <Grid item xs={12} md={6}>
                        <TextBox 
                          label={"Headers"}
                          placeholder={'{"name":"John", "age":30, "city":"New York"}'}
                          multiline 
                          rows={3}
                          value={header}
                          onChange={(e) => setHeader(e.target.value) }
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <TextBox 
                          label={"Body"}
                          placeholder={'{"name":"John", "age":30, "city":"New York"}'}
                          multiline 
                          rows={3}
                          value={body}
                          onChange={(e) => setBody(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container gap={2} sx={{ marginTop: 3 }}>
                      <Grid item md={6}>
                        {/* <FormLabel>Response Array Key</FormLabel> */}
                        <FormControl fullWidth>
                          <TextBox
                            label={"Response Array Key"}
                            type={"text"}
                            isRequired
                            placeholder={"Key"}
                            value={arraykey}
                            onChange={(e, index) => setArraykey(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={5}>
                        <FormControl fullWidth>
                          <StyledLabel>
                            Feed Response As{" "}
                            <sup style={{ color: theme.palette.error.main }}>
                              *
                            </sup>
                          </StyledLabel>
                          <Styledselect
                            sx={{ padding: "0px !important" }}
                            // labelId="demo-simple-select-label"
                            placeholder={`Network Call Methods`}
                            value={feedResponse}
                            required={true}
                            label={`Network Call Methods`}
                            input={
                              <OutlinedInput
                                sx={{ padding: "0px !important" }}
                              />
                            }
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(e) => setFeedResponse(e.target.value)}
                          >
                            {/* <MenuItem disabled value="">
                            <em>Network Call Methods</em>
                          </MenuItem> */}

                            {feedResponseAs?.map((x) => {
                              return (
                                <MenuItem value={x.value}>{x.label}</MenuItem>
                              );
                            })}
                          </Styledselect>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}
                {feedType === "FILE" && (
                  <div className={classes.filessection}>
                    {/* <StyledLabel>
                    Upload sample CSV File{" "}
                    <sup style={{ color: theme.palette.error.main }}>
                      *
                    </sup>
                  </StyledLabel> */}
                    <UploaderWithPreview
                      // label={"id_proof"}
                      // handleFile={(value)=> handleUploadedFile(value)}
                      handleFile={(e) => setUploadedFile(e)}
                      handleRemove={() => {}}
                      label={
                        <StyledLabel>
                          Upload CSV (or) Excel File{" "}
                          <sup style={{ color: theme.palette.error.main }}>
                            *
                          </sup>
                        </StyledLabel>
                      }
                      value={""}
                      customExtensions={
                        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      }
                      file={null}
                      fileUrl={uploadedFile}
                      // fileLink={uploadedFile}
                      isEdit={props.isStepper ? true : false}
                      // fileUrl={""}
                      idData={""}
                      parentID={"id"}
                    />
                  </div>
                )}
                {feedType === "SFTP" && (
                  <Grid container gap={1}>
                    {/* <Grid item xs={12} md={3}>
                      <TextBox
                        label={"Source Type"}
                        type={"text"}
                        placeholder={"Source Type"}
                        value={sftp_source_type}
                        isRequired
                        disabled={true}
                        onChange={(e, index) => {
                          setValid(null);
                          setSftpSourceType(e.target.value);
                        }}
                      />
                    </Grid> */}
                    <Grid item xs={12} md={5}>
                      <TextBox
                        label={"SFTP Host"}
                        type={"text"}
                        placeholder={"SFTP Host"}
                        value={sftp_host}
                        isRequired
                        onChange={(e, index) => {
                          setValid(null);
                          setSftpHost(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextBox
                        label={"SFTP Username"}
                        type={"text"}
                        placeholder={"SFTP Username"}
                        value={sftp_username}
                        isRequired
                        onChange={(e, index) => {
                          setValid(null);
                          setSftpUsername(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextBox
                        label={"SFTP Password"}
                        type={"text"}
                        placeholder={"SFTP Password"}
                        value={sftp_password}
                        // isRequired
                        onChange={(e, index) => {
                          setValid(null);
                          setSftpPassword(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <UploaderFile
                        // label={"id_proof"}
                        // handleFile={(value)=> handleUploadedFile(value)}
                        handleFile={(e) => setSftpPrivateKey(e)}
                        handleRemove={() => {}}
                        label={<StyledLabel>SFTP Private Key </StyledLabel>}
                        value={""}
                        customExtensions={""}
                        file={null}
                        fileUrl={uploadedFile}
                        // fileLink={uploadedFile}
                        isEdit={props.isStepper ? true : false}
                        // fileUrl={""}
                        idData={""}
                        parentID={"id"}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextBox
                        label={"SFTP File Location"}
                        type={"text"}
                        placeholder={"SFTP Location"}
                        value={sftp_file_location}
                        isRequired
                        onChange={(e, index) => {
                          setValid(null);
                          setSftpFileLocation(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextBox
                        label={"SFTP File Name"}
                        type={"text"}
                        placeholder={"SFTP Name"}
                        value={sftp_filename}
                        isRequired
                        onChange={(e, index) => {
                          setValid(null);
                          setSftpFileName(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* City */}

              <Grid item md={12} xs={12}>
                {/* {feedType === "FILE" && (
                <div className={classes.filessection}>
                  <StyledLabel>
                    Upload sample CSV File{" "}
                    <sup style={{ color: theme.palette.error.main }}>
                      *
                    </sup>
                  </StyledLabel>
                  <UploaderWithPreview
                    label={"id_proof"}
                    // handleFile={(value)=> handleUploadedFile(value)}
                    handleFile={(e) => setUploadedFile(e)}
                    handleRemove={() => {}}
                    value={""}
                    customExtensions={""}
                    file={""}
                    fileUrl={
                       ""
                    }
                    // fileUrl={""}
                    idData={
                      ""
                    }
                    parentID={"id"}
                  />
                </div>
              )} */}
              </Grid>
            </Grid>

            <div className={classes.footer}>
              {/* <Stack gap={2} direction={"row"} justifyContent={"flex-end"}> */}
              <Button
                variant="outlined"
                className={`${classes.sumbit} ${classes.cancelButton}`}
                onClick={back}
              >
                {`Cancel`}
              </Button>{" "}
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                className={classes.sumbit}
                onClick={Continue}
                sx={{ background: theme.palette.button.primary }}
              >
                Save & Continue
              </Button>
              &nbsp;
              {/* </Stack> */}
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={alert.open}
          message={alert.message}
          ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setAlert({ ...alert, open: false })}
          autoHideDuration={4000}
        />
      </div>
      {/* <div className={classes.btnmain}>
        <Stack gap={2} direction={"row"} justifyContent={"flex-end"}>
          <Button
            variant="outlined"
            className={`${classes.sumbit} ${classes.cancelButton}`}
            onClick={props?.isStepper ? () => {props.onSave(vendor_id)} :back}
          >
            {props?.isStepper ? `Skip` : `Cancel`}
          </Button>
          <Button
            variant="contained"
            className={classes.sumbit}
            onClick={Continue}
            sx={{ background: theme.palette.button.primary }}
          >
            Save & Continue
          </Button>
        </Stack>
      </div> */}
    </>
  );
};
