import React from "react";
import Popover from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { makeStyles } from "@mui/styles";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
const useStyles = makeStyles((theme) => ({
  popover: {
    "& .MuiPopover-paper": {
      border: "1px solid #eeeeee",
      boxShadow: "0px 0px 10px 3px #efefef4d",
      borderRadius: 8,
    },
  },
  item: {
    padding: "6px 10px",
    borderBottom: "1px solid #eeeeee",
    color: "#231535",
    fontSize: 14,
    "& svg": {
      fontSize: 17,
      marginRight: 6,
    },
  },
}));

function WithActionMenu(WrappedComponent) {
  return function WithActionMenu({
    handleEdit,
    handleClone,
    handleDelete,
    ...props
  }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const onEditMenuClick = () => {
      handleEdit();
      handleClose();
    };
    const onCloneMenuClick = () => {
      handleClone();
      handleClose();
    };
    const onDeleteMenuClick = () => {
      handleDelete();
      handleClose();
    };

    return (
      <React.Fragment>
        <WrappedComponent handleMenuOpen={handleOpen} {...props} />
        <Popover
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.popover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuList style={{ padding: 0 }}>
            {!props.showViewMore && (
              <MenuItem onClick={onCloneMenuClick} className={classes.item}>
                <RemoveRedEyeIcon />
                View
              </MenuItem>
            )}
            <MenuItem onClick={onEditMenuClick} className={classes.item}>
              <EditOutlinedIcon />
              Edit
            </MenuItem>
            {/* <MenuItem onClick={onCloneMenuClick} className={classes.item}>
              <FileCopyOutlinedIcon />
              Clone
            </MenuItem> */}
            <MenuItem onClick={onDeleteMenuClick} className={classes.item}>
              <DeleteOutlineOutlinedIcon />
              Delete
            </MenuItem>
          </MenuList>
        </Popover>
      </React.Fragment>
    );
  };
}

export default WithActionMenu;
