import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Tabs, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";
import { withNavBars } from "./../../HOCs";
import ManageRoles from "./ManageRoles";
import ManageRoleAccess from "./ManageRolesAccess";
import ManageUsers from "./ManageUsers";

const useStyles = makeStyles((theme) => ({
    tabPanel:{
        color: theme.palette.common.darkgrey,
        textTransform: "none",
        "& :active":{
            color: `${theme.palette.secondary.main} !important`
        },
        selected:{
            color: `${theme.palette.secondary.main} !important`
        }
    },
    
}))

const UserManagementParent = () => {
    const classes = useStyles();
    const location = useLocation()
    console.log(location?.state)
  const [value, setValue] = React.useState(location.state?.data || "0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TabContext value={value}>
        <TabList sx={{background: "white", gap:5}} onChange={handleChange}>
          <Tab className={classes.tabPanel} value={"0"} label={"Manage Users"} />
          <Tab className={classes.tabPanel} value={"1"} label={"Master Roles"} />
          {/* <Tab className={classes.tabPanel} value={"2"} label={"Role Access"} /> */}
        </TabList>
        <TabPanel value={"0"} index={0}>
          <ManageUsers/>
        </TabPanel>
        <TabPanel value={"1"} index={1}>
          <ManageRoles/>
        </TabPanel>
        {/* <TabPanel value={"2"} index={2}>
          <ManageRoleAccess/>
        </TabPanel> */}
      </TabContext>
    </>
  );
};

export default withNavBars(UserManagementParent);
