import React, { useState } from "react";
import { TextBox, Select } from "../../../components";
import { useStyles } from "../style";
import { Button, Typography, Grid, Snackbar, Card } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTheme } from "@emotion/react";
import { withNavBars } from "../../../HOCs";
import DeleteIcon from "../../../assets/deleteIcon";
import { NetworkCall } from "../../../networkcall";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useHistory, useParams } from "react-router-dom";
import { config } from "../../../config";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const listdata = [
  { label: "same value", value: "same value" },
  { label: "is equal to", value: "is equal to" },
  { label: "is not equal to", value: "is not equal to" },
  { label: "greater than", value: "greater than" },
  { label: "greater than or equal to", value: "greater than or equal to" },
  { label: "less than", value: "less than" },
  { label: "less then or equal to", value: "less then or equal to" },
  { label: "in", value: "in" },
  { label: "not in", value: "not in" },
  { label: "contains", value: "contains" },
  { label: "between", value: "between" },
];

const UpdateParingLogic = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const theme = useTheme();
  const params = useParams();
  const [allCondition, setAllCondition] = useState([]);
  const [values, setValues] = useState([""]);
  const [state, setState] = React.useState({
    id: "",
    rule_name: "",
    condition: "",
    operator: "",
    index: "",
  });
  const [RuleData, setRuleData] = React.useState([]);
  const [isUpdate, setUpdate] = React.useState(false);
  const [indexAll, setIndexAll] = React.useState("");

  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });
  React.useEffect(() => {
    GetAllData();
    GetRuleData();
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    history.push({
      state: {
        IndexMenu: 7,
      },
    });
    // eslint-disable-next-line
  }, []);
  const GetAllData = async () => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    await NetworkCall(
      `${config.api_url}pairing/columns/get/all`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        console.log(res.data);
        const data = res?.data?.columns?.map((v) => {
          return {
            value: v,
            label: v,
          };
        });
        setAllCondition(data ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const GetRuleData = async () => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    await NetworkCall(
      `${config.api_url}pairing/rules/get?rule_id=${params.id}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        setRuleData(res?.data?.rules_data?.all ?? []);
        let val = {
          id: res?.data?.id ?? "",
          rule_name: res?.data?.rule_name ?? "",
        };
        setState({ ...state, ...val });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateState = (name, value) => {
    if (name === "operator" && value === "between") {
      setValues(["", ""]);
    } else if (name === "operator") {
      setValues([""]);
    }
    setState({ ...state, [name]: value });
  };
  const handleOperatorDelete = (index) => {
    let initialValue = [...values];
    initialValue.splice(index, 1);
    setValues(initialValue);
  };
  const updateValue = (value, index) => {
    let initialValue = [...values];
    initialValue[index] = value;
    setValues(initialValue);
  };
  const handleAddChild = () => {
    setValues([...values, ""]);
  };
  const handleEdit = (data, index) => {
    if (data.operator === "between") {
      setValues([data.value.from ?? "", data.value.to ?? ""]);
    } else if (data.operator === "in" || data.operator === "not in") {
      setValues([...data.value]);
    } else {
      setValues([...data.value]);
    }
    setUpdate(true);
    setIndexAll(index);
    setState({ ...state, condition: data.fact, operator: data.operator });
  };

  const handleUpdate = () => {
    if (isValidCheck()) {
      let allRuleData = RuleData;
      let data = {};
      if (state?.operator === "between") {
        data = {
          fact: state?.condition ?? "",
          path: "payload",
          operator: state?.operator,
          value: {
            from: values[0] ?? "",
            to: values[1] ?? "",
          },
        };
      } else if (state?.operator === "in" || state?.operator === "not in") {
        data = {
          fact: state?.condition ?? "",
          path: "payload",
          operator: state?.operator,
          value: [...values],
        };
      } else {
        data = {
          fact: state?.condition ?? "",
          path: "payload",
          operator: state?.operator,
          value: values[0],
        };
      }
      if (isUpdate) {
        allRuleData[indexAll] = data;
        setRuleData(allRuleData);
      } else {
        setRuleData([...RuleData, data]);
      }
      emptyState();
    }
  };
  const handleDeleteData = (data, index) => {
    let allRule = [...RuleData];
    allRule.splice(index, 1);
    setRuleData(allRule);
  };
  const emptyState = () => {
    setState({
      id: state.id,
      rule_name: state.rule_name,
      condition: "",
      operator: "",
    });

    setValues([""]);
    setUpdate("");
    setIndexAll(false);
  };

  const AlertValidCheck = (name) => {
    setAlert({
      open: true,
      message: `${name} is required`,
      backgroundColor: "#FF3232",
      time: 1000,
    });
    return false;
  };
  const isValidCheck = () => {
    if (state.rule_name.length === 0) {
      return AlertValidCheck("Name");
    }
    if (state.condition.length === 0) {
      return AlertValidCheck("Condition");
    }
    if (state.operator.length === 0) {
      return AlertValidCheck("Operator");
    }
    if (state.operator === "same value") {
      return true;
    }
    if (values.includes("")) {
      return AlertValidCheck("Value");
    }
    return true;
  };
  const handleSubmit = async () => {
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);

    let payload = {
      rule_id: state.id,
      rule_name: state.rule_name,
      rules_data: {
        all: RuleData,
      },
    };

    await NetworkCall(
      `${config.api_url}pairing/rules/upsert`,
      "POST",
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        setAlert({
          open: true,
          message: res?.data?.message ?? "",
          backgroundColor: " #4BB543",
        });
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Something went wrong!",

          backgroundColor: "#FF3232",
        });
        console.log(err);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootBody}>
        <>
          <div className={classes.titleSection}>
            <ArrowBackIcon
              className="icon"
              onClick={() => history.push("/paring")}
            />
            <Typography variant="subtitle2">Edit Paring Logic</Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} style={{ borderRight: "1px solid #eee" }}>
              <div className={classes.addruleDrawer}>
                <div className={classes.addruleDrawerBody}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextBox
                        label={"Paring Logic name"}
                        type={"text"}
                        value={state.rule_name}
                        placeholder={"Paring Logic name"}
                        onChange={(e) =>
                          updateState("rule_name", e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <br /> <br />
                  Condition :
                  <br />
                  <div className={classes.RuleData}>
                    <Grid
                      container
                      spacing={2}
                      alignItems={"center"}
                      style={{
                        padding: "16px 0px",
                      }}
                    >
                      <Grid item xs={6} sm={4}>
                        <Select
                          style={{ width: "100%" }}
                          disablePortal
                          value={{
                            label: state?.condition ?? "",
                            value: state?.condition ?? "",
                          }}
                          onChange={(e, value) =>
                            updateState("condition", value?.value)
                          }
                          options={allCondition ?? []}
                          label="Column Name"
                          placeholder="Column Name"
                        />
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Select
                          style={{ width: "100%" }}
                          disablePortal
                          value={{
                            label: state?.operator ?? "",
                            value: state?.operator ?? "",
                          }}
                          onChange={(e, value) =>
                            updateState("operator", value?.value)
                          }
                          options={listdata ?? []}
                          label="Operator"
                          placeholder="Operator"
                        />
                      </Grid>
                      {values &&
                        values?.map((val, index) => (
                          <Grid
                            item
                            xs={6}
                            sm={3}
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "center",
                            }}
                          >
                            <TextBox
                              label={`Value ${index + 1}`}
                              type={"text"}
                              value={val ?? ""}
                              placeholder={`Value ${index + 1}`}
                              onChange={(e) =>
                                updateValue(e.target.value, index)
                              }
                              disabled={
                                state?.operator === "same value" ? true : false
                              }
                            />

                            {(state?.operator === "in" ||
                              state?.operator === "not in") &&
                              index > 0 && (
                                <Grid
                                  style={{
                                    padding: "6px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <DeleteIcon
                                    style={{
                                      color: "#ff4d4a",
                                      cursor: "pointer",
                                      fontSize: "1rem",
                                    }}
                                    onClick={() => handleOperatorDelete(index)}
                                  />
                                </Grid>
                              )}
                          </Grid>
                        ))}
                      {(state?.operator === "in" ||
                        state?.operator === "not in") && (
                        <Grid
                          item
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <AddCircleOutlineIcon
                            style={{
                              color: "#231535",
                              cursor: "pointer",
                              marginTop: 20,
                            }}
                            onClick={() => handleAddChild()}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <br /> <br />
                    <Grid container xs={12} justifyContent={"center"}>
                      <Button
                        sx={{ background: theme.palette.button.primary }}
                        variant="contained"
                        onClick={handleUpdate}
                      >
                        {isUpdate ? "UPDATE" : "ADD"}
                      </Button>
                      &nbsp;
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={4} style={{ borderRight: "1px solid #eee" }}>
              <div
                style={{ background: "#fff" }}
                className={classes.addruleDrawer}
              >
                {RuleData?.length > 0 && (
                  <div
                    className={classes.titleSection}
                    style={{ borderBottom: "1px solid #ced3dd" }}
                  >
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      &nbsp;{state.rule_name}
                    </Typography>
                  </div>
                )}

                {RuleData?.length > 0 && (
                  <Typography
                    style={{
                      margin: "0px 16px 0px",
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    Added Conditions
                  </Typography>
                )}

                {!RuleData?.length > 0 && (
                  <div
                    style={{
                      height: "64vh",
                      margin: "auto",
                      display: "flex",
                    }}
                  >
                    <Typography style={{ textAlign: "center", margin: "auto" }}>
                      <img src="../images/box@2x.png" alt="logo" />
                      <br />
                      No Conditions Added
                    </Typography>
                  </div>
                )}

                {RuleData?.length > 0 && (
                  <div className={classes.addruleDrawerBody1}>
                    {RuleData?.map((_item, _index) => {
                      return (
                        <div
                          className={classes.addruleDrawer}
                          style={{ marginBottom: 6 }}
                        >
                          <div style={{ height: "auto" }}>
                            <Card className={classes.card}>
                              <div className={classes.count}>{_index + 1}</div>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography className="title">
                                    Column Name{" "}
                                    <div className="body">
                                      {_item?.fact ?? ""}
                                    </div>
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography className="title">
                                    Operator{" "}
                                    <div className="body">
                                      {_item?.operator ?? ""}
                                    </div>
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <div
                                    style={{
                                      display: "flex",
                                      margin: "4px -8px",
                                    }}
                                  >
                                    <EditOutlinedIcon
                                      style={{
                                        color: "#8863FB",
                                        fontSize: 21,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleEdit(_item, _index)}
                                    />
                                    <DeleteOutlineIcon
                                      style={{
                                        color: "#FF4D4A",
                                        fontSize: 21,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleDeleteData(_item, _index)
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    className="title"
                                    style={{ marginTop: 6 }}
                                  >
                                    Value
                                    <div className="body">
                                      {_item?.operator === "in" ||
                                      _item?.operator === "not in" ? (
                                        <>
                                          {_item?.value &&
                                            _item?.value?.map((child, i) => {
                                              return i ===
                                                _item?.value?.length - 1
                                                ? child
                                                : child + ", ";
                                            })}
                                        </>
                                      ) : _item?.operator === "between" ? (
                                        <>{`${_item?.value?.from ?? ""}, ${
                                          _item?.value?.to ?? ""
                                        }`}</>
                                      ) : _item?.operator === "same value" ? (
                                        <>{"same value"}</>
                                      ) : (
                                        <>{_item?.value ?? ""}</>
                                      )}
                                    </div>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Card>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid
            xs={8}
            style={{
              padding: "28px",
              marginTop: "12px",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "flex-end",
              borderRadius: "6px",
            }}
          >
            <Button
              className={classes.cancelButton}
              variant="outlined"
              onClick={() => history.push("/paring")}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              sx={{ background: theme.palette.button.primary }}
              variant="contained"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Grid>
        </>
      </div>
      <Snackbar
        open={alert.open}
        message={alert.message}
        ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={4000}
      />
    </div>
  );
};

export default withNavBars(UpdateParingLogic);
