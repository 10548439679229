import * as React from "react"

const FolderIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={65.862}
    height={52.69}
    {...props}
  >
    <path
      data-name="Path 96714"
      d="M59.276 6.586H29.638L23.052 0H6.586A6.606 6.606 0 0 0 0 6.586v13.173h65.862v-6.587a6.606 6.606 0 0 0-6.586-6.586Z"
      fill="#8863fb"
    />
    <path
      data-name="Path 96715"
      d="M59.276 6.586H6.586A6.606 6.606 0 0 0 0 13.172v32.931a6.606 6.606 0 0 0 6.586 6.583h52.69a6.606 6.606 0 0 0 6.586-6.586V13.172a6.606 6.606 0 0 0-6.586-6.586Z"
      fill="#8863fb"
      opacity={0.5}
    />
  </svg>
)

export default FolderIcon