import React from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  Skeleton,
  Pagination,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment-timezone";
import { styled } from "@mui/material/styles";

// Styles
// style section for cell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8863FB1F",
    color: theme.palette.common.black,
    paddingLeft: 25,
    fontSize: 14,
    // textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    paddingLeft: 25,
    color: "#848484",
    opacity: "100%",
    // textAlign: "center",
  },
}));
// style section for row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "none",
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    fontSize: 13,
  },
}));

export function TabelComponent(props) {
  const { state, loading, page, setPage, count, search } = props;
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    backgroundColor: "",
  });
  // Handle Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const DownloadUrl = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = url.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const DownloadCsv = async (id, vendor_id) => {
    setAlert({
      open: true,
      message: "Download Started Successfully!",
      backgroundColor: "#4BB543",
    });
    let token = localStorage.getItem("auth_token").toString();
    token = token.substring(1, token.length - 1);
    await NetworkCall(
      `${config.api_url}files/get/error/download?vendor_id=${vendor_id}&id=${id}`,
      "GET",
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    )
      .then((res) => {
        DownloadUrl(res?.data?.file_url);
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Something Went Wrong!",
          backgroundColor: "#FF3232",
        });
        console.log(err);
      });
  };

  // Listing Data
  const listrender = () => {
    let data = state;

    if (data?.length === 0) {
      return (
        <StyledTableRow>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell>
            <Typography>{`- found for '${search}'`}</Typography>
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </StyledTableRow>
      );
    } else {
      return data.map((val, index) => {
        return (
          <StyledTableRow key={index}>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              {val?.vendor?.first_name ?? "-"}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              {val?.scheduled_meta?.schedule_name ?? "-"}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              {/* {val?.scheduled_meta?.cron_time ?? "-"} */}
              {val?.created_at
                ? moment(val?.created_at).format("DD MMM YYYY - HH:mm:SS a ")
                : "-"}
            </StyledTableCell>

            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              {val?.error_message ?? "-"}
            </StyledTableCell>
            <StyledTableCell
              component="th"
              scope="row"
              sx={{
                textAlign: `left !important`,
              }}
            >
              <Button onClick={() => DownloadCsv(val?.id, val?.vendor?.id)}>
                Download
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        );
      });
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "0px 12px 53px rgb(29 31 114 / 7%)",
        border: "1px solid #00000012",
      }}
    >
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table" size="medium">
          <TableHead>
            <TableRow>
              {props?.titles.map((val) => {
                return (
                  <StyledTableCell
                    sx={{
                      textAlign: `${val.align} !important`,
                    }}
                  >
                    {val.title}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </StyledTableCell>
                </StyledTableRow>
              </>
            ) : (
              <>{listrender()}</>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 5 && (
        <div
          style={{
            padding: "10px 10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            count={Math.round(count / 5)}
            page={page + 1}
            color="primary"
            showFirstButton
            showLastButton
            onChange={handleChangePage}
          />
        </div>
      )}
      <Snackbar
        open={alert.open}
        message={alert.message}
        ContentProps={{ style: { backgroundColor: alert.backgroundColor } }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={4000}
      />
    </Paper>
  );
}
