import React from "react";
import { ViewVendor } from './viewvendor';
import { withNavBars } from "./../../HOCs";
import VendorDetail from "./vendorDetail";
class ViewVendorParent extends React.Component {
  render() {
    // return <ViewVendor />;
    return <VendorDetail />
  }
}

export default withNavBars(ViewVendorParent);
